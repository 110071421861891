.create-employee .dropdown-menu.dropdown-menu.show {overflow-y: scroll; height: 250px;}

.create-employee .card .header{color: #F9623E; text-transform: uppercase; margin: 10px 0;}

.create-employee .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {width: 5px; background-color: var(--text-secondary);}

.create-employee .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {background-color: var(--text-primary);  border-radius: 5px;}

.create-employee .status-dropdown.error .status-dropdown-toggle {border: 1px solid red !important;}

.create-employee .status-dropdown {margin-top: 7px;}

.create-employee .status-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E7E7E7 !important;
    background-color: #FFFFFF;
    width: 100%;
    color: #7C7C7C !important;
    border-radius: 5px;
    font-size: 13px;
}

.create-employee .status-dropdown-toggle:focus,
.create-employee .status-dropdown-toggle:active,
.create-employee .status-dropdown-toggle.show,
.create-employee .status-dropdown-toggle:hover {
    background-color: transparent !important;
    box-shadow: none !important;
}

.create-employee .dropdown-menu { position: absolute; border-radius: 10px; min-width: 200px; padding: 5px 10px;}

.create-employee .dropdown-menu .dropdown-item {padding: 5px 5px; left: 28%; font-size: 14px;  border-bottom: 1px solid #F0F0F0;}

.create-employee .dropdown-menu .check_menu .title .common_icon_image {height: 15px; width: 15px;}

.create-employee .form_group .form-label,
.create-employee label { font-size: 11px; text-transform: uppercase; letter-spacing: 1px; font-weight: 500; margin-top: 10px;}

/* uploads */

.create-employee .uploads {width: 32%; border-color: #F9623E; }

.create-employee .uploads .upload-container {background-color: #FFF7F5; border-radius: 9px; }