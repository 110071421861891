    /*calculation tabs css  */
    .calculation .nav-pills .nav-link.active {
        --bs-nav-pills-border-radius: var(--bs-border-radius);
        --bs-nav-pills-link-active-color: white;
        --bs-nav-pills-link-active-bg: #F9623E;
        color: white !important;
        background-color: #F9623E !important;
    }

    .calculation .nav-pills .nav-link {
        color: #6C7278;
        background-color: transparent !important;
    }

    .calculation .nav {
        padding: 5px 10px;
        background-color: #EFEFEF;
        border-radius: 8px;

    }

    .calculation .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .calculation .heading2 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }

    .calculation .heading .form_group .input-group {
        background-color: #F7F7F7;
    }

    /* add new calculation  glass calculation*/
    .select-category .heading {
        color: #F0F0F0;
        padding: 12px;
        background-color: #696969;
        margin-top: 10px;
    }

    .select-category .services {
        /* position: relative; */
        /* max-width: 800px; */
        text-align: center;
        color: white;
        /* width: 30%; */
        height: 178px;
        position: relative;
        border-radius: 8px !important;
        margin-top: 15px;
        cursor: pointer;
        background-color: #ebebeb;
    }


    .select-category .services .content {
        /* overflow: hidden; */
        position: absolute;
        bottom: 0 !important;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5);
        /* Black background with 0.5 opacity */
        padding: 13px;
        width: 100%;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .select-category .services .content h6 {
        color: #FFFFFF !important;
        text-align: start;
        font-size: 15px;

    }

    .glass-cleaning-card .dropdown-menu.dropdown-menu.show {
        overflow-y: scroll;
        height: 300px;
    }

    .glass-cleaning-card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {
        width: 5px;
        background-color: var(--text-secondary);
    }

    .glass-cleaning-card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
        background-color: var(--text-primary);
        border-radius: 5px;
    }

    /* .dropdown-menu.dropdown-menu.show {
        overflow-y: scroll;
        height: 300px;
    }

    .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {
        width: 10px;
        background-color: var(--text-secondary);
    }

    .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
        background-color: var(--text-primary);
        border-radius: 5px;
    } */

    .close-appointment-btn {
        height: 33px !important;
        width: 33px !important;
    }

    /* select category css */
    .category .card {
        border-radius: 10px;
    }

    .category .card .heading {
        color: #F0F0F0;
        padding: 12px;
        margin-top: 10px;
        background-color: #696969;
    }

    .category .preview .preview-summary {
        color: #F9623E;
        cursor: pointer;
        margin-top: 10px;

    }

    .category .additional-cost {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

    }

    .category .additional-cost .addCost {
        color: #F9623E;
        cursor: pointer;
    }

    .category .card label {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        color: #616161;
    }

    .category .card .form_group .form-label {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        color: #616161;
    }

    .category .card .form_group .input-group .right_text {
        font-size: 13px;
        color: #3E3E3F;
        font-weight: 600;

    }

    .category .card .form_group .input-group input {
        border-radius: 7px;
        font-size: 13px;

    }

    .category .card .form_group .input-group input:disabled {
        background-color: #EBEBEB !important;
        opacity: 1;
    }

    .category .card .radios .form-check {
        padding-left: 0;
    }

    .category .card .custom-table-preview {
        border-radius: 7px;
        margin-top: 10px;
    }

    .category .card .custom-table-preview th {
        color: #6C7278;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 30px;
    }

    .category .card .custom-table-preview .card {
        overflow: hidden;
        border-radius: 6px;
        border: 1px solid #EBEBEB;
    }


    .category .card .custom-table-preview .table-with-radius {
        vertical-align: middle;
        /* border: 1px solid #E1E1E1; */
    }

    .category .card .custom-table-preview th {
        background-color: #EDEDED;
        color: #6C7278;
        font-size: 15px;
        font-weight: 500;
        padding: 10px 10px;
        align-items: center;
        /* text-align: center; */

    }

    .category .card .custom-table-preview tr {
        border-color: none;
    }

    .category .card .custom-table-preview td {
        color: #6C7278;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 10px;
        /* text-align: center; */
        border: 1 px solid #E1E1E1 !important;

    }

    /* Ensure that the form-check input is hidden */
    .category .card .radios .form-check .form-check-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .category .card .radios .form-check .form-check-label {
        margin-top: 20px;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        user-select: none;
    }

    /* Custom outer circle styling */
    .category .card .radios .form-check .form-check-label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        /* Size of the outer circle */
        height: 16px;
        border-radius: 50%;
        background-color: #FFFFFF;
        border: 1px solid #D9D9D9;
    }

    /* Custom inner circle styling */
    .category .card .radios .form-check .form-check-label::after {
        content: '';
        position: absolute;
        left: 4px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: transparent;
    }

    /* Inner circle color and outer circle border color when radio button is checked */
    .category .card .radios .form-check .form-check-input:checked+.form-check-label::before {
        border: 1px solid #F9623E;
    }

    .category .card .radios .form-check .form-check-input:checked+.form-check-label::after {
        background: #F9623E;
    }

    .category .card .dropdown-menu.dropdown-menu.show {
        overflow-y: scroll;
        height: 300px;
    }

    .category .card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {
        width: 5px;
        background-color: var(--text-secondary);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .category .card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
        background-color: var(--text-primary);
        border-radius: 5px;
    }

    .category .card .status-dropdown {
        margin-top: 7px;
    }

    .category .card .status-dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #D9D9D9 !important;
        background-color: #FFFFFF;
        width: 100%;
        color: #555555 !important;
        border-radius: 7px;
        font-size: 13px;
    }

    .category .card .status-dropdown.error .status-dropdown-toggle {
        border: 1px solid red !important;
    }

    .category .card .dropdown-menu {
        position: absolute;
        /* left: 48% !important; */
        border-radius: 10px;
        min-width: -webkit-fill-available;
        padding: 0 7px;
    }

    .category .card .dropdown-menu .dropdown-item {
        padding: 15px 5px 10px 5px;
        right: 58%;
        font-size: 14px;
        border-bottom: 1px solid #F0F0F0;
    }

    /* additional Cost */
    .addcost .modal-dialog {
        --bs-modal-width: 600px;
    }

    .addcost .modal-content {
        background-color: #D9D9D9;
        border-radius: 9px;
        border: none;
        padding: 10px 0;
    }

    .addcost .modal-body {
        padding: 0;
    }

    .addcost .save-btn {
        width: 100px;

    }

    .addcost .items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px 0 20px;
    }

    .addcost .items h6 {
        font-size: 18px;
        color: #616161;
    }

    .addcost .costs {
        background-color: #FFFFFF;
        border-radius: 7px;
        margin: 7px 15px;
        padding: 16px 6px;
    }

    .addcost .costs h6 {
        margin-bottom: 10px;
        color: #F9623E;
    }


    .addcost .costs .form_group .form-label {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        color: #616161;
    }

    .addcost .costs .form_group .input-group .right_text {
        font-size: 13px;
        color: #3E3E3F;
        font-weight: 600;
    }

    /* .addcost .costs .form_group.input_group_icon .input-group{
        border-radius: 7px !important;

    } */
    .addcost .costs .form_group.input-group,
    .addcost .costs .form_group input {
        border-radius: 7px;
    }

    .addcost .costs .form_group .input-group input,
    .addcost .costs .form_group input {
        font-size: 13px;

    }

    .overview .card .heading {
        color: #F0F0F0;
        padding: 12px;
        background-color: #696969;
        /* margin-top: 10px; */
    }

    .overview .card .card-body-header .btns .save-btn {
        background-color: #454544;
        color: #FFFFFF;
    }
    .overview .card .card-body-header .btns .save-btn:hover {
       border-color: #454544 !important;
    }

    /* overview table */
    .custom-table-overview .header-1 {
        background-color: var(--text-primary);
        color: var(--text-white);
    }

    .custom-table-overview .header-2 {
        background-color: #454544;
        color: var(--text-white);
    }

    .custom-table-overview .sub-header-1 th {
        background-color: #FFEEEA;
        color: var(--text-primary);
    }

    .custom-table-overview .content td {
        color: #7C7C7C;
        font-weight: 500;
        font-size: 13px;
    }


    /* calculation overview */
    .calculation-overview-card {
        --bs-body-color-rgb: 44, 44, 44;
        --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0)
    }

    /* .calculation-overview-card .card-header-bg{
        background-color: white;
    } */

    /* offer letter modal */
    .offermodal .modal-dialog {
        --bs-modal-width: 570px;
    }

    .offermodal .modal-body {
        padding: 0;
    }

    .offermodal .modal-content {
        background-color: #ECECEC;
        border-radius: 9px;
        border: none;
        padding: 10px 0;
    }

    .offermodal .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 15px;

    }

    .offermodal .text-1 {
        /* margin-top: 5px; */
        padding: 5px 12px;
        border-radius: 6px;
        border: none;
    }

    .offermodal .heading h6 {
        font-size: 18px;
        color: #616161;
    }

    .offermodal .text-1 .card h6 {
        color: #F9623E;
        font-size: 14px;
    }

    .offermodal .text-1 .card p {
        font-size: 12px;
        margin-top: 10px;

    }

    /* customer modal */
    .customermodal .modal-dialog {
        --bs-modal-width: 350px;
    }

    .customermodal .modal-content {
        background-color: #FFFFFF;
        border-radius: 24px;
        border: none;
        padding: 10px 0;
    }

    .customermodal .modal-body {
        padding: 0;
    }

    .customermodal .heading {
        padding: 10px;
        text-align: center;
    }

    .customermodal .heading h6 {
        font-size: 25px;
        color: #555555;
        font-weight: 600;
        padding: 2rem 0;
    }

    .customermodal .heading .btns {
        margin: 0 5px;
    }

    /* fullcleaning */
    .full-cleaning .card .status-dropdown.error .status-dropdown-toggle {
        border: 1px solid #DC3645 !important;
    }

    .full-cleaning .form_group.error .form-control {
        --bs-border-color: #DC3645;
    }

    .full-cleaning .form_group.error .right_text {
        --bs-border-color: #DC3645;
    }

    .full-cleaning .first .card .heading,
    .interval .first .card .heading {
        color: #F0F0F0;
        padding: 12px;
        background-color: #696969;
        margin-top: 10px;
    }

    .full-cleaning .first .card .rate,
    .interval .first .card .rate {
        border: 1px solid #D9D9D9;
        border-radius: 9px;
        /* margin-top: 15px; */

    }

    .full-cleaning .first .card .rate .row,
    .interval .first .card .rate .row {
        background-color: #F8F8F8;
        margin: 0 0;
        border-radius: 9px;
        padding: 15px 10px;

    }

    .full-cleaning .first .card .text-rate,
    .interval .first .card .text-rate {
        background-color: #4CAF50;
        padding: 10px 0;
        border-radius: 7px;
        text-align: end;
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        margin-top: 15px ;
    }

    .full-cleaning .first .card label,
    .interval.first .card label {
        /* text-transform: uppercase; */
        /* letter-spacing: 1px; */
        font-size: 12px;
        font-weight: 500;
        color: #575757;
    }

    .full-cleaning .first .card .hourly-rate h6,
    .interval .first .card .hourly-rate h6 {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        color: #F9623E;
        margin-bottom: 10px;

    }

    .full-cleaning .first .card .rate .form_group .form-label,
    .interval .first .card .rate .form_group .form-label {
        color: #F9623E;
    }

    .full-cleaning .first .card .rate .form_group .form-label,
    .full-cleaning .first .card .surface .form_group .form-label,
    .interval .first .card .rate .form_group .form-label,
    .interval .first .card .surface .form_group .form-label {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        /* color: #F9623E; */
        margin-top: 10px;
    }

    .full-cleaning .first .card .rate .form_group .input-group .right_text,
    .full-cleaning .first .card .surface .form_group .input-group .right_text,
    .interval .first .card .rate .form_group .input-group .right_text,
    .interval .first .card .surface .form_group .input-group .right_text {
        font-size: 13px;
        color: #3E3E3F;
        font-weight: 600;

    }

    .full-cleaning .first .card .rate .form_group .input-group,
    .full-cleaning .first .card .surface .form_group .input-group,
    .interval .first .card .rate .form_group .input-group,
    .interval .first .card .surface .form_group .input-group {
        border-radius: 7px;
        font-size: 13px;

    }

    .full-cleaning .first .card .dropdown-menu.dropdown-menu.show,
    .interval .first .card .dropdown-menu.dropdown-menu.show {
        overflow-y: scroll;
        max-height: 210px;
        border-radius: 10px;
    }

    .full-cleaning .first .card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar,
    .interval .first .card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {
        width: 5px;
        background-color: var(--text-secondary);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .full-cleaning .first .card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb,
    .interval .first .card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
        background-color: var(--text-primary);
        border-radius: 5px;
    }

    .full-cleaning .first .card .status-dropdown.error .status-dropdown-toggle,
    .interval .first .card .status-dropdown.error .status-dropdown-toggle {
        border: 1px solid red !important;
    }

    .full-cleaning .first .card .status-dropdown,
    .interval .first .card .status-dropdown {
        margin-top: 7px;
    }

    .full-cleaning .first .card .status-dropdown-toggle,
    .interval .first .card .status-dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #D9D9D9 !important;
        background-color: #FFFFFF;
        width: 100%;
        color: #555555 !important;
        border-radius: 7px;
        font-size: 13px;
    }

    .full-cleaning .first .card .dropdown-menu,
    .interval .first .card .dropdown-menu {
        position: absolute;
        border-radius: 0px;
        width: 100%;
        padding: 5px 10px;
    }

    .full-cleaning .first .card .dropdown-menu .dropdown-item,
    .interval .first .card .dropdown-menu .dropdown-item {
        padding: 10px 0px;
        right: 58%;
        font-size: 14px;
        border-bottom: 1px solid #F0F0F0;
    }

    .full-cleaning .first .card .status-dropdown .dropdown-menu .check_menu .title .common_icon_image,
    .interval .first .card .status-dropdown .dropdown-menu .check_menu .title .common_icon_image {
        width: 15px !important;
        height: 15px;
    }

    .full-cleaning .first .card .preview h6,
    .interval .first .card .preview h6 {
        color: #F9623E;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px;
        letter-spacing: 1px;
    }

    .full-cleaning .first .card .preview .custom-table-preview,
    .interval .first .card .preview .custom-table-preview {
        margin-top: 10px;
    }

    .full-cleaning .emp-per-hr-cost,
    .full-cleaning .cmp-surcharge {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .full-cleaning .cmp-surcharge-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .full-cleaning .emp-per-hr-cost-input-box .input-group,
    .full-cleaning .cmp-surcharge-input-box .input-group {
        width: 110px;
        height: 30px;
        background-color: var(--text-white) !important;
    }

    .full-cleaning-preview .preview-thead th {
        background-color: #E1E1E1;
        color: #3E3E3F;
    }

    .full-cleaning-preview .preview-tbody td {
        color: #7C7C7C;
        font-weight: 500;
        font-size: 14px;
    }



    /* interval Cleaning */
    .interval-prev {
        margin-top: 10px;
    }

    .interval-prev .card .preview {
        color: #F9623E;
        margin-bottom: 15px;
    }

    .interval-prev .card th {
        background-color: #D6F2E8;
        border: 1px solid #96CDBA;
        font-size: 13px;
        color: #616161;
    }

    .interval-prev .card tbody td {
        font-size: 14px;
        color: #7C7C7C;
    }

    /* Table border radius for the header */
    .interval-prev .card .custom-table thead th.top-left-radius {
        border-top-left-radius: 7px;
    }

    .interval-prev .card .custom-table thead th.top-right-radius {
        border-top-right-radius: 7px;
    }

    /* Border radius for the bottom corners of the table */
    .interval-prev .card .custom-table tbody tr:last-child td:first-child {
        border-bottom-left-radius: 7px;
    }

    .interval-prev .card .custom-table tbody tr:last-child td:last-child {
        border-bottom-right-radius: 7px;
    }

    /* Gray background for the last two columns in tbody */
    .interval-prev .card .custom-table tbody .bg-gray {
        background-color: #EDEDED;
    }

    /* Additional styles to separate the totals row */
    .interval-prev .custom-table tbody .border-t {
        border-top: 1px solid #ddd;
    }

    .interval-cleaning .emp-per-hr-cost,
    .interval-cleaning .cmp-surcharge {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .interval-cleaning .emp-per-hr-cost label,
    .interval-cleaning .cmp-surcharge label {
        font-size: 12px;
        font-weight: 500;
        color: #575757;
    }

    .interval-cleaning .cmp-surcharge-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .interval-cleaning .emp-per-hr-cost-input-box .input-group,
    .interval-cleaning .cmp-surcharge-input-box .input-group {
        width: 95px;
        height: 30px;
        background-color: var(--text-white) !important;
    }

    .interval-cleaning-preview .preview-thead th {
        background-color: #E1E1E1;
        color: #3E3E3F;
    }

    .interval-cleaning-preview .preview-tbody td {
        color: #7C7C7C;
        font-weight: 500;
        font-size: 14px;
    }

    .interval-cleaning-preview .preview-thead th {
        background-color: #E1E1E1;
        color: #3E3E3F;
    }

    .interval-cleaning-preview .preview-tbody td {
        color: #7C7C7C;
        font-weight: 500;
        font-size: 14px;
    }

    .interval-cleaning-preview-data-table tbody .delete-icon,
    .interval-cleaning-preview-data-table tbody .edit-icon {
        cursor: pointer !important;
    }





    /* building division */

    .building-cleaning-preview .preview-thead th {
        background-color: #E1E1E1;
        color: #3E3E3F;
    }

    .building-cleaning-preview .preview-tbody td {
        color: #7C7C7C;
        font-weight: 500;
        font-size: 14px;
    }

    .building .card .heading {
        color: #F0F0F0;
        padding: 12px;
        background-color: #696969;
        margin-top: 10px;
    }


    .building .card .hour-rate {
        margin-top: 10px;
        background-color: #F8F8F8;
        border-radius: 9px;
        border: 1px solid #D9D9D9;
    }

    .building .card .hour-rate .content-box {
        padding: 10px 5px 0 5px;
    }

    .building .card .hour-rate h6 {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        color: #F9623E;
        padding: 0 5px;
    }

    .building .card .hour-rate label {
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;
        padding: 0 5px;
    }

    .building .card .text-rate {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        background-color: #4CAF50;
        padding: 9px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    .building .card label.surface-area-title {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        color: #616161;
        margin-top: 10px;

    }

    .building-division .cmp-surcharge-input-box .input-group {
        width: 100%;
        height: 30px;
        background-color: var(--text-white) !important;
    }

    /* .building .card .form_group .form-label {
        color: #F9623E;
    } */


    .building .card .form_group .form-label,
    .building .card .form_group .form-label {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        margin-top: 10px;
        padding: 0 15px;

    }


    .building .card .form_group .input-group .right_text,
    .building .card .form_group .input-group .right_text {
        font-size: 13px;
        color: #3E3E3F;
        font-weight: 600;

    }

    .building .card .form_group .input-group,
    .building .card .form_group .input-group {
        border-radius: 7px;
        font-size: 13px;
        /* padding: 0 10px; */

    }



    .building .first .card .preview h6 {
        color: #F9623E;
        margin-bottom: 10px;
    }

    .building .card .preview .custom-table-preview {
        margin-top: 10px;
    }

    .building .card .card {
        border-radius: 7px;
    }

    .building .card .card .building-table {
        margin-bottom: 0;
    }

    .building .card .building-table tbody td {
        border: none;
    }

    .building .card .building-table thead th:first-child {
        border-top-left-radius: 7px;
    }

    .building .card .building-table thead th:last-child {
        border-top-right-radius: 7px;
    }

    .building .card .building-table thead th {
        background-color: #4CAF50;
        color: #ECECEC;
        font-weight: 500;
        font-size: 13px;
        /* text-align: center; */
        padding: 12px 12px;
    }

    .building .card .building-table tbody tr:nth-child(odd) {
        background-color: #f2f2f2 !important;
    }

    .building .card .building-table tbody tr:nth-child(even) {
        background-color: #ffffff !important;
    }

    .building .card .building-table .label-text {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        color: #616161;
    }

    .building .card .building-table .form-select {
        padding: 5px;
        /* margin: 5px auto; */
        border: 1px solid #D9D9D9 !important;
        border-radius: 5px;
        font-size: 12px;
        /* color: #575757; */
        background-color: var(--text-white);
    }

    .building .card .building-table .form-control {
        /* margin: 5px auto ; */
        padding: 5px 5px;
        border: 1px solid #D9D9D9 !important;
        border-radius: 5px;
        font-size: 12px;
        background-color: var(--text-white);
    }

    .building .card .preview {
        color: #F9623E;
    }

    .building .card .bg-clr {
        background-color: #EDEDED;
        color: #7C7C7C;
    }

    .building .card .custom-checkbox {
        width: 16px;
        height: 16px;
        border: 1px solid #4CAF50;
        border-radius: 3px;
        appearance: none;
        cursor: pointer;
        margin: 5px 10px;
        padding: 0 5px;
    }

    .building .card .custom-checkbox:checked {
        background-color: #4CAF50;
        border-color: #4CAF50;
    }

    .building .card .custom-checkbox:checked::after {
        content: '✔';
        color: white;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .building-division .card .is-invalid {
        --bs-form-select-bg-icon: none !important;
    }


    /* maintenance cleaning css */
    .category .preview,
    .category .preview .addCost {
        color: #F9623E;
        cursor: pointer;
        margin-top: 10px;

    }