.custom-table .heading-title {
    font-size: 18px;
    font-weight: 600;
}

.button-filter .form_group .form-control {
    --bs-body-bg: #F7F7F7;
    --bs-border-color: #E7E7E7;
    width: 250px;
    height: 39px;
}

.button-filter .form_group .left_text {
    --bs-tertiary-bg: #F7F7F7;
    --bs-border-color: #E7E7E7;
}

.button-filter .form_group .right_text {
    --bs-tertiary-bg: #F7F7F7;
    --bs-border-color: #E7E7E7;
}

.button-filter.search .form_group:focus-within .left_text {
    color: var(--bs-body-color);
    background-color: #F7F7F7;
}

.button-filter.search .form_group:focus-within .right_text {
    color: var(--bs-body-color);
    background-color: #F7F7F7;
}


.button-filter.user-type .custom-select .custom-select__control .custom-select__value-container {
    /* min-width: 170px; */
}

.button-filter.user-type .custom-select .custom-select__control {
    background-color: #F7F7F7;
}

.button-filter.user-type .custom-select .custom-select__control.css-13cymwt-control {
    border: 1px solid #E7E7E7;
}


/* table ===================================================== */
.custom-table .table-with-radius{
    vertical-align: middle;
 }
.custom-table .table-th-bg th {
    background-color: #FFF2EF;
    border: 1px solid #FFB4A2;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    padding: 10px 8px;
}


.custom-table .table-td-bg td {
    background-color: #F5F5F5;
    border-color: #D9D9D9 !important;
    color: var(--text-color);
}

.custom-table .table-with-radius tr:hover td {
    background-color: #FFFBEF !important;
    cursor: pointer;
}

.custom-table .table-with-radius tbody, 
.custom-table .table-with-radius td {
    padding: 15px 8px !important;
    text-align: left;
}


/* table-border-radius */
.custom-table .table-with-radius {
    border-collapse: separate;
    border: 0;
    border-spacing: 0;
    border-radius: 6px;

}

.custom-table .table-with-radius th,
.custom-table .table-with-radius td {
    border-radius: 0;

}

.custom-table .table-with-radius tr:first-child th:first-child {
    border-top-left-radius:8px;
}

.custom-table .table-with-radius tr:first-child th:last-child {
    border-top-right-radius:8px;
}


.custom-table .table-with-radius tr:last-child td:first-child {
    border-bottom-left-radius:8px;
}

.custom-table .table-with-radius tr:last-child td:last-child {
    border-bottom-right-radius:8px;
}

/* remove the right border */
.custom-table .table-with-radius th:not(:last-child) {
    border-right: none;
}

.custom-table .table-with-radius td:not(:last-child) {
    border-right: none;
}


/* custom table two===================================== */
.custom-table-two.card{
    overflow: hidden;
    min-height: 100%;
}
.custom-table-two.card .card-header{
    padding: 12px 15px;
}

.custom-table-two.card .card-header .heading-title{
    color: #6C7278;
    font-weight: 600;
    font-size: 17px;
    margin: 0;
}
.custom-table-two.card .card-header .heading-title-two{
    color: #6C7278;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}
.custom-table-two .table-with-radius{
   vertical-align: middle;
}

.custom-table-two .table-th-bg th{
    background-color: #F5F5F5;
    color: #6C7278;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
}
.custom-table-two .table-tbody-bg td{
    color: #9CA1A6;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0px 10px 16px;
}

.custom-table-two .table-tbody-bg tr:hover td.new-calc-td{
    background-color: #F5F5F5;
    color: #6C7278;
    cursor: pointer;
}
.custom-table-two .card-header{
    background-color: var(--text-white);
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}  
.custom-table-two .card-header .card-header-first-div{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    
}  

.custom-table-two .table-th-bg{
   border-bottom: 1px solid var(--border-primary);
} 


/* custom-table-two Icons================  */
.custom-table-two .view-icon-box{
    background-color: #FFDFD7;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 5px;
    border-radius: 5px;
    cursor: pointer;
}
.custom-table-two .view-icon-box2{
    background-color:  #e0e0e0;;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 5px;
    border-radius: 5px;
}

.custom-table-two .card-header-custom-tabs{
    display: flex;
    gap: 40px;
    cursor: pointer;
}
.custom-table-two .card-header-custom-tabs .active-tab{
    color: var(--text-primary);
    position: relative;
  
}
.custom-table-two .card-header-custom-tabs .tabs-text{
    padding: 0 10px;
}
.custom-table-two .card-header-custom-tabs .active-tab::after{
   content: "";
   width: 100%;
   height: 2px;
   background-color: var(--text-primary);
   position: absolute;
   bottom: -23px;
   left: 0px;
   border-radius: 10px;
}


/* customer add-edit================================ */
.customers-add-edit-card .title-info{
    color: var(--text-primary);
    font-weight: 500;
    font-size: 17px;
}
.prospect-details .title-info{
    color: var(--text-primary);
    font-weight: 500;
    font-size: 17px;
}

.switch-two-tabs-box{
    background-color: #EFEFEF;
    padding: 5px 5px;
    border-radius: 6px;
}
.switch-two-tabs-box .btn{
    border: none;
}
