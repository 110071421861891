.services .edit-service .card .card-body-header {
    padding: 15px;
}

.services .edit-service .card .heading {
    color: #F0F0F0;
    padding: 12px;
    background-color: #696969;
    margin-top: 10px;
}

.services .edit-service .card .service-data {
    padding: 10px 15px;
}

.services .edit-service .card .service-data label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    color: #6C7278;
    margin-bottom: 5px;
}

.services .edit-service .card .service-data .weekdays {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 30px;
    /* margin-bottom: 20px; */
    align-items: center;
}


.services .edit-service .card .service-data .weekdays h6 {
    font-weight: 500;
    font-size: 13px;
}

.services .edit-service .card .service-data .weekdays .day-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 3px;
}

.services .edit-service .card .service-data .weekdays .form-check {
    margin-bottom: 0;
    min-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services .edit-service .card .service-data .weekdays .form-check .form-check-input {
    height: 18px;
    width: 34px;
    border: 2px solid #d5d5d5;
    cursor: pointer;
}

.services .edit-service .card .service-data .weekdays .custom-switch .form-check-input:focus {
    box-shadow: none;
    border-color: #4CAF50;
}

.services .edit-service .card .service-data .weekdays .custom-switch .form-check-input:checked {
    background-color: #4CAF50;
    border-color: #4CAF50;
}

.services .edit-service .card .service-data .weekdays .form_group .form-check .form-check-input:checked {
    box-shadow: none !important;
    /* border-color: #4CAF50 !important; */
    border: none !important;
    background-color: #4CAF50 !important;

}

/* .services .edit-service .card .service-data .weekdays .custom-switch .form-check-input:checked {
    background-color: #4CAF50 !important;
    border-color: #4CAF50 !important;
    border: none !important;
} */

/* dropdowns */
 .services .edit-service .card .service-data .dropdown-menu.dropdown-menu.show {
    overflow-y: scroll;
    max-height: 200px;
}

.services .edit-service .card .service-data .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {
    width: 5px;
    background-color: var(--text-secondary);
}

.services .edit-service .card .service-data .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
    background-color: var(--text-primary);
    border-radius: 5px;
}

.services .edit-service .card .service-data .status-dropdown {
    margin-top: 7px;
}

.services .edit-service .card .service-data .dropdown-menu.dropdown-menu.show {
    overflow: scroll;
    max-height: 200px;
}

.services .edit-service .card .service-data .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: var(--text-secondary);
}

.services .edit-service .card .service-data .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
    background-color: var(--text-primary);
    border-radius: 5px;
    /* width: 0px; */
}

.services .edit-service .card .service-data .status-dropdown {
    margin-top: 7px;
}

 .services .edit-service .card .service-data .status-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D9D9D9 !important;
    background-color: #FFFFFF;
    width: 100%;
    color: #555555 !important;
    border-radius: 7px;
    font-size: 13px;
}

.services .edit-service .card .service-data .status-dropdown.error .status-dropdown-toggle {
    border: 1px solid red !important;
}

.services .edit-service .card .service-data .dropdown-menu {
    position: absolute;
    /* left: 48% !important; */
    border-radius: 10px;
    min-width: 260px;
    padding: 0 7px;
    /* width: -webkit-fill-available; */
}

.services .edit-service .card .service-data .dropdown-menu .dropdown-item {
    padding: 10px 5px;
    right: 58%;
    font-size: 14px;
    border-bottom: 1px solid #F0F0F0;
}

.services .edit-service .card .service-data .status-dropdown .dropdown-menu .check_menu .common_icon_image {
    width: 15px;
    height: 15px;
    margin-left: 5px;
}

/* list service table css */
.services .edit-service .listService .card {
    margin-top: 15px;
    color: #7C7C7C;
}

/* .services .edit-service .listService .card table thead.header th{
    background-color: #F9623E !important;
    color: #FFFFFF;
} */
.services .edit-service .listService .card .custom-table .header-1 {
    background-color: var(--text-primary);
    /* padding: 10px 5px;    */
    color: var(--text-white);
    font-weight: 500;
    text-align: center;
}

.services .edit-service .listService .card .custom-table .header-2 {
    background-color: #565656;
    color: var(--text-white);
    font-weight: 500;
    padding: 10px 0;
    padding-left: 10px;
}

.services .edit-service .listService .card .custom-table .header-3 {
    background-color: #8F8F8F;
    color: var(--text-white);
    font-weight: 500;
    padding: 10px 0;
    padding-left: 10px;
}

.services .edit-service .listService .card .custom-table .header-2 .icons {
    background-color: var(--text-white);
}

.services .edit-service .listService .card .custom-table .top-left {
    /* border-top-left-radius: 9px; */
}

.services .edit-service .listService .card .custom-table .top-right {
    /* border-top-right-radius: 9px; */
}

.services .edit-service .listService .card .custom-table tbody .task,
.services .edit-service .listService .card .custom-table tbody .frequency {
    color: #7C7C7C;
}

.services .edit-service .listService .card .add-edit-bg {
    background-color: #565656;
    color: var(--text-white);
}

.services .edit-service .listService .card .add-edit-bg i {
    background-color: var(--text-white);
}

/* .services .edit-service .listService .card .object-td{
    min-width: 200px;
  } */

.services .edit-service .listService .card .service-blue-bg {
    background-color: #4141FF;
    color: var(--text-white);
}

.services .edit-service .listService .card .service-yellow-bg {
    background-color: #FFFF04;
}

.services .edit-service .listService .card .service-green-bg {
    background-color: #2BF628;
}

.services .edit-service .listService .card .service-orange-bg {
    background-color: #FF4144;
    color: var(--text-white);
}