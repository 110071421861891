.ext-offer .card {
    border-radius: 11px;
}

.ext-offer .statistics {
    margin-top: 10px;
    /* padding: 10px; */
    display: flex;
    gap: 10px;
    /* min-width: 500px; */
}

.ext-offer .statistics .stat-1 {
    background-color: #52B7DE;
    border-radius: 9px;
    padding: 15px;
    color: #FFFFFF;
    min-width: 170px;
}

.ext-offer .statistics .stat-2 {
    background-color: #405FCB;
    border-radius: 9px;
    padding: 15px;
    color: #FFFFFF;
    min-width: 170px;

}

.ext-offer .statistics .stat-3 {
    background-color: #FF5252;
    border-radius: 9px;
    padding: 15px;
    color: #FFFFFF;
    min-width: 170px;

}

.ext-offer .statistics .stat-4 {
    background-color: #8CA558;
    border-radius: 9px;
    padding: 15px;
    color: #FFFFFF;
    min-width: 170px;

}

.ext-offer .card .top-offer .comp-name {
    /* margin-top: 5px; */
    display: flex;
    gap: 5px;
    padding: 10px 0;
    align-items: center;
}

/* add external offer */
.add-external .dropdown-menu.dropdown-menu.show {
    overflow-y: scroll;
    height: 250px;
}

.add-external .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {
    width: 5px;
    background-color: var(--text-secondary);
}

.add-external .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
    background-color: var(--text-primary);
    border-radius: 5px;
}

.add-external .status-dropdown.error .status-dropdown-toggle {
    border: 1px solid red !important;
}

.add-external .status-dropdown {
    margin-top: 7px;
}

.add-external .status-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E7E7E7 !important;
    background-color: #FFFFFF;
    width: 100%;
    color: #7C7C7C !important;
    border-radius: 5px;
    font-size: 13px;
}

.add-external .status-dropdown-toggle:focus,
.add-external .status-dropdown-toggle:active,
.add-external .status-dropdown-toggle.show,
.add-external .status-dropdown-toggle:hover {
    background-color: transparent !important;
    box-shadow: none !important;
}

.add-external .dropdown-menu {
    position: absolute;
    border-radius: 10px;
    min-width: -webkit-fill-available;
    padding: 5px 10px;
}

.add-external .dropdown-menu .dropdown-item {
    padding: 5px 5px;
    left: 28%;
    font-size: 14px;
    border-bottom: 1px solid #F0F0F0;
}

.add-external .dropdown-menu .check_menu .title .common_icon_image {
    height: 15px;
    width: 15px;
}

.add-external .form_group .form-label,
.add-external label {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    margin-top: 10px;
}

/* uploads */

.add-external .uploads {
    width: 32%;
    border-color: #F9623E;

}

.add-external .uploads .upload-container {
    background-color: #FFF7F5;
    border-radius: 9px;
}

/* documentation create folder */
.createFolder .modal-dialog {
    --bs-modal-width: 400px;
}


.createFolder .modal-content {
    background-color: #FFFFFF;
    border-radius: 13px;
    border: none;
    padding: 5px 10px;
}

.createFolder .heading .heading-1 {
    color: #616161;
    font-size: 21px;
    margin-bottom: 7px;

}

/* documentation move folder */
.move-folder .modal-dialog {
    --bs-modal-width: 500px;
}


.move-folder .modal-content {
    background-color: #FFFFFF;
    border-radius: 13px;
    border: none;
    padding: 5px 10px;
}

.move-folder .modal-body {
    height: 80vh !important;
    overflow: auto !important;
}

.move-folder .heading .heading-1 {
    color: #616161;
    font-size: 21px;
    margin-bottom: 7px;
}

/* create offer */
.create-offer .card .offer-detail-form {
    margin: 15px 0;

}

.create-offer .card .deleteIcon {
    margin: 0 15px;
    padding: 6px 7px;
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #C2C2C2;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    cursor: pointer;
}

.create-offer .card .preview h6 {
    margin-bottom: 10px;
    color: #F9623E;
}

.create-offer .card .preview {
    display: flex;
    align-items: center;
}

.create-offer .card form label {
    text-transform: uppercase;
    font-size: 11px;
    color: #6C7278;
    font-weight: 500;
    letter-spacing: 1px;
}

.create-offer .card .dropdown-menu.dropdown-menu.show {
    overflow-y: scroll;
    height: 250px;
}

.create-offer .card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar {
    width: 5px;
    background-color: var(--text-secondary);
}

.create-offer .card .dropdown-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
    background-color: var(--text-primary);
    border-radius: 5px;
}

.create-offer .card .status-dropdown {
    margin-top: 7px;
}

.create-offer .card .status-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E7E7E7 !important;
    background-color: #FFFFFF;
    width: 100%;
    color: #7E7E7E !important;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.create-offer .card .status-dropdown.error .status-dropdown-toggle,
.create-offer .card .form-control.error {
    border: 1px solid red !important;
}

.create-offer .card .dropdown-menu {
    position: absolute;
    /* left: 48% !important; */
    border-radius: 10px;
    min-width: 345px;
    padding: 0 7px;
}

.create-offer .card .dropdown-menu .dropdown-item {
    padding: 10px 5px;
    right: 58%;
    font-size: 14px;
    border-bottom: 1px solid #F0F0F0;

}

.create-offer .card .status-dropdown .dropdown-menu .common_icon_image {
    width: 15px;
    height: 15px;
    margin-left: 5px;
}

/* view external offer detail page */
.view-extoffer-modal-dialog.modal {
    padding: 0 !important;
}

.view-extoffer-modal-dialog .modal-dialog .modal-content {
    border-radius: 0;
}

.view-extoffer-modal-dialog .modal-dialog {
    margin: 0;
    margin-left: auto;
}

.view-extoffer-modal-dialog .modal-dialog.modal-lg {
    --bs-modal-width: 700px
}

/* header */
.view-extoffer-modal-dialog .modal-header {
    background-color: #f3f3f3;
    padding: 16px 20px;
}

.view-extoffer-modal-dialog .modal-header .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f3;
    flex-grow: 1;
}

.view-extoffer-modal-dialog .modal-header .header .header-icons-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.view-extoffer-modal-dialog .modal-header .header .header-icons-box .common_icon {
    height: 22px;
    width: 22px;
}

.view-extoffer-modal-dialog .modal-header .header .header-icons-box .common_icon_image {
    height: 22px;
    width: 22px;
    cursor: pointer;
}

.view-extoffer-modal-dialog .modal-header .header h6 {
    color: var(--text-primary);
    font-weight: 700;
    font-size: 20px;
}

.view-extoffer-modal-dialog .view-appointment-modal {
    padding: 0 16px 16px 16px;
}


/* title font common for all */
.view-extoffer-modal-dialog .title-font {
    font-weight: 500;
    font-size: 12px;
    font-family: "poppins";
    text-transform: uppercase;
    letter-spacing: 1px;
}

.view-extoffer-modal-dialog .title-font-2 {
    font-weight: 500;
    font-size: 13px;
    font-family: "poppins";
    display: flex;
    align-items: center;
    color: var(--text-color2);
}

/* title */
.view-extoffer-modal-dialog .title-box {
    margin-top: 10px;
}

.view-extoffer-modal-dialog .title-content-box {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    border: 1px solid #C2C2C2;
    border-radius: 8px;
    padding: 15px 15px;
    margin-top: 10px;
    gap: 10px;
}

/* offer Overview */
.offer-overview .cards {
    display: flex;
    gap: 10px;
}

.offer-overview .cards .all-offer,
.offer-overview .cards .open-offer,
.offer-overview .cards .close-offer,
.offer-overview .cards .reject-offer {
    border-radius: 9px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    /* width: 20%; */
}

/* .offer-overview .cards .all-offer h6:active {
    color: #F9623E;
    border-color:#F9623E ;
    font-size: 25px;
    font-weight: 600;
} */

.offer-overview .cards .all-offer span {
    font-size: 13px;
    color: #F9623E;
    padding: 10px 0;
}

.offer-overview .cards .open-offer span {
    font-size: 13px;
    color: #487FFF;
    padding: 10px 0;
}

.offer-overview .cards .close-offer span {
    font-size: 13px;
    color: #00B844;
    padding: 10px 0;
}

.offer-overview .cards .reject-offer span {
    font-size: 13px;
    color: #FA0606;
    padding: 10px 0;
}

/* offers overview sr.no. colors */
.offer-overview .service-blue-bg {
    color: #487FFF !important;
    background-color: #ECF1FF;
    font-weight: 700 !important;
    border-radius: 10px;    
    padding: 8px 16px !important;
    text-align: center;
    margin: 5px !important;
}
.offer-overview .service-red-bg{
    color: #FF5252 !important;
    background-color: #FFE7E7;
    font-weight: 700 !important;
    border-radius: 10px;
    padding: 8px 16px !important;
    text-align: center;
    margin: 5px !important;
}
.offer-overview .service-green-bg{
    color: #00B844 !important;
    font-weight: 700 !important;
    background-color: #D9FFE7;
    border-radius: 10px;
    padding: 8px 16px !important;
    text-align: center;
    margin: 5px !important;
   
}
/* Offer-Details */
.Offer-Details .card .comapny {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #F9623E;
    border-radius: 7px;
    margin-top: 10px;
    font-weight: 500;
}

.Offer-Details .card .comapny .company-title {
    color: white;
}

.Offer-Details .filters-box {
    display: flex;
}

.Offer-Details .details {
    display: flex;
    align-items: center;
    gap: 5px;
}

.Offer-Details .details h6 {
    font-size: 14px;

}

/* offer overview - add document */
.offer-addDocument .list {
    background-color: white;
    border-radius: 6px;
    border: none;
}

/* .offer-addDocument .list .list-group-item .icons .common_icon_image{
    background-color: #7C7C7C;
} */
.offer-addDocument .card .tabs {
    padding: 10px;
}

.offer-addDocument .list .list-group-item {
    color: #6A6A6A;
    background-color: white;
    border: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    cursor: pointer;
}

.offer-addDocument .list-group .list-group-item.active .common_icon {
    background-color: #F9623E;
}

.offer-addDocument .list .list-group-item.active {
    color: #F9623E;
    background-color: #FFDCD3;
    font-weight: bold;
}

/* .Offer-Details .status-dropdown .btn{
    padding: 8px 15px !important;
    width: 200px;
}
.Offer-Details .status-dropdown .btn i{
    float: right;
}

.Offer-Details .status-dropdown .dropdown-menu.show{
    width: -webkit-fill-available;
} */



.Offer-Details .status-main-box {
    position: relative;
}

.Offer-Details .status-main-box .status-content-box {
    width: 200px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    background-color: #f6f5f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}


.Offer-Details .status-main-box .status-menu-box {
    z-index: 999;
    width: 250px;
    background-color: white;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    padding: 5px 5px;
    position: absolute;
    top: 40px;
    left: 0;
    box-shadow: 2px 2px 8px gray;

}

.Offer-Details .status-main-box .status-menu-box .label-box {
    display: flex;
    justify-content: space-between;
}

.Offer-Details .status-main-box .status-menu-box span {
    display: block;
    padding: 5px 10px;
}

.Offer-Details .status-main-box .status-menu-box .label-box:hover {
    background-color: #f8f9fa;
    cursor: pointer;
}

.Offer-Details .status-main-box .status-menu-box .status-btn-box {
    /* background-color: cyan; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* margin-top: 10px; */
    padding: 10px 0;
}

.Offer-Details .status-main-box .status-menu-box .status-btn-box .status-btn-cancel {
    width: 45%;
}

.Offer-Details .status-main-box .status-menu-box .status-btn-box .status-btn-submit {
    width: 45%;
}

.Offer-Details .custom-date-box {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    align-items: center;
    padding: 5px 5px 5px 5px;
    height: 100%;
    background-color: #EEEEEE;
}

.Offer-Details .custom-date-box .react-datepicker__input-container input {

    width: 100px;
    padding: 3px 7px;
    border-radius: 4px;
    background-color: #CECECE !important;
    border: none;
    outline: none !important;
    background-color: #EEEEEE;
}

.add-external .external-offer-preview-box {
    /* background: ; */
    border: 1px solid #DDDDDD;
    display: flex;
    justify-content: space-between;
    width: 350px;
    padding: 7px 5px;
    border-radius: 7px;
}

.custom-table-date-filter {
    display: flex;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    padding: 2.5px 2.5px;
    border: 1px solid #EEEEEE;
    height: 100%;
    background-color: #F7F7F7;
}

.custom-table-date-filter .react-datepicker__input-container input {
    min-width: 4rem;
    max-width: 110px;
    border: none;
    outline: none !important;
    background-color: #F7F7F7;
}

.custom-table-date-filter .react-datepicker__input-container {
    /* display: flex; */
    /* align-items: center; */
}

.custom-table-date-filter .date-right-border {
    border-left: 2px solid #E6E6E6;
    height: 100%;
    /* padding-right: 10px;
    margin-left: 10px; */
}

.custom-table-date-filter .end-date {
    border: none;
    border-left: 1px solid #E7E7E7;
}

.custom-table-date-filter .react-datepicker__calendar-icon {
    padding: 4px !important;
    width: 25px !important;
}

.custom-table-date-filter .btn-danger {
    background-color: red !important;
    color: white !important;
}

.custom-table-date-filter .btn-danger:hover {
    background-color: red !important;
}