@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('./style.css');
@import url('./sidebar.css');
@import url('./customtable.css');
@import url('./button.css');
@import url('./defaultchanges.css');
@import url('./prospects.css');
@import url('./calculation.css');
@import url('./externalOffers.css');
@import url('./services.css');
@import url('./promotion.css');
@import url('./employee.css');
body {
    margin: 0px;
    --width-sidebar: 370px;
    --text-color:#6C7278;
    --text-color2:#3E3E3F;
    --text-primary: #F9623E;
    --text-primary2:#FFF5F3;
    --text-secondary: #FFF4F1;
    --text-ternary: #FFFBEF;
    --text-gray:#F6F5F4;
    --text-border:#D0D0D0;
    --text-white: #FFFFFF    ;
    --b-color: #EEEEEE;
    --border-primary:#D9D9D9;
    --border-secondary:#E7E7E7;

    color: var(---text-color);
    --fs-10: 10px;
    --fs-11: 11px;
    --fs-12: 12px;
    --fs-13: 13px;
    --fs-14: 0.9rem;
    --fs-15: 15px;
    --fs-16: 16px;
    --fs-17: 17px;
    --fs-18: 18px;
    --fs-19: 19px;
    --fs-20: 20px;
    --fs-22: 22px;
    --fs-23: 23px;
    --fs-24: 24px;
    --fs-25: 25px;
    --fs-26: 26px;
    --fs-27: 27px;
    --fs-28: 28px;
    --fw-200: 200;
    --fw-300: 300;
    --fw-400: 400;
    --fw-500: 500;
    --fw-600: 600;
    --fw-700: 700;
    --fw-800: 800;
    --bs-blue: var(--text-primary);
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: var(--second-primary);
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: var(--text-white);
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: var(--b-color);
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: var(--second-primary);
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 35, 77, 202;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text-emphasis: #052c65;
    --bs-secondary-text-emphasis: #2b2f32;
    --bs-success-text-emphasis: #0a3622;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;
    --bs-primary-bg-subtle: #cfe2ff;
    --bs-secondary-bg-subtle: #e2e3e5;
    --bs-success-bg-subtle: #d1e7dd;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-light-bg-subtle: #fcfcfd;
    --bs-dark-bg-subtle: #ced4da;
    --bs-primary-border-subtle: #9ec5fe;
    --bs-secondary-border-subtle: #c4c8cb;
    --bs-success-border-subtle: #a3cfbb;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1aeb5;
    --bs-light-border-subtle: #e9ecef;
    --bs-dark-border-subtle: #adb5bd;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-font-sans-serif: "DM Sans", sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: var(--fs-14);
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: var(---text-color);
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg: var(--text-white);
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0, 0, 0;
    --bs-secondary-color: rgba(33, 37, 41, 0.75);
    --bs-secondary-color-rgb: 33, 37, 41;
    --bs-secondary-bg: #e9ecef;
    --bs-secondary-bg-rgb: 233, 236, 239;
    --bs-tertiary-color: rgba(33, 37, 41, 0.5);
    --bs-tertiary-color-rgb: 33, 37, 41;
    --bs-tertiary-bg: #f8f9fa;
    --bs-tertiary-bg-rgb: 248, 249, 250;
    --bs-heading-color: inherit;
    --bs-link-color: var(--text-primary);
    --bs-link-color-rgb: 13, 110, 253;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #0a58ca;
    --bs-link-hover-color-rgb: 10, 88, 202;
    --bs-code-color: #d63384;
    --bs-highlight-color: #212529;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: var(--b-color);
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 4px;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
    --bs-form-valid-color: #198754;
    --bs-form-valid-border-color: #198754;
    --bs-form-invalid-color: #dc3545;
    --bs-form-invalid-border-color: #dc3545;
    --padding: 9.2px 0.75rem;
    --select-padding: 0.55rem 2.25rem 0.55rem 0.75rem;
    --fr-secondary-primary: linear-gradient(180deg, #E6E9ED 0%, #FFFFFF 100%), linear-gradient(0deg, var(--grey-light-color);, var(--grey-light-color););
    --fr-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    --text-font: 14px;
    --warning-color: #FF9B25;
    --green-color: #53D13F;
    --bg-body-color: #FAFAFA;
    --form-border: #E3E5E9!important;
    --tab-color: #90ACFC;
    --trans-primary: #D9E7F4;
    --grey-light-color: #EEEEEE;
    --check-primary: #234DCA;
    --fr--hover-border-color: #234DCA;
    --text-sidebar: var(--white-color);
    font-family: var(--bs-font-sans-serif);
    color: #6C7278;
}
body.theme-purple{
    /* --width-sidebar: 320px; */
    --text-primary: #851ed6;
    --transparent-primary: #6410a5;
    --second-primary: #234DCA;
    --second-primary-1: #234DCA;
    --check-primary: #234DCA;
    --text-white: #ffffff;
    ---text-color: #262D3D;
    --b-color: var(--grey-light-color);;
    --warning-color: #FF9B25;
    --green-color: #53D13F;
    --bg-body-color: #FAFAFA;
    --form-border: #E3E5E9 !important;
    --fr-form-border:#d6d7df!important;
    --tab-color: #262D3D;
    --trans-primary: #dcc8eb;
    --grey-light-color: #ece2f5;
    --fr--hover-border-color: #851ed6;
    --text-sidebar: black

}
body.theme-purple .main-sidebar, body.theme-purple .main-sidebar,body.theme-purple  .metis_menu .list-group .active .list-group-item
, body.theme-purple .metis_menu .list-group>a:hover .list-group-item{
    --bs-list-group-color: white!important;
}
body.theme-purple .side-footer{--bs-list-group-color: var(--bs-body-color)!important}
body.theme-purple .main-sidebar{
    --text-primary: white
}
.border-login{
    --form-border: #B5BDC7!important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    --bs-heading-color: var(---text-color);
}

.t-underline {
    text-decoration: underline !important;
}

.cursor-pointer {
    cursor: pointer;
}

.tp-1 {
    --text-font: var(--fs-25);
    font-size: var(--text-font);
}

.tp-2 {
    --text-font: var(--fs-24);
    font-size: var(--text-font);
}

.tp-3 {
    --text-font: var(--fs-22);
    font-size: var(--text-font);
}

.tp-4 {
    --text-font: var(--fs-20);
    font-size: var(--text-font);
}

.tp-5 {
    --text-font: var(--fs-18);
    font-size: var(--text-font);
}

.tp-6 {
    --text-font: var(--fs-16);
    font-size: var(--text-font);
}

.tp-7 {
    --text-font: var(--fs-14);
    font-size: var(--text-font);
}

.tp-8 {
    --text-font: var(--fs-12);
    font-size: var(--text-font);
}

.tp-9 {
    --text-font: var(--fs-10);
    font-size: var(--text-font);
}

.title-1,
.subtitle-1 {
    --text-font: var(--fs-25);
    font-size: var(--text-font);
    font-weight: var(--fw-700);
}

.title-2,
.subtitle-2 {
    --text-font: var(--fs-24);
    font-size: var(--text-font);
    font-weight: var(--fw-700);
}

.title-3,
.subtitle-3 {
    --text-font: var(--fs-22);
    font-size: var(--text-font);
    font-weight: var(--fw-700);
}

.title-4,
.subtitle-4 {
    --text-font: var(--fs-20);
    font-size: var(--text-font);
    font-weight: var(--fw-700);
}

.title-5,
.subtitle-5 {
    --text-font: var(--fs-18);
    font-size: var(--text-font);
    font-weight: var(--fw-600);
}

.title-6,
.subtitle-6 {
    --text-font: var(--fs-16);
    font-size: var(--text-font);
    font-weight: var(--fw-700);
}

.title-7,
.subtitle-7 {
    --text-font: var(--fs-14);
    font-size: var(--text-font);
    font-weight: var(--fw-700);
}

.title-8,
.subtitle-8 {
    --text-font: var(--fs-12);
    font-size: var(--text-font);
    font-weight: var(--fw-700);
}

.title-9,
.subtitle-9 {
    --text-font: var(--fs-28);
    font-size: var(--text-font);
    font-weight: var(--fw-700);
}

.text-primary {
    ---text-color: var(--text-primary);
    color: var(---text-color);
}

.d-flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitle-1,.subtitle-2,.subtitle-3,.subtitle-4,.subtitle-5,.subtitle-6,.subtitle-7,.subtitle-8 {---text-color: #ACB6D3;color: var(---text-color);}
.text-2,
.text-1{color: var(---text-color)!important;}
.text-2{---text-color: #5A5F6A!important}
.text-1{---text-color: #898C94!important}
.fs-10 {font-size: var(--fs-10) !important ;}
.fs-11 {font-size: var(--fs-11) !important ;}
.fs-12 {font-size: var(--fs-12) !important ;}
.fs-13 {font-size: var(--fs-13) !important ;}
.fs-14 {font-size: var(--fs-14) !important ;}
.fs-15 {font-size: var(--fs-15) !important ;}
.fs-16 {font-size: var(--fs-16) !important ;}
.fs-17 {font-size: var(--fs-17) !important ;}
.fs-18 {font-size: var(--fs-18) !important ;}
.fs-19 {font-size: var(--fs-19) !important ;}
.fs-20 {font-size: var(--fs-20) !important ;}
.fs-22 {font-size: var(--fs-22) !important ;}
.fs-23 {font-size: var(--fs-23) !important ;}
.fs-24 {font-size: var(--fs-24) !important ;}
.fs-25 {font-size: var(--fs-25) !important ;}
.fs-26 {font-size: var(--fs-26) !important ;}
.fs-27 {font-size: var(--fs-27) !important ;}
.fs-28 {font-size: var(--fs-28) !important ;}
.fw-2 {font-weight: var(--fw-200) !important}
.fw-3 {font-weight: var(--fw-300) !important}
.fw-4 {font-weight: var(--fw-400) !important}
.fw-5 {font-weight: var(--fw-500) !important}
.fw-6 {font-weight: var(--fw-600) !important}
.fw-7 {font-weight: var(--fw-700) !important}
.fw-8 {font-weight: var(--fw-800) !important}
.d-column-center {display: flex;flex-direction: column;align-items: center;justify-content: center;}
.d-column {display: flex;flex-direction: column;}
body a {color: var(---text-color);text-decoration: none;line-height: 1.5;}
body .ps-color {---text-color: var(--tab-color) !important;color: var(---text-color);}
.picture-opacity-1 {position: relative;display: block;}
.picture-opacity-1 img {object-fit: contain;object-position: center;height: 100%;width: 100%;}
.d-align-center {display: flex !important;align-items: center;}
.form_group .custom-select {width: 100%;}
.custom-select .custom-select__indicator-separator {display: none;}
.custom-select .custom-select__indicator {padding: 10px 6px;}
.form-size-sm .custom-select .custom-select__indicator {padding: 4.7px 6px;}
.form-size-sm .custom-select .custom-select__control{min-height: 32.2px;}
.form-size-sm .custom-select .custom-select__control .custom-select__value-container{padding: 4px 8px 4px 14px;}
.form-size-sm .custom-select .custom-select__control .custom-select__value-container .custom-select__input-container{line-height: 1;margin-top: 0px;margin-bottom: 0px;}

.form-size-md .custom-select .custom-select__indicator {padding: 8.7px 6px;}
.form-size-md .custom-select .custom-select__control{min-height: 32.2px;}
.form-size-md .custom-select .custom-select__control .custom-select__value-container{padding: 4px 8px 4px 14px;}
.form-size-md .custom-select .custom-select__control .custom-select__value-container .custom-select__input-container{line-height: 1;margin-top: 0px;margin-bottom: 0px;}

.custom-select .custom-select__indicator svg {
    fill: var(--text-color);
    stroke-width: 0px !important;
}
.custom-select .custom-select__control {border-radius: var(--bs-border-radius);border-color: var(--fr-border-color);background: var(--fr-body-bg);box-shadow: none !important;}
.custom-select .custom-select__control .custom-select__value-container {padding: 6px 8px 6px 14px;min-width: 54px;}
.custom-select .custom-select__control .custom-select__value-container .custom-select__single-value {color: var(---text-color);font-weight: var(--fw-500);}
.custom-select .custom-select__control .custom-select__value-container .custom-select__placeholder {font-size: var(--fr-font-size);}
.custom-select .input-group>.custom-select {width: 100%;}
.custom-select .custom-select__option.custom-select__option--is-selected {background-color: #a8ceff;color: var(---text-color);}
.custom-select .custom-select__option.custom-select__option--is-focused {background-color: #edf0f4;}
.form_group.lg .custom-select .custom-select__indicator {padding: 14.2px 6px;}
.custom-select .custom-select__menu {min-width: fit-content;}
thead tr th .form-check-input {
    --form-border: #C1C1C1;
    --bs-border-width: 1px
}

.form_group {
    --fr-label-font-size: var(--fs-13);
    --fr-label-fw: var(--fw-400);
    --fr-input-fw: 400;
    --fr-font-size: var(--fs-13);
    --fr-padding: var(--padding);
    --fr-line-height: 1.5;
    --fr-body-bg: transparent;
    --bs-body-bg: var(--fr-body-bg);
    --fr-border-width: 1px;
    --fr-border-color: #E7E7E7 !important;
    --bs-body-color: var(---text-color);
}
.form_group {--fr-label-font-size: var(--fs-13);--fr-label-fw: var(--fw-400);--fr-input-fw: 400;--fr-font-size: var(--fs-13);--fr-padding: var(--padding);--fr-line-height: 1.5;--fr-body-bg: transparent;--bs-body-bg: var(--fr-body-bg);--fr-border-width: 1px;--fr-border-color: var(--fr-form-border);--bs-body-color: var(---text-color);}
.form_group .quill .ql-toolbar.ql-snow{border-radius: var(--bs-border-radius) var(--bs-border-radius) 0px 0px;background-color: #F7F9FD;border-color: var(--fr-border-color);}
.form_group .quill .ql-container.ql-snow{border-radius: 0px 0px var(--bs-border-radius) var(--bs-border-radius) ;border-color: var(--fr-border-color);}
.form_group .quill .ql-container.ql-snow .ql-editor{min-height: 200px;}
.form_group.border-0 .quill .ql-toolbar.ql-snow{border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;}
.form_group.border-0  .quill .ql-container.ql-snow{border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;}
.form_group .form-check:not(.form-switch) {display: inline-flex;align-items: center;padding-left: 30px;gap: 0px;position: relative;}
.form_group .form-check-input:checked {
    background-color: var(--text-primary);
    border-color: var(--text-primary);
}

.form_group .form-check-input:focus {
    border-color: var(--text-primary);
}
.form_group .form-check:not(.form-switch) .form-check-input {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 4px;
    margin: 0px;
    --bs-border-color: #C1C1C1;
}
.form_group.form-light-secondary .input-group, .form_group.form-light-secondary textarea{--fr-body-bg: #F2F6FB; --fr-border-color:var(--fr-form-border);}
.form_group.form-size-xsm {--fr-line-height: 1.2;--fr-font-size: 12px;--fr-padding: 3px 7px}
.form_group.form-size-xsm .input-group-text .common_icon{--height: 18px;--width: 18px;} 
.form_group.form-size-sm {--fr-line-height: 1.6;--fr-font-size: 13px;--fr-padding: 5px}
.form_group.form-size-md {--fr-line-height: 1.7;--fr-font-size: 13px;--fr-padding: 7px}
.form_group.md {--fr-line-height: 1.5;--fr-font-size: var(--fs-15)}
.form_group.lg {--fr-line-height: 1.5;--fr-font-size: var(--fs-15);}
.form_group .input-group{flex-wrap: nowrap;}
.form_group.normal-control .form-control.is-invalid{--fr-border-color:var(--bs-form-invalid-color)}
.form_group.input_group_icon .input-group,
.form_group.normal-control .form-control {
    background: var(--fr-body-bg);
    border-radius: var(--bs-border-radius);
}

.form_group.normal-control .form-control.otp {width: 50px !important;height: 50px;}
.form_group.form-group-otp {--fr-font-size: var(--fs-18) !important;--fr-input-fw: 500;--fr-border-color: #E4E4E7}
.form_group.trans-primary {--fr-body-bg:var(--trans-primary)}
.form_group.secondary-primary {--fr-body-bg: var(--fr-secondary-primary);--fr-border-color: var(--fr-form-border);}
.form_group.secondary-primary .form-control:focus,.form_group.secondary-primary .form-select:focus {--fr-border-color: var(--grey-light-color);}
.form_group .form-select:focus {box-shadow: none;border-color: var(--fr-border-color);}
.form_group .form-control {
    --bs-border-width: var(--fr-border-width);
    --bs-border-color: var(--fr-border-color);
    --bs-body-bg: var(--fr-body-bg);
    display: block;
    width: 100%;
    padding: var(--fr-padding);
    font-size: var(--fr-font-size);
    font-weight: var(--fr-input-fw);
    line-height: var(--fr-line-height);
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: var(--fr-transition);
}
.form_group .form-control::placeholder{
    color: #9c9c9c; /* Replace with your desired color */
    opacity: 1; 
}
.form_group :not(.is-invalid) .form-control:focus,
.form_group:focus-within .right_text,
.form_group:focus-within .left_text {
    color: var(--bs-body-color);
    --fr-border-color: var(--fr--hover-border-color);
    background-color: var(--bs-body-bg);
    border-color: var(--text-primary);
    outline: 0;
    box-shadow: none;
}
.form_group .form-select {--bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);display: block;width: 100%;padding: var(--select-padding);font-size: var(--fr-font-size);font-weight: 400;line-height: var(--fr-line-height);color: var(--bs-body-color);-webkit-appearance: none;-moz-appearance: none;appearance: none;background-color: var(--bs-body-bg);background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);background-repeat: no-repeat;background-position: right 0.75rem center;background-size: 16px 12px;border: var(--bs-border-width) solid var(--bs-border-color);border-radius: var(--bs-border-radius);transition: var(--fr-transition);}
.form_group.form-size-sm   .form-select{--select-padding: 0.3015624rem 2.25rem 0.3015624rem 0.75rem}
input::placeholder,input::-ms-input-placeholder {color: #BFBFCC !important;opacity: 0.6;font-weight: var(--bs-btn-font-weight);}
.form_group input:focus {box-shadow: none !important;}
.form_group .right-input,.form_group .left_text {border-right-color: transparent !important;}
.form_group .right-input {padding-right: 0px;}
.form_group .left-input {border-left-color: transparent !important;padding-left: 0px;}
.form_group .right_text {border-top-left-radius: 0;border-bottom-left-radius: 0;border-left-color: transparent !important;}
.form_group .right_text,.form_group .left_text {margin: 0px;--bs-border-color: var(--fr-border-color);--bs-tertiary-bg: var(--fr-body-bg);display: flex;align-items: center;justify-content: center; padding: var(--fr-padding);font-size: 1rem;font-weight: 400;line-height: 1.5;color: var(--bs-body-color);text-align: center;white-space: nowrap;background-color: var(--bs-tertiary-bg);border: var(--bs-border-width) solid var(--bs-border-color);border-radius: var(--bs-border-radius);transition: var(--fr-transition);min-width: 30px;}
.form_group .form-label,
.form-label {
    font-size: var(--fr-label-font-size);
    font-weight: var(--fr-label-fw);
}
.form_group.form-validate,.form_group.form-validate .input-group-text {--fr-border-color: #dc3545 !important;}
.form_group .right_text:not(:last-child){border-right-color: transparent!important;}
.borderless.border-zero{--bs-table-border-color: transparent;}
.form_group  .react-tel-input .form-control{height: auto;line-height: normal;font-size: var(--fr-font-size);line-height: var(--fr-line-height);border-color: var(--fr-border-color);}
.form_group  .react-tel-input .flag-dropdown {border-color: var(--fr-border-color);border-right-width: 0px;}
.form_group .react-tel-input .form-control:focus~.flag-dropdown,
.form_group.normal-control .form-control:focus {
    --fr-border-color: var(--text-primary);
}
.min-height-container{min-height: calc(100vh - 120px);}
.company-info{display: flex;}
.company-info .details h5{font-size: 18px;}
.company-info .details h5:first-child{font-weight: 900;}
.company-info h5 span{font-weight: 600;}
.company-info .details{margin-right: auto;}
.company-info .company-contact h5{font-size: 16px;}
.company-info .company-contact{margin-left: auto;}
.company-info .picture-opacity-1 img{height: 80px;width: 80px;}
.table-rows .table-cell-row .table-header{font-weight: 600;min-width: 180px;width: auto;}
.table-rows .table-cell-row .table-data{width: calc(100% -  180px);}
.table-rows .table-cell-row{display: flex;margin-bottom: 6px;font-size: 16px;}


body .dropdown-menu .dropdown-item:focus,body .dropdown-menu .dropdown-item:active,body .dropdown-menu:focus-visible .dropdown-item {--bs-dropdown-link-active-bg: #ebf0ff;outline: 1px !important;}
body .dropdown-menu.border-bottom .dropdown-item {position: relative;font-size: var(--fs-13);}
body .dropdown-menu.border-bottom .dropdown-item:not(:last-child) {padding-bottom: 8px;padding-top: 7px;}
body .dropdown-menu.border-bottom .dropdown-item:not(:last-child)::after {content: "";width: calc(100% - 20px);left: 10px;right: 10px;height: auto;border-bottom: 1px solid #E6E6E6;position: absolute;bottom: 0px;}
body .dropdown-menu {--bs-dropdown-box-shadow: 0px 3px 9px 1px #00000040;box-shadow: var(--bs-dropdown-box-shadow);--bs-dropdown-border-color: transparent}
.type-input .dropdown-item,.type-dropdown .dropdown-item {display: flex;align-items: center;cursor: pointer;}
.type-input .dropdown-item .circle,.custom-select__option .circle {--bs-border-color: #CACACD;height: 16px;width: 16px;border: 1px solid var(--bs-border-color);display: inline-flex;margin-right: 10px;border-radius: 400px;align-items: center;justify-content: center;line-height: 1;position: relative;}
.common-dropdown .dropdown-menu .dropdown-item.selected .circle,.custom-select__option .circle.select {background-color: var(--text-primary);--bs-border-color: transparent;}
.common-dropdown .dropdown-menu {max-height: 300px;overflow: overlay;}
.confirm-modal .modal-footer{--bs-modal-footer-gap: 0.75rem 1.5rem 0.75rem 1.5rem;padding: var(--bs-modal-footer-gap);border-color: transparent;position: relative;}
.confirm-modal .modal-footer::after{
    content: "";
    display: block;
    border-top: 1px solid #D5D5D5;
    width: calc(100% - 50px);
    margin: 0 auto;
    position: absolute;
    top: 1px;
    left: 25px;
}
.breadcrumb-main {
    padding: 10px 0px !important;
}
.breadcrumb-main .breadcrumb {
    margin-bottom: 0px;
    font-weight: var(--fw-600);
}
.breadcrumb-main .breadcrumb-item {display: inline-flex;align-items: center;}
.breadcrumb-main .breadcrumb-item+.breadcrumb-item::before {
    content: "";
    font-size: 20px;
    line-height: 20px;
    height: 20px !important;
    width: 20px !important;
    --icon-url: url(./../../public/assets/img/AngleRightIcon.svg);
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    background-color: var(--text-color);
    height: var(--height);
    width: var(--width);
    display: inline-block;
    position: relative;
    mask-image: var(--icon-url);
    --webkit-mask-image: var(--icon-url);
    -webkit-mask-size: contain;
    mask-size: contain;
}
.breadcrumb-main .breadcrumb-item.active {color: var(--text-primary);}
.body-content .breadcrumb-item.active{color: var(--text-primary);}
.body-content .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, ">");
}
.breadcrumb-main .breadcrumb-item+.breadcrumb-item{
    padding-left: 0px;
}
.common-dropdown .dropdown-menu {min-width: 100%;}
body .btn.rounded {--bs-border-radius: 100px !important}
/* Icon */
/* .common_icon_image .picture-opacity-1 {height: 100%;width: 100%;display: flex;} */
/* .common_icon_image .picture-opacity-1 img{    vertical-align: 4px;} */
.common_icon {
    --height: 20px;
    --width: 20px;
    --text-primary-color: var(--text-color);
    --icon-url: none;
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    background-color: var(--text-primary-color);
    height: var(--height);
    width: var(--width);
    display: inline-block;
    position: relative;
    mask-image: var(--icon-url);
    --webkit-mask-image: var(--icon-url);
    -webkit-mask-size: contain;
    mask-size: contain;
    vertical-align: -4px;
}
.common_icon.light-blue{--text-primary-color: #8C9DCB!important}
.common_icon.rotate-90{rotate: 90deg;}
.common_icon.rotate-120{rotate: 120deg;}
.common_icon.rotate-180{rotate: 180deg;}
.common_icon.rotate-240{rotate: 240deg;}
.common_icon.rotate--90{rotate: -90deg;}
.common_icon.rotate--120{rotate: -120deg;}
.common_icon.rotate--180{rotate: -180deg;}
.common_icon.rotate--240{rotate: -240deg;}

/* .main-section .common_icon_image .picture-opacity-1 img{filter: invert(1);} */
.main-section .common_icon_image.profile-sm .picture-opacity-1 img{filter: invert(0);}
.main-section .active .common_icon_image .picture-opacity-1 img, body.theme-purple .metis_menu .list-group>a:hover .list-group-item .picture-opacity-1 img{filter: invert(0);}

.common_icon_image {
    --height: 20px;
    --width: 20px;
    height: var(--height);
    width: var(--width);
    display: inline-block;
    cursor: pointer;
}
.common_icon.xxl,.common_icon_image.xxl {--height: 50px;--width: 50px;}
.common_icon.profile-sm,.common_icon_image.profile-sm {--height: 90px;--width: 90px;}
.common_icon.xxxl,.common_icon_image.xxxl {--height: 75px;--width: 75px;}
.common_icon.lg,.common_icon_image.lg {--height: 30px;--width: 30px;}
.common_icon.nm,.common_icon_image.nm {--height: 18px;--width: 18px;}
.common_icon.md,.common_icon_image.md {--height: 22px;--width: 22px;}
.common_icon.sm,.common_icon_image.sm {--height: 17px;--width: 17px;}
.common_icon.sm,.common_icon_image.sm-x {--height: 14px;--width: 14px;}
.common_icon.xsm,.common_icon_image.xsm {--height: 8px;--width: 8px;}
.common_icon.xxsm,.common_icon_image.xxsm {--height: 7px;--width: 7px;}
.common_icon.danger {--text-primary-color: #e31a1a}
.common_icon.white {--text-primary-color: white}
.common_icon.grey {--text-primary-color: #888a91}
.common_icon.green {--text-primary-color: #01CC83}
.common_icon.warning {--text-primary-color: var(--warning-color)}
.common_icon.primary, body .btn.button i.common_icon.primary {--text-primary-color: var(--text-primary)}
.common_icon.primary-dark {--text-primary-color: #AB6E18}
.trainer_main {display: flex;}
.trainer_main>aside.main-sidebar {width: var(--width-sidebar);height: 100vh;display: block;}
.trainer_main>.trainer_body {padding: 0px 1px 0px var(--width-sidebar);width: 100%;will-change: auto;transition: padding 0.15s;min-height: 100vh;max-height: 100vw;height: 100%;overflow: hidden;}

/* Scrolling */
.side-scrolled {height: 100%;overflow: overlay;margin-right: 3px;padding-bottom: 15px;}
.side-scrolled::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: var(--text-secondary);
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.common-dropdown .dropdown-menu::-webkit-scrollbar-track,
.side-scrolled::-webkit-scrollbar-track,
.body-scroll::-webkit-scrollbar-track {-webkit-box-shadow: none;background-color: #ffffff00;}
.common-dropdown .dropdown-menu::-webkit-scrollbar,.side-scrolled::-webkit-scrollbar,.body-scroll::-webkit-scrollbar {width: 7.side-scrolledpx;background-color: transparent;}
.common-dropdown .dropdown-menu::-webkit-scrollbar-thumb,.side-scrolled::-webkit-scrollbar-thumb,.body-scroll::-webkit-scrollbar-thumb {background-color: #dddbdb;border: 3px solid #ffffff00;border-radius: 30px;}
.common-dropdown .dropdown-menu::-webkit-scrollbar {background-color: #fff;}
.common-dropdown .dropdown-item:focus,.common-dropdown .dropdown-item:hover {background-color: #f0f3fc;}
.common-dropdown .dropdown-item.search:focus,.common-dropdown .dropdown-item.search:hover {background-color: white !important;}
.common-dropdown .dropdown-item.search {position: sticky;top: -12px;display: block;background: white;z-index: 9;}
/* header2-wrapper */
.header2-wrapper {padding-top: 83px;width: 100%;}
.header2-wrapper .header2 {margin-top: 0px;}
/* Scrolling */
.side-footer {position: absolute;width: 100%;bottom: 0px;padding: 25px 1.2rem;border-top: 1px solid var(--border-color);}
.side-footer .list-group-item,.pointer {cursor: pointer;}
/* header */
.header.header-sticky {position: -webkit-sticky;position: sticky;top: 0;z-index: 1029;border-bottom: 1px solid var(--b-color);background-color: var(--bs-body-bg);}
.header .container-fluid {display: flex;}
.header .header-title {padding: 1.4rem 0.75rem;}
.header .header-title h5 {margin: 0px;font-size: var(--fs-22);font-weight: var(--fw-700);}
.header .header-login-container {display: flex;align-items: center;}
.header .header-left {display: inline-flex;align-items: center;gap: 10px;font-size: var(--fs-14);font-weight: var(--fw-600);}
.header .btn-links {--bs-btn-font-weight: var(--fw-500)}
.header.login-state {box-shadow: 0px 4px 7px 0px #00000024;position: absolute;width: 100%;}
/* Body */
.body {max-height: calc(100vh - 73.5px);padding-top: 1rem;padding-bottom: 1rem;overflow: overlay;}
body #gtx-trans {display: none !important;}
.dashboard-layout {background-color: var(--bg-body-color);}
.guest-layout {height: 100vh;max-height: 100vh;position: relative;padding-top: 76.3px;}
.guest-layout>* {flex: 1 1 auto;width: 100%;}
.guest-layout .banner-divide {position: relative;min-height: calc(50vh - 60px);}
.guest-layout .banner-divide video {position: absolute;height: 100%;width: 100%;object-fit: cover;object-position: top;}
/* .guest-layout .banner-divide::after{height: 100%;width: calc(100% + 60px);background-image: url(./../public/assets/image/login_banner.png);position: absolute;background-position: 0px 0;background-repeat: no-repeat;background-size: cover;margin-top: 0px;min-height: 50px;} */
.guest-layout .card {display: block;--bs-card-border-color: transparent;height: 100%;--bg-border-radius: 20px 0px 0px 20px;--bs-card-spacer-y: 1rem;--bs-card-spacer-x: 1rem;--bs-card-title-spacer-y: 0.5rem;--bs-card-title-color: ;--bs-card-subtitle-color: ;--bs-card-border-width: var(--bs-border-width);--bs-card-border-color: var(--bs-border-color-translucent);--bs-card-box-shadow: ;--bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));--bs-card-cap-padding-y: 0.5rem;--bs-card-cap-padding-x: 1rem;--bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);--bs-card-cap-color: ;--bs-card-height: ;--bs-card-color: ;--bs-card-bg: var(--bs-body-bg);--bs-card-img-overlay-padding: 1rem;--bs-card-group-margin: 0.75rem;border-radius: var(--bg-border-radius);}
.guest-container {max-width: 520px;margin: 0 auto;padding: 20px 0px 0px 0px;}
.guest-container .logo {min-height: 68px;min-width: 68px;}
.guest-container .logo-lg {min-height: 98px;min-width: 98px;}
.br-0 {--bs-border-radius: 0px;border-radius: var(--bs-border-radius) !important;}
.br-1 {--bs-border-radius: 5px;border-radius: var(--bs-border-radius) !important;}
.br-2 {--bs-border-radius: 8px;border-radius: var(--bs-border-radius) !important;}
.br-3 {--bs-border-radius: 11px;border-radius: var(--bs-border-radius) !important;}
.d-flex-center {display: flex !important;align-items: center;justify-content: center;}
.bg-secondary-1 {background-color: #F1F1F1 !important;}
.dataTables_wrapper table tbody tr td.top {vertical-align: top;}
/* .dataTables_wrapper table tbody tr td {vertical-align: middle;} */
.CommonPagination {padding: 10px 10px;display: flex;--bs-primary-rgb: white;--bs-primary: var(--second-primary);--white-color: white}
.CommonPagination .pagination {margin-left: auto;padding: 0px;}
.pagination {padding: 10px;background-color: var(--bs-primary-rgb);gap: 4px;border-radius: 10px;display: inline-flex;margin-bottom: 0;justify-content: flex-end;}
.pagination .page-item .page-link {border: 1px solid transparent;background-color: transparent;color: #898989;white-space: nowrap;display: flex;justify-content: center;align-items: center;width: 35px !important;height: 35px !important;padding: 0 !important;border-radius: 100% !important;font-size: 15px;}
.pagination .page-item.active .page-link {
    box-shadow: 0px 7px 20px 0 rgba(var(--white-color), 0.4);
    background-color: var(--text-primary);
    color: white;
}
.pagination .page-item .page-link:focus {outline: 0;box-shadow: 0 0 0 0.1rem rgba(var(--bs-primary-rgb), 0.4);}
.pagination li:first-child {background-color: var(--white-color);border-radius: 100% !important;}
.pagination li:last-child {background-color: var(--white-color);border-radius: 100% !important;}
/* card-upload */
.card-upload {max-width: 290px;width: 290px;border: 1px dashed #B7B7D3;--border-radius: 10px;border-radius: var(--border-radius);cursor: pointer;background: linear-gradient(0deg, #F9F9FC, #F9F9FC);}
.preview .card-upload, .preview .upload-container-dev:not(.col-12, .col-lg-6, .col-lg-4){max-width: 100%;width: 100%;}
.card-upload.common_file_upload .upload-container:not(.document){min-height: 215px;}
.card-upload.common_file_upload .upload-container.document .document_file{position: absolute;right: 10px;}
.card-upload.common_file_upload .upload-container.document{padding: 15px 50px 15px 10px;align-items: flex-start;}
.card-upload.common_file_upload .upload-container.document.left_icon {padding-left: 50px;}
.card-upload.common_file_upload .upload-container.document.left_icon .common_icon_image:first-child{position: absolute;left: 15px;}
.card-upload .upload-container {padding: 25px 20px;display: flex;flex-direction: column;align-items: center;justify-content: center;position: relative;}
.card-upload .upload-container p {margin-bottom: 5px;}
.upload-file-preview.view{flex-direction: column;align-items: flex-start;position: relative;}
.upload-file-preview.view .profile-sm{min-height: 156px;max-height: 156px;width: 100%;height: 100%;display: flex;border-radius: 10px;overflow: hidden;}
.upload-file-preview.view .profile-sm .picture-opacity-1 img{height: 100%;width: 100%;object-fit: cover;}
.upload-file-preview.view .profile-sm .video_player{min-height: 156px;}
.upload-file-preview.view .profile-sm video{height: 100%;width: 100%;object-fit: cover;}
.upload-container-dev.mb-3.is-invalid{margin-bottom: 4px!important;}
.upload-file-preview.view .file_details{width: 100%!important;padding-left: 0px!important;padding-top: 10px!important;position: relative;padding-right: 30px;}
.upload-file-preview.view .file_details a{position: absolute;right: 0px;top: 10px;cursor: pointer;}
.upload-file-preview.view .file_details p{  white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
/* bulk-add */
.bulk-add {display: flex;flex-wrap: wrap;}
.bulk-add .upload-file-preview:not(.document, .view) .profile-sm{background-color: #e7eaf3;border-radius: 4px;width: 90px;height: 90px;}
.bulk-add .upload-file-preview:not(.document, .view) .profile-sm .picture-opacity-1 img{object-fit: cover;object-position: center;border-radius: 4px;}
.bulk-add .upload-file-preview.document .profile-sm{border-radius: 4px;width: 80px;height: 90px;padding: 15px;position: relative;}
.bulk-add .upload-file-preview.document .profile-sm .container-image{height: 100%;width: 100%;position: relative;display: flex;align-items: center;justify-content: center;}
.bulk-add .upload-file-preview.document .profile-sm i{--height:100%; --width: 100%; --text-primary-color: var(--text-primary)}
.bulk-add .upload-file-preview.document .profile-sm span{color: var(--bs-white);background-color: var(--text-primary);font-weight: 600;font-size: 36%;padding: 2px 5px;display: inline-flex;align-items: center;justify-content: center;text-transform: uppercase;border-radius: 3px;position: absolute;left: -3px;margin-top: 30%;}
.bulk-add .upload-file-preview{margin-bottom: 10px;width: 100%;display: flex;align-items: center;}
.bulk-add .upload-file-preview .file_details{width: calc(100% - 90px);padding-left: 10px;}
.bulk-add .upload-file-card {margin-bottom: 10px;border: 1px solid var(--grey-light-color);;border-radius: var(--bs-border-radius-sm);padding: 15px 30px 15px 50px;min-width: 276px;display: flex;align-items: center;position: relative;}
.bulk-add .upload-file-card .upload-status {width: 100%;}
.bulk-add .upload-file-card i:first-child {position: absolute;left: 10px;}
.bulk-add .upload-file-card i.close-btn {cursor: pointer;position: absolute;right: 10px;}
.bulk-add .upload-file-card .progress {height: 3px;}
.bulk-add .document-sm .upload-file-preview .profile-sm{width: 60px;padding: 12px;height: 70px;}
.bulk-add .document-sm .upload-file-preview .file_details h6{font-size: 12px;margin-bottom: 0px!important;font-weight: 600;}
.bulk-add .document-sm .upload-file-preview .file_details p{font-size: 13px;margin-bottom: 0px!important;}
.bulk-add .document-sm .upload-file-preview .file_details a{font-size: 13px;}
.bulk-add h5{--text-color:#7C7C87; color: var(--text-color)!important;}
.course-level-item{display: flex;border-bottom: 1px solid var(--bs-border-color);padding: 10px 20px;}
.course-level-item .form_group{max-width: 270px;width: 100%;}
.course-level-item .button-common {margin-top: auto;padding: 4px 20px;}
.ScrollbarsSidebar{padding-bottom: 20px;}
.scrollable-x.nav-tabs{flex-wrap: nowrap;white-space: nowrap;padding-bottom: 3px!important;gap: 5px;}
.scrollable-x {overflow-x: auto;overflow-y: hidden;-webkit-overflow-scrolling: touch;}
.scrollable-x::-webkit-scrollbar {height: 6px;background-color: white;position: absolute;top: 0px;}
.scrollable-x::-webkit-scrollbar-thumb {background-color: rgba(0, 0, 0, 0.2);border-radius: 3px;}
.scrollable-x::-webkit-scrollbar-button {display: none;}
.scrollable-y {overflow-x: hidden;overflow-y: auto;background-color: transparent;background-repeat: no-repeat;background-position: left center, right center, left center, right center;background-size: 10px 100%, 10px 100%, 10px 100%, 10px 100%;background-attachment: scroll, scroll, local, local;-webkit-overflow-scrolling: touch;height: 100%;}
.scrollable-y::-webkit-scrollbar {height: 6px;width: 6px;background-color: white;position: absolute;top: 0px;}
.scrollable-y::-webkit-scrollbar-track {background-color: transparent;}
.scrollable-y::-webkit-scrollbar-thumb {background-color: #999;border-radius: 3px;}
.scrollable-y::-webkit-scrollbar-button {display: none;}
/* page-header */
.page-header {padding: 1rem 0px;}
.card.card-report>.card-body,.card.report-box>.card-body {padding: 1.5rem;}
.card.card-report,.card.report-box {--bs-card-border-color: var(--grey-light-color);;}
.card.card-report .card .card-body {--bs-card-spacer-x: 1.5rem;--bs-card-spacer-y: 1.5rem;display: flex;flex-direction: column;justify-content: center;align-items: center;gap: 5px;}
.card.card-report .card {background: var(--bs-card-bg);}
.card.card-report .tran-danger {--bs-card-border-color: #FFDBDB;--bs-card-bg: linear-gradient(0deg, #FFF1F1, #FFF1F1);}
.card.card-report .tran-warning {--bs-card-border-color: #FFE2B9;--bs-card-bg: linear-gradient(0deg, #FFF7EC, #FFF7EC);}
.card.card-report .tran-success {--bs-card-border-color: #AEF6DA;--bs-card-bg: linear-gradient(0deg, #E9FBF4, #E9FBF4);}
.card.card-br-3 {--bs-card-border-radius: 11px;--bs-card-cap-bg: var(--bs-body-bg);--bs-card-border-color: var(--grey-light-color);;}
.card.card-br-3 .fs-17{margin-bottom: 0px;}

.common-card-border .card-header {padding: 14px 1rem !important;}
.card .card-header {border-top-left-radius: var(--bs-card-border-radius) !important;border-top-right-radius: var(--bs-card-border-radius) !important;}
.common-card-border .table thead th{--bs-table-bg: #F7FAFD;--bs-border-width: 0px;padding: .75rem .5rem;}
/* report-box */
.report-box .box {margin-bottom: 15px;--bs-border-color: #E6E6E6;--bs-border-radius: 4px;position: relative;transition: background 1s ease-in-out 2s, width 2s linear;box-shadow: 0px 10px 23px 0px #234DCA1A;border: 1px solid var(--bs-border-color);border-radius: var(--bs-border-radius);}
.report-box .box.active::after {content: "";height: 4px;background-color: var(--warning-color);width: 100%;bottom: 0px;position: absolute;display: block;border-radius: 40px;}
.report-box .box.active .box-wrapper::after {height: auto;content: "";border: 1px solid var(--bs-border-color);width: calc(100% - 70px);display: block;position: absolute;bottom: 0px;left: 0px;margin: 0px 35px;}
.report-box .box .box-wrapper {padding: 1rem 1rem 1rem 4rem;position: relative;display: flex;align-items: center;cursor: pointer;}
.report-box .box .box-wrapper .box-icon {position: absolute;left: 1rem;}
.report-box .box .box-wrapper .box-content {width: 100%;}
.report-box .box .collapse {padding: 20px 10px;}
.report-box .box .collapse .box-padding {padding-left: .5rem;padding-right: .5rem;}
.report-box .box .box-row {margin: 0px;}
.report-box .box .box-filter {display: flex;flex-wrap: wrap;}
.report-box .box .box-filter>.d-inline-block {width: 100%;}
.report-box .box .box-filter>.d-inline-block>label {min-width: 5rem;}
.report-box .box .collapse .box-padding .form_group.d-inline-block {min-width: calc(100% - calc(5rem + 30px));}
.header2 .navbar-collapse .nav-link.active-link {background-color: #D6801A;}
body .side-offcanvas {--bs-offcanvas-width: 350px}
.side-offcanvas ul {list-style: none;padding: 0px;}
.side-offcanvas ul li {position: relative;}
.side-offcanvas ul li:not(:last-child)::after {content: "";width: calc(100%);height: auto;border-bottom: 1px solid #ccc;display: block;border-radius: 40px}
.side-offcanvas ul li a {padding: 8px 20px 8px 20px;display: block;}
.navbar-toggler {--bs-navbar-toggler-border-color: transparent;width: 100%;--bs-navbar-color: var(--text-white)}
.navbar-toggler .common_icon {--text-primary-color: var(--text-white)}
.body-head-wrapper .head-filter {white-space: nowrap;}
.body-head-wrapper .head-filter .row {padding-top: 6px;padding-bottom: 6px;}
.body-head-wrapper .head-filter .col-6 {margin-bottom: 5px !important;margin-top: 5px !important;}
.card .card-head-wrapper {display: flex;align-items: center;gap: 10px;}
.card .card-head-wrapper .tbl-sub-heading,.card .card-head-wrapper .card-heading-action {width: auto;}
.card .card-head-wrapper .card-heading-action {display: flex;margin-left: auto;white-space: nowrap;}
.card .card-head-wrapper .card-heading-action .button-filter {padding: 0px 5px;}
.card .card-head-wrapper .card-heading-action .button-filter>* {width: 100%;}
.card .card-head-wrapper .card-heading-action .button-filter.user-type{min-width: 160px;max-width: 311px;}
/* Main css */
.main-section{display: block;height: 100%;width: 100%;background-color: white;}
.main-section .content-outlet {
    padding-left: var(--width-sidebar);
    height: 100vh;
    width: 100%;
    /* padding-right: 7px; */
}
.main-section .content-outlet header{top: 0px;width: 100%;padding: 10px 25px;border:0px solid var(--grey-light-color);;position: relative;z-index: 2;display: flex;align-items: center;height: 70px;background-color: var(--bs-white);border-left: none;border-right: none;}
.main-section .content-outlet .body-content {
    padding: 10px 10px;
    /* border-radius: var(--bs-border-radius); */
    background-color: #f5f4f3;
}

/* tab-view */
.tab-view{overflow: hidden;}
.tab-view .last-tab-ms-auto{padding: 1px 10px;}
.tab-view .last-tab-ms-auto li:last-child{margin-left: auto;}
.tab-view .last-tab-ms-auto li:last-child .nav-link{padding: 0px;}
.tab-view .nav-item {display: flex;align-items: center;}
.tab-view .nav-item .nav-link{position: relative; --bs-nav-link-padding-x: 1rem;--bs-nav-link-padding-y: 1rem;border-color: transparent!important;font-weight: 600;font-size: var(--fs-15);color: var(---text-color);}
.tab-view .nav-item .nav-link::after{transition: all 0.15s ease-in; opacity: 0; visibility: hidden;	transition: 0.25s ease-out;border: 2px solid var(--text-primary);content: "";width: 100%;height: auto;position: absolute;bottom: -3px;left: 0px;}
.tab-view .nav-item .nav-link.active::after{visibility: visible; opacity: 1;transition: opacity 0.45s ease-in;  }
.tab-view .nav-tabs{padding: 1px 10px;}
.tab-view .sm .nav-item .nav-link{--bs-nav-link-padding-x: 0.5rem;--bs-nav-link-padding-y: 0.75rem;font-size: 13px;}
.tab-view .sm .nav-item .nav-link.alert-badge::before{content: "";border: 0.24rem solid red;display: block;position: absolute;top: 0px;right: 0px;border-radius: 7px;box-shadow: inset rgb(255 0 0) 0px 0px 20px 0px, rgb(255 0 0 / 24%) 0px 0px 6px 1px, rgb(255 0 0 / 29%) 0px 0px 4px 1px}
.tab-view .normal .nav-item .nav-link:not(.active) {color: grey;font-weight: 600;}
.tab-view .normal .nav-item .nav-link.button{height: auto;color: var(--bs-btn-color)!important;}
.tab-view .nav-item .nav-link .form_group{--fr-label-font-size: var(--fs-16);--fr-font-size: var(--fs-12);--fr-line-height: 1;}
.tab-view .tab-link{min-width: 160px;--bs-nav-link-padding-y: 0.5rem!important;display: flex;align-items: center;justify-content: center;}
/* course-module */
.course-module .accordion{--bs-accordion-inner-border-radius: calc(var(--bs-card-border-radius) - 6px);--bs-accordion-border-radius: calc(var(--bs-card-border-radius) - 6px);}
.course-module .accordion .accordion-item .accordion-header, .course-module .accordion .accordion-item .accordion-header .accordion-button, .course-module .accordion .accordion-item .accordion-header .accordion-button:focus{box-shadow: none!important;outline: 0px;}
.course-module .accordion .accordion-item .accordion-header .accordion-button::after{display: none;content: none;}
.course-module .accordion .accordion-item .accordion-header .accordion-button{padding-right: 90px;}
.course-module .accordion .accordion-item .accordion-header .accordion-action{position: absolute;right: 0px;height: 100%;padding: 0px 10px;display: flex;align-items: center;}
.course-module .accordion-body .p-0.card{--bs-card-bg:#F3F6FF!important;--bs-border-color-translucent:#E4E4E7!important}
.course-module .accordion-body .p-0.card .form_group{--fr-body-bg: var(--bs-white)}
.question-item {display: flex;gap: 20px;}
/* .question-item>* {    flex: 1 0;} */
.question-item.left-icon .form_group{width: calc(100% - 70px);}
.question-item.right-icon.left-icon .form_group{width: calc(100% - 140px);}
.text-capitalize .accordion-button, .text-capitalize {text-transform: capitalize;}
.btn.button.btn-outline-primary {
    --bs-btn-border-color: var(--text-primary) !important;
    color: var(--text-primary) !important;
    background-color: var(--bs-btn-bg) !important;
    --bs-btn-disabled-border-color: #fb9981;

}
.btn.button.btn-outline-light{--bs-btn-border-color:#D3DAE2!important;color: #000!important;--btn-hover-b-color: #D3DAE2 !important;}
/* .course-module .accordion .accordion-item .accordion-header */
.table-title {width: 12rem;}
.price-card-wrapper{display: flex;flex-direction: column;align-items: center;}
.pricing-card {background-color: #FFFFFF;border: 1px solid #D9D9D9;border-radius: 10px;padding: 10px 10px 10px 10px;width: 100%;}
.pricing-card-col{padding: 20px 0;}
.pricing-card-top{background-color: #234DCA;margin-bottom: 0;padding: 0;border-top-left-radius: 8px;border-top-right-radius: 8px;padding: 4px 15px;color: #FFFFFF;font-weight: 700;font-size: 18px;display: inline-flex;align-items: center;justify-content: center; width: auto;min-width: 134px;}
.pricing-card .pricing-title {font-weight: 700;font-size: 12px;display: inline-block;}
.pricing-card .price {font-weight: 800;font-size: 22px;color: #234DCA;}
.pricing-card .price-2 {font-weight: 800;font-size: 30px;color: var(--second-primary-1);}
.pricing-card .price-2-strike-through {font-weight: 800;font-size: 19px;color: #262D3D;text-decoration: line-through;text-decoration-thickness: 1px;}
.pricing-card .price-card-hr {border-color: #D9D9D9;opacity: 1;}
.pricing-card .price-card-hr hr {width: 60%;background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #F1F1F1 49.4%, rgba(217, 217, 217, 0) 100.82%);}
.pricing-card .save-credit {font-weight: 800;font-size: 12px;}
.pricing-card .save-credit-details {font-weight: 400;font-size: 12px;}
.pricing-card .pricing-button{--btn-hover-b-color:#234DCA;--bs-btn-border-color:#234DCA;color: #234DCA;}
.price-or-title{font-weight: 800;font-size: 28px;}
.price-credit-box{background-color: #234DCA;padding: 30px 40px;text-align: center;border-radius: 11px;width: 100%;}
.price-credit-box .price-credit-title{color: #FFFFFF;padding: 0;margin: 0;font-weight: 700;font-size: 20px;}
.price-credit-box .price-credit-title-2{color: #FFFFFF;line-height: 1.7;padding: 0;margin: 0;font-weight: 700;font-size: 20px;}
.price-credit-box .price-credit-title-3{color: #FFFFFF;margin-top: 20px !important;padding: 0;margin: 0;font-weight: 700;font-size: 20px;}
.price-credit-box .price-credit-title-4{color: var(--second-primary-1);margin-top: 8px;font-weight: 700;font-size: 20px;}
.price-credit-box .price-credit-input{margin-top: 20px;width: 150px;height: 50px;border-radius: 10px;outline: none;border: none;font-size: 25px;font-weight: 700;text-align: center;}
.price-credit-box .price-credit-button{margin-top: 30px;margin-bottom: 0;--bs-btn-bg:#01CC83 !important;--bs-btn-hover-bg:#01CC83 !important;padding: 25px !important;width: 100%;max-width: 100%;color: #FFFFFF;--bs-btn-font-size:17px !important;}
hr{border-top: 1px solid #a1a6ac;}
.star-rating-content.size-sm .star-rating{font-size:18px;width:auto}
.star-rating-content.size-sm span{padding-right: 5px;font-weight: 600;font-size: 12px;}
.star-rating-content{width: auto;display: inline-flex;align-items: center;}
.star-rating-content span{padding-right: 5px;font-weight: 600;font-size: 16px;}
.star-rating {display:flex;flex-direction: row-reverse;font-size:22px;justify-content:space-around;padding:0 .2em;text-align:center;width:6em;}
.star-rating input {display:none;}
.star-rating label {color:#ccc;cursor:pointer;height: auto;line-height: 1;}
.star-rating input:checked ~ label.checked-true {color:#f90;}
.star-rating label:hover:not(.disable),.star-rating label:hover:not(.disable) ~ label {color:#fc0;}
/* Review Modal */
.common-model{--bs-modal-border-radius: 21px;--bs-modal-color: #262D3D}
.review_model{--bs-modal-width: 621px;--bs-modal-border-radius: 19px;--bs-modal-color: #262D3D}
.review_model .borderless.table td, .review_model .borderless.table th{--bs-border-width: 0px}

.checkmark__circle{stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: var(--text-primary);fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}
.checkmark{ scale:1.3; width: 56px;height: 56px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin: 10% auto;box-shadow: inset 0px 0px 0px var(--text-primary);animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}
.checkmark__check{transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}
@keyframes stroke{100%{stroke-dashoffset: 0}}
@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}@keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px var(--text-primary)}}
.ios-toggle-wrap {position: relative;text-align: center;padding: 1rem;background: rgba(255,255,255,.1)}
.ios-toggle-mega-label-wrap {cursor: pointer; display: inline-block;position: relative;--cp-color-7: #b7bbc8;--cp-color-9: #BEC3CE;--cp-color-1: white;--cp-color-green: #234dca;--switch-size: 20px;--switch-width: 40px;white-space: nowrap}
.ios-toggle-mega-label-wrap.ios-toggle-disabled {opacity: .5;pointer-events: none}
.ios-toggle-mega-label {position: absolute;position: absolute;top: 0;left: 0;width: 100%;height: 100%}
.ios-toggle>input:active+label::before,.ios-toggle>input:checked+label::before {width: 60%;left: 20%;top: 30%;height: 40%;opacity: 0}
.ios-toggle {position: relative;display: inline-block;width: var(--switch-width);height: var(--switch-size);border-radius: 1.75em;background: var(--cp-color-7);vertical-align: middle;margin: 0 .5rem}
.ios-toggle>input {-webkit-appearance: none;-moz-appearance: none;appearance: none;opacity: 0;width: 100%!important;height: 100%;position: absolute!important}
.ios-toggle>input:checked+label {background: var(--cp-color-9)}
.ios-toggle>input:checked+label::after {right: 0;left: calc( 100% - var(--switch-size));box-shadow: 0px 0px 6px 0px #00000040;}
.ios-toggle>input:active+label::after {right: .875em;left: 0}
.ios-toggle>input:active:checked+label::after {right: 0;left: auto}
.ios-toggle>input[disabled][disabled]+label,.ios-toggle>input[disabled][disabled]+label::before {background: var(--cp-color-11)}
.ios-toggle>input[disabled][disabled]+label::after {background: var(--cp-color-6)}
.ios-toggle>input[disabled][disabled]~label {cursor: not-allowed}
.ios-toggle>label:not(.ios-toggle-mega-label) {display: block!important;width: 100%!important;height: 100%;border-radius: 1.75em;-webkit-transition: background .125s;transition: background .125s}
.ios-toggle>label:not(.ios-toggle-mega-label)::before,.ios-toggle>label:not(.ios-toggle-mega-label)::after {position: absolute;content: "";top: 0;left: 0;height: var(--switch-size);width: var(--switch-size);border-radius: inherit;-webkit-transition: all .2s;transition: all .2s}
.ios-toggle>label:not(.ios-toggle-mega-label)::before {background: var(--cp-color-green);width: 100%}
.ios-toggle>label:not(.ios-toggle-mega-label)::after {background: var(--cp-color-1);-webkit-transform: scale(1.1);transform: scale(1.1);right: 1.225em;box-shadow: 0px 0px 6px 0px #00000040;cursor: pointer;}
.ios-toggle.ios-toggle-ambiguous>input:checked+label {background: var(--cp-color-9)}
.ios-toggle.ios-toggle-ambiguous>label::before {background: var(--cp-color-9)}
.ios-toggle.ios-toggle-reverse>input:checked+label {background: var(--cp-color-green)}
.ios-toggle.ios-toggle-reverse>label::before {background: var(--cp-color-9)}
.ios-toggle.ios-toggle-big {-webkit-transform: scale(1.5);transform: scale(1.5)}
.rating{width: auto;display: inline-block;}
.rating.size-{--font-size: 30px}
.rating.size-sm{--font-size: 20px}
.rating .rate-star {position: relative;display: inline-flex;align-items: center;}
.rating span:not(.full, .half, .selected ){padding-right: 10px;font-weight: 600;font-size: 16px;}
.hidden {opacity: 0;}
.rating .star {display: inline-block;margin: 3px 1px;font-size: var(--font-size);color: #ccc;position: relative;line-height: var(--font-size);}
.star.animate {-webkit-animation: stretch-bounce 0.5s ease-in-out;animation: stretch-bounce 0.5s ease-in-out;}
.star.hidden {opacity: 0;}
.full {display: inline-block;position: relative;/* float: right */;z-index: 2;cursor: pointer;user-select: none;}
.half {position: absolute;float: left;z-index: 3;width: 50%;overflow: hidden;cursor: pointer;user-select: none;}
.star-colour {color: #ffd700;}
@-webkit-keyframes stretch-bounce {0% {-webkit-transform: scale(1);}25% {-webkit-transform: scale(1.5);}50% {-webkit-transform: scale(0.9);}75% {-webkit-transform: scale(1.2);}100% {-webkit-transform: scale(1);}}
@keyframes stretch-bounce {0% {-webkit-transform: scale(1);}25% {-webkit-transform: scale(1.5);}50% {-webkit-transform: scale(0.9);}75% {-webkit-transform: scale(1.2);}100% {-webkit-transform: scale(1);}}
.selected:before {display: inline-block;top: 0;left: 0;-webkit-transform: scale(1);opacity: 1;z-index: 1;transform: scale(1);}
.selected.pulse:before {-webkit-transform: scale(3);opacity: 0;transform: scale(3);}
.selected.is-animated:before {transition: 1s ease-out;}
td.free_credit{--bs-table-color:var(--second-primary-1)}
.unstyled-list{list-style: none;padding: 0px;margin-bottom: 0px;}
.org-list li{padding: 7px 10px;display: flex;flex-direction: column;gap: 3px;align-items: center;justify-content: center;cursor: pointer;margin-bottom: 5px;position: relative;}
.org-list li img{width: 30px;height: 30px;user-select: none;-webkit-user-drag: none;}
.org-list li span{font-size: 14px;font-weight: 600;}
/* .org-list li.active::after{content: "";display: block;width: 14px;height: 14px;position: absolute;border-style: dashed;border-width: 8px 8px 0px 0px;rotate: 45deg;left: -10px;border-color: #c393e9;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;} */
.react-datepicker__input-container .react-datepicker__calendar-icon{box-sizing: border-box!important;}
.btn-loader{cursor: default!important;padding-top: 10px;}
.btn-loader .loader {position: relative;display: flex;align-items: center;justify-content: center;}
.btn-loader .loader_dev{display: inline-flex;align-items: center;justify-content: center;padding-right: 25px;position: relative;}
.btn-loader .loader_dev::after{content: "";border: .25em dotted currentcolor;border-radius: 50%;animation: .15s loader-01 linear infinite;height: 18px;width: 18px;display: block;position: absolute;right: 0px;}
@keyframes loader-01 {0% {transform: rotate(0deg);}100% {transform: rotate(360deg);}}
.btn-loader .loader>span{display: flex;align-items: center;justify-content: space-between;width: 100%;opacity: 0;visibility: hidden;}
.fi-1, .fi-1 img{filter: invert(1);}
body .tooltip {--bs-tooltip-zindex: 1080;--bs-tooltip-max-width: 200px;--bs-tooltip-padding-x: 0.5rem;--bs-tooltip-padding-y: 0.25rem;--bs-tooltip-margin: ;--bs-tooltip-font-size: 12px;--bs-tooltip-color: white;--bs-tooltip-bg: var(--bs-emphasis-color);--bs-tooltip-border-radius: var(--bs-border-radius);--bs-tooltip-opacity: 0.9;--bs-tooltip-arrow-width: 0.8rem;--bs-tooltip-arrow-height: 0.4rem;}
body .fade.show.tooltip.bs-tooltip-bottom{position: fixed!important;}
.installment{    display: flex;flex-direction: column;border: 1px solid var(--bs-border-color);border-radius: var(--bs-border-radius);background: #fafafa;}
.installment .installment-body{padding: 5px 10px;}
.m-w-auto{min-width: auto!important;}
.d-grid-view{display: grid;grid-template-columns: auto auto auto auto;}
.d-grid-view .grid-column{padding: 0px 12px 8px;}
.d-grid-view .t-capitalize{text-transform: capitalize;}
.d-grid-view.grid-column-1{display: grid;grid-template-columns: auto;}
.d-grid-view.grid-column-2{display: grid;grid-template-columns: auto auto;}
.d-grid-view.grid-column-3{display: grid;grid-template-columns: auto auto auto;}
.table-bordered tbody tr td{border-bottom: 1px solid var(--bs-table-border-color);;}
.payment-history tr th{font-weight: 600;padding: 10px 8px;background-color: #e4e5ea;}
.payment-history{margin-bottom: 0px;}
.payment-history tr td{padding: 5px 8px;vertical-align: middle;}
.payment-history tr th:first-child{border-top-left-radius: var(--bs-card-border-radius);}
.payment-history tr th:last-child{border-top-right-radius: var(--bs-card-border-radius);}
/* .payment-history tr:last-child td{} */
.payment-history tr td:last-child{border-bottom-right-radius: var(--bs-card-border-radius);}
.payment-history tr td:first-child{border-bottom-left-radius: var(--bs-card-border-radius);}
.hr-line{width: 100%;border-top: 1px solid #e4e5ea;padding-bottom: 10px;margin-top: 5px;}

td.w-20 {
    width: 20%;
}

td.w-20 {
    width: 20%;
}
.d-grid-view.row{display: flex;}

.loader-image-size{
    width: 80px !important;
    height: 80px !important;
}


.custom-marquee {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 10s linear infinite; /* Adjust duration as needed */
}

@keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
}

@media (min-width: 768px) {
    .guest-layout {
        padding-top: 82.3px;
        display: flex;
        position: relative;
    }

    .guest-layout .card {
        overflow: overlay;
        position: absolute;
        width: calc(100% + 30px);
        left: -30px;
    }

    .guest-layout>* {
        width: 50vw;
    }

    .guest-layout .guest-form {
        position: relative;
        height: 100%;
        min-width: calc(50vw) !important;
    }

    .form_group.normal-control .form-control.otp {
        width: 90px !important;
        height: 90px;
    }

    .report-box .box .box-wrapper {
        padding: 2.5rem 2.5rem 2.5rem 8rem;
    }

    .report-box .box .box-wrapper .box-icon {
        left: 2.5rem;
    }

    .report-box .box .collapse .box-padding {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .report-box .box .box-filter>.d-inline-block {
        width: auto;
    }

    .report-box .box .box-filter>.d-inline-block>label,
    .report-box .box .collapse .box-padding .common-dropdown.d-inline-block {
        min-width: auto;
    }
}

@media (min-width: 540px) {
    .guest-layout .banner-divide {
        position: relative;
        min-height: calc(50vh);
    }

    .form_group {
        --fr-label-font-size: var(--fs-16);
        --fr-font-size: var(--fs-14);
    }

    .guest-layout .card .card-body {
        padding: 1.5rem;
    }

    .form_group.lg {
        --fr-line-height: 2;
        --fr-font-size: var(--fs-15);
    }

    .date-dropdown {
        display: flex;
    }

    .date-dropdown .mounth {
        width: calc(100% - 250px);
        padding: 0px 13px;
    }

    .report-box .box .box-wrapper {
        padding: 1.5rem 1.5rem 1.5rem 5rem;
    }

    .report-box .box .box-wrapper .box-icon {
        left: 1.5rem;
    }
}

@media (max-width: 1024px) {
    .card .card-head-wrapper .card-heading-action {
        width: 100%;
        flex-wrap: wrap;
    }

    .card .card-head-wrapper .card-heading-action .button-filter {
        width: 50%;
    }
}

@media (max-width: 768px) {

    .title-1,
    .subtitle-1 {
        --fs-25: 20px
    }

    .title-2,
    .subtitle-2 {
        --fs-24: 21px
    }

    .title-3,
    .subtitle-3 {
        --fs-22: 19px
    }

    .title-4,
    .subtitle-4 {
        --fs-20: 16px
    }

    .title-5,
    .subtitle-5 {
        --fs-18: 14px
    }

    .title-6,
    .subtitle-6 {
        --fs-16: 13px
    }

    .title-7,
    .subtitle-7 {
        --fs-14: 11px
    }

    .title-8,
    .subtitle-8 {
        --fs-12: 10px
    }

    .card.card-report>.card-body,
    .card.report-box>.card-body {
        padding: 1rem;
    }

    .common_icon.xxl,
    .common_icon_image.xxl {
        --height: 35px;
        --width: 35px;
    }

    .common_icon.lg,
    .common_icon_image.lg {
        --height: 25px;
        --width: 25px;
    }

    .common_icon.nm,
    .common_icon_image.nm {
        --height: 15px;
        --width: 15px;
    }

    .common_icon.md,
    .common_icon_image.md {
        --height: 18px;
        --width: 18px;
    }

    .common_icon.sm,
    .common_icon_image.sm {
        --height: 12px;
        --width: 12px;
    }

    .common_icon.sm,
    .common_icon_image.sm-x {
        --height: 10px;
        --width: 10px;
    }

    .common_icon.xsm,
    .common_icon_image.xsm {
        --height: 8px;
        --width: 8px;
    }

    .common_icon.xxsm,
    .common_icon_image.xxsm {
        --height: 6px;
        --width: 6px;
    }

    body .h1,
    body .h2,
    body .h3,
    body .h4,
    body .h5,
    body .h6,
    body h1,
    body h2,
    body h3,
    body h4,
    body h5,
    body h6 {
        margin-bottom: .4rem;
    }

    .content-body>.container-lg {
        --bs-gutter-x: 0rem
    }

    .content-body>.container-lg>.row {
        margin: 0px;
    }

    .content-body .page-header {
        padding-left: 10px;
        padding-right: 10px;
    }

    body {
        --bs-body-font-size: var(--fs-13)
    }

    .header2-wrapper {
        padding-top: 77px;
    }

    .header2-wrapper .container-xl {
        padding: 0px;
        margin: 0px;
    }

    .header2 {
        height: auto;
        padding: 0px !important;
    }

    .header2 .navbar-toggler {
        --bs-navbar-toggler-padding-y: 1rem;
        padding-right: 2.5rem;
        position: relative;
        cursor: pointer;
    }

    .header2 .navbar-toggler i {
        position: absolute;
        right: 15px;
    }

    .header2 .navbar-collapse.show {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0px;
        background: var(--second-primary);
        z-index: 3;
    }

    .header2 .navbar-collapse.show .navbar-nav {
        flex-direction: column;
        max-height: 100% !important;
    }
}

@media (max-width: 768px) {}

.content-outlet {
    padding-left: 300px;
}