.promotion-body .to-name {
    padding: 4px 5px;
    border-bottom: 1px solid #DCDCDC;
    border-top: 1px solid #DCDCDC;
    display: flex;
    background-color: #FAFAFA;
    align-items: center;
    margin-top: 10px;
}

.promotion-body .to-name .title-search {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 10px;
}

.promotion-body .to-name .title {
    font-size: 15px;
    width: 12%;
}

.promotion-body .to-name .title-search .search-user {
    width: 95%;
    border: none !important;
    background-color: #FAFAFA;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.promotion-body .to-name .title-search .search-user .customer-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    padding: 4px;
    background-color: #E0E6F9;
    color: #000000;
    font-weight: 600;
    font-size: 15px;
    font-family: DM Sans;
    /* width: 12%; */

}

.promotion-body .to-name .title-search .search-user .customer-name h6 {
    font-size: 13px !important;

}

.promotion-body .to-name .title-search .search-user .customer-name .cross-btn {
    height: 9px;
    background-color: #000000;
    padding: 3px;
    margin-left: 2px;
    cursor: pointer;
}

.promotion-body .to-name .title-search .to-users .css-13cymwt-control {
    border: none !important;
    background-color: #FAFAFA;

}

.promotion-body .to-name .title-search .to-users .css-t3ipsp-control:hover {
    border: none !important;
    box-shadow: none;
    cursor: pointer;
}

.promotion-body .to-name .title-search .to-users .css-t3ipsp-control {
    border: none !important;
    box-shadow: none;
    cursor: pointer;
    background-color: #FAFAFA;
}

.promotion-body .to-name .title-search .to-users .css-t3ipsp-control .css-3w2yfm-ValueContainer .css-1p3m7a8-multiValue {
    border-radius: 3px;
    padding: 3px;
    background-color: #E0E6F9;
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    font-family: DM Sans;
}

.promotion-body .to-name .title-search .to-users .css-t3ipsp-control .css-1p3m7a8-multiValue .css-12a83d4-MultiValueRemove:hover {
    background-color: #d2daf6;
    color: #000;
}

.promotion-body .to-name .plusbtn {
    height: 8px;
    width: 8px;
    background-color: #F9623E;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.promotion-body .to-name .plusbtn .plusticon {
    background-color: #fff;
}

.promotion-body .mail-input-box {
    padding: 10px;
    border: none;
    width: 100%;
    font-size: 16px;
    outline: none;
}

.promotion-body .prime-react-container {
    padding: 0 20px 4px 20px;
}

.promotion .modal-footer .add-document-btn {
    color: #000 !important;
}

.promotion-body .attachments-text {
    padding: 0px 25px;
    margin-bottom: 10px;
}

.promotion-body .email-file-preview-box {
    display: flex;
    flex-wrap: wrap;
}

.promotion-body .email-file-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px !important;
    background-color: #F2F5F8 !important;
    border-radius: 4px;
    width: 240px;
    height: 35px;

}

.promotion-body .email-file-preview .email-attachment-name-ellipsis {
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.promotion-body .email-file-name {
    display: flex;
    align-items: center;
}

.promotion-body .email-file-name span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.promotion-body .email-delete-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.promotion-body .email-discard-button {
    background-color: #F2F5F8 !important;
}

.promotion-body .email-star-selected-not-selected-icon.button.btn-icon:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}

.promotion .prime-header-icon-box {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    padding: 0 10px;
}

.promotion .prime-header-icon {
    background-color: #f3f3f3;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.promotion .prime-header-icon .icons {
    background-color: #404143;
    fill: #404143;
    /* height: 15px;
    width: 15px; */
}

.promotion .modal-content .prime-header-icon.bold-to-unordered .common_icon {
    /* width: 15px; */
    height: 15px;

}

.promotion-body .prime-react-container {
    margin-top: 15px;
    padding: 0 10px;
}

.promotion-body .prime-react-container .p-editor-toolbar {
    display: none !important;
}

.promotion-body .prime-react-container .card {
    border: none !important;
}

.promotion-body .prime-react-container .card .ql-container {
    border: none !important;
    height: 300px !important;

}

.promotion-body .prime-react-container .card .ql-container .ql-editor {
    padding: 0 5px;
}

/* .promotion-body .prime-react-container .card .ql-container .ql-toolbar.ql-snow + .ql-container.ql-snow{
    height: 200px !important;
} */
.promotion-body .prime-react-container .card .ql-container .ql-editor.ql-blank::before {
    left: 3px !important;
}

.promotion-body .compose-email-subject-error {
    margin: 0 !important;
    padding: 0px 0px 5px 25px !important;
}

.promotion-body .prime-react-container .card .ql-container .ql-editor img {
    width: 30%;
}

/* add customer mdoal */
.customer-modal-dialog {
    padding: 0px !important;
}

.customer-modal-dialog .modal-dialog {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.customer-modal-dialog .modal-dialog.modal-lg {
    --bs-modal-width: 700px;
}

.customer-modal-dialog .modal-content {
    background-color: #ececec;
}

.customer-modal .invitee-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.customer-modal-dialog .customer-modal .invitee-header-title {
    font-weight: 500;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
}

.customer-modal-dialog .customer-modal .invitee-header-title-done {
    font-weight: 500;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    color: var(--text-primary);
    cursor: pointer;
}

.customer-modal-dialog .customer-modal .invitee-header-back-icon.common_icon {
    height: 25px;
    width: 25px;
}

.customer-modal-dialog .customer-modal .invitee-selected-value-box {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.customer-modal-dialog .customer-modal .invitee-selected-value-box .invitee-selected-value-title {
    background-color: var(--text-primary);
    padding: 9px 10px;
    border-radius: 4px;
    color: var(--text-white);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.customer-modal-dialog .customer-modal .invitee-selected-value-box .invitee-selected-value-title .common_icon_image {
    vertical-align: middle;
    height: 12px;
    width: 12px;
    margin-left: 5px;
    cursor: pointer;
}

.customer-modal .form_group.invitee-input-box .input-group {
    background-color: var(--text-white);
    border-radius: 7px;
}

/* .customer-modal .form_group.invitee-input-box  .input-group-wrapper{
    position: relative;
} */

.customer-modal .invitee-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
    padding: 15px 10px;
}

.customer-modal .invitee-data .invitee-add-btn {
    color: var(--text-primary);
    cursor: pointer;
}

.customer-modal .filter-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.customer-modal .filter-dropdown h6 {
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
    font-weight: 500;
}

.customer-modal .filter-dropdown .custom-select__control {
    border-radius: 4px;
}

.customer-modal .filter-dropdown .custom-select__control .input-group-text {
    background-color: white;
    border: none;
}

.promotions-icons.common_icon.bold-icon,
.promotions-icons.common_icon.i-icon,
.promotions-icons.common_icon.u-icon {
    --height: 15px !important;
}

.promotions-file-manger-dropdown {
    --padding: 0 14px !important;
    background-color: #f6f5f4 !important;
    border: 1px solid #e7e7e7 !important;
    border-radius: 6px !important;
    height: 34px !important;
}

.promotions-file-manger-dropdown:hover,
.promotions-file-manger-dropdown:focus {
    --bs-btn-hover-border-color: #e7e7e7 !important;
}

.promotions-file-manger-dropdown i.common_icon.ms-1 {
    display: none !important;
}

.promotions-file-manger-dropdown i.common_icon {
    background-color: #6c7278 !important;
}

/* --t-active-primary */

/* view promotion */
.view-promotion .time{
    color: #F9623E;
}
.view-promotion .promotion-body .to-name {
    padding: 4px 5px;
    border-bottom: 1px solid #DCDCDC;
    border-top: 1px solid #DCDCDC;
    display: flex;
    background-color: #FAFAFA;
    align-items: center;
    margin-top: 10px;
}

.view-promotion .promotion-body .to-name .title-search {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 10px 0;
}

.view-promotion .promotion-body .to-name .title {
    font-size: 15px;
    width: 10%;
}

.view-promotion .promotion-body .to-name .title-search .search-user {
    width: 95%;
    border: none !important;
    background-color: #FAFAFA;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.view-promotion .promotion-body .to-name .title-search .search-user .customer-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    padding: 4px 10px;
    background-color: #E0E6F9;
    color: #000000;
    font-weight: 600;
    font-size: 15px;
    font-family: DM Sans;

}

.view-promotion .promotion-body .to-name .title-search .search-user .customer-name h6 {
    font-size: 13px !important;

}

.view-promotion .promotion-body .attachments-card{
    margin-top: 3rem;

}
.view-promotion .promotion-body .attachments-card .attachments-card-body {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.view-promotion .promotion-body .attachments-card .attachments-card-body .attachment-content-box {
    border: 1px solid #CCCCCC;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-radius: 10px;
    width: 400px;
}

.view-promotion .promotion-body .attachments-card .attachments-card-body .attachment-download-btn {
    cursor: pointer;
}