h1,h2,h3,h4,h5,h6{ margin: 0;}

.prospects-details.tabs{ display: flex; justify-content: flex-start; align-items: center; gap: 10px; }
.tbl-schedule-appointment th{ background-color: #F5F5F5; }
.schedule-appointment .appointment-header{ display: flex; justify-content: space-between; align-items: center; }

  /* New Appointment ====================== */
  .new-appointment-modal-dialog.modal{ padding: 0 !important; }
  .new-appointment-modal-dialog .modal-dialog{ margin: 0; margin-left: auto; margin-right: auto; margin-top: 10px; }
  .new-appointment-modal-dialog .modal-dialog.modal-lg{ --bs-modal-width:700px }
  .new-appointment-modal{ background-color: #ECECEC; border-radius: 10px; font-family: "Poppins", sans-serif; }
  .new-appointment-modal-dialog .modal-content{ background-color: #ececec; }
  .new-appointment-modal h6{ font-weight: 500; font-size: 14px; }
  .new-appointment-modal .header-container{ display: flex; justify-content: space-between; align-items: center; }
    .new-appointment-modal .header-container .header-title {
      font-weight: 500;
      font-size: 18px;
      margin: 0;
      color: #616161;
    }
    .new-appointment-modal .header-container .header-button-save-close { display: flex;}

    .new-appointment-modal .save-appointment-btn {
      padding: 5px 40px !important;
      margin-right: 10px;
    }
    .new-appointment-modal .close-appointment-btn {
      height: 32px !important;
      width: 32px !important;
      cursor: pointer;
    }
  /* .new-appointment-modal  .close-appointment-btn .close-icon{ vertical-align: middle; } */


  /* meeting-search */
  /* .new-appointment-modal .meeting-search-box{margin-top: 15px;}

  .new-appointment-modal .meeting-search-box .form_group{
    width: 100%;
    background-color: white;
    border-radius: 8px;
  }
  .new-appointment-modal .meeting-search-box .form_group .input-group{border-radius: 8px;} */
  /* .new-appointment-modal .meeting-search-box .form_group .input-group input{
    border-color: white;
  } */

  

  /* address */
.new-appointment-modal .company-info-box { background-color: var(--text-white); border-radius: 10px; margin-top: 15px; padding: 10px 10px 0 10px; }
.new-appointment-modal .company-info-box .company-name,
.new-appointment-modal .company-info-box .company-email,
.new-appointment-modal .company-info-box .company-address{ display: flex; align-items: center; }
.new-appointment-modal .company-info-box .company-name .company-icon,
.new-appointment-modal .company-info-box .company-email .company-icon,
.new-appointment-modal .company-info-box .company-address .company-icon{ display: flex; align-items: center; padding: 15px 10px; }
.new-appointment-modal .company-info-box .company-name .company-icon .common_icon_image,
.new-appointment-modal .company-info-box .company-email .company-icon .common_icon_image,
.new-appointment-modal .company-info-box .company-address .company-icon .common_icon_image{ width: 22px; }
.new-appointment-modal .company-info-box .company-name .company-title,
.new-appointment-modal .company-info-box .company-email .company-title{ border-bottom: 1px solid #EAEAEA; padding: 15px 15px; flex-grow: 1; }
.new-appointment-modal .company-info-box .company-address .company-title{ padding: 15px 15px; flex-grow: 1; }

/* user */
.new-appointment-modal .user-info-box{ background-color: var(--text-white); border-radius: 10px; margin-top: 10px; padding: 10px 10px 0 10px; }
.new-appointment-modal .user-info-box .user-name,
.new-appointment-modal .user-info-box .user-email{ display: flex; align-items: center; }
.new-appointment-modal .user-info-box .user-name .user-icon,
.new-appointment-modal .user-info-box .user-email .user-icon{ display: flex; align-items: center; padding: 15px 10px; }
.new-appointment-modal .user-info-box .user-name .user-icon .common_icon_image,
.new-appointment-modal .user-info-box .user-email .user-icon .common_icon_image{ width: 22px; }
.new-appointment-modal .user-info-box .user-name .user-title{ border-bottom: 1px solid #EAEAEA; padding: 15px 15px; flex-grow: 1; }
.new-appointment-modal .user-info-box .user-email .user-title{ padding: 15px 15px; flex-grow: 1; }

/* date-time */
.new-appointment-modal .date-time-info-box{ background-color: var(--text-white); border-radius: 10px; margin-top: 10px; padding: 10px 10px 10px 10px; }
.new-appointment-modal .date-time-info-box .date-time-first,
.new-appointment-modal .date-time-info-box .date-time-second{ display: flex; align-items: center; }
.new-appointment-modal .date-time-info-box .date-time-first .date-time-icon,
.new-appointment-modal .date-time-info-box .date-time-second .date-time-icon{ display: flex; align-items: center; padding: 12px 10px; }
.new-appointment-modal .date-time-info-box .date-time-first .date-time-icon .common_icon_image,
.new-appointment-modal .date-time-info-box .date-time-second .date-time-icon .common_icon_image{ width: 22px; }
.new-appointment-modal .date-time-info-box .date-time-first .date-time-start-box .title,
.new-appointment-modal .date-time-info-box .date-time-second .date-time-end-box .title{ width: 45px; }

/* date time start date */
.new-appointment-modal .date-time-info-box .date-time-first .date-time-start-box{ display: flex; justify-content: flex-start; align-items: center; gap: 10px; padding: 0 15px; flex-grow: 1; }
/* start-date */
.new-appointment-modal .date-time-info-box .date-time-first .date-time-start-box .custom-date-box{ display: flex; justify-content: center; border-radius: 4px; align-items: center; padding: 5px 5px 5px 10px; height: 100%; background-color: #EEEEEE; }
.new-appointment-modal .date-time-info-box .date-time-first .date-time-start-box .custom-date-box .react-datepicker__input-container input{ min-width: 100px; max-width: 100px; border: none; outline: none!important; background-color: #EEEEEE; }
/* start-time */
.new-appointment-modal .date-time-info-box .date-time-first .date-time-start-box .custom-time-box{ display: flex; justify-content: center; border-radius: 4px; align-items: center; padding: 5px 5px 5px 10px; height: 100%; background-color: #EEEEEE; }
.new-appointment-modal .date-time-info-box .date-time-first .date-time-start-box .custom-time-box .react-datepicker__input-container input{ min-width: 67px; max-width: 67px; border: none; outline: none!important; background-color: #EEEEEE; }
/* date time end date */
.new-appointment-modal .date-time-info-box .date-time-second .date-time-end-box{ display: flex; justify-content: flex-start; align-items: center; gap: 10px; padding: 0 15px; flex-grow: 1; }
/* end-date */
.new-appointment-modal .date-time-info-box .date-time-second .date-time-end-box .custom-date-box{ display: flex; justify-content: center; border-radius: 4px; align-items: center; padding: 5px 5px 5px 10px; height: 100%; background-color: #EEEEEE; }
.new-appointment-modal .date-time-info-box .date-time-second .date-time-end-box .custom-date-box .react-datepicker__input-container input{ min-width: 100px; max-width: 100px; border: none; outline: none!important; background-color: #EEEEEE; }
/* endtime */
.new-appointment-modal .date-time-info-box .date-time-second .date-time-end-box .custom-time-box{ display: flex; justify-content: center; border-radius: 4px; align-items: center; padding: 5px 5px 5px 10px; height: 100%; background-color: #EEEEEE; }
.new-appointment-modal .date-time-info-box .date-time-second .date-time-end-box .custom-time-box .react-datepicker__input-container input{ min-width: 67px; max-width: 67px; border: none; outline: none!important; background-color: #EEEEEE; }

/* customizing star-end date calender */
.new-appointment-modal .date-time-info-box .custom-date-box .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.new-appointment-modal .date-time-info-box .custom-date-box .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after { border: none; }
.new-appointment-modal .date-time-info-box .custom-date-box .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, 
.new-appointment-modal .date-time-info-box .custom-date-box .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after { border: none; }
.new-appointment-modal-dialog .custom-date-box .react-datepicker{ font-family:var(--bs-font-sans-serif); border: 1px solid #EEEEEE; font-weight: 500; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); }
.new-appointment-modal-dialog .custom-date-box .react-datepicker .react-datepicker__header{ background-color: var(--text-white); border: 1px solid #EEEEEE; }
.new-appointment-modal-dialog .custom-date-box .react-datepicker .react-datepicker__day--selected{ background-color: var(--text-primary); border-radius: 50%; }
.new-appointment-modal-dialog .custom-date-box .react-datepicker .react-datepicker__day--keyboard-selected{ background-color: var(--text-secondary); border-radius: 50%; }
.new-appointment-modal-dialog .custom-date-box .react-datepicker .react-datepicker__month-container{ width: 300px; }
.new-appointment-modal-dialog .custom-date-box .react-datepicker .react-datepicker__day{ margin: 7px; }
.new-appointment-modal-dialog .custom-date-box .react-datepicker .react-datepicker__day-name{ margin: 7px; }


/* customizing start-end time */
.new-appointment-modal-dialog .custom-time-box .react-datepicker{ font-family:var(--bs-font-sans-serif); border: 1px solid #EEEEEE; font-weight: 500; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); }
.new-appointment-modal-dialog .custom-time-box .end-time,
.new-appointment-modal-dialog .custom-time-box .start-time{ border: none; background-color: #EEEEEE; padding: 1px 5px; outline: none; }
.new-appointment-modal-dialog .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{ background-color:var(--text-primary) !important; }
.new-appointment-modal-dialog  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{ background-color: var(--text-secondary); }
.new-appointment-modal-dialog  .react-datepicker__header{ background-color: var(--text-white) !important; border-bottom-color: var(--b-color); }

.new-appointment-modal-dialog .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar{ width: 5px; background-color: var(--text-secondary); }
.new-appointment-modal-dialog .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-thumb{ background-color: var(--text-primary); border-radius: 5px; }
.new-appointment-modal .date-time-info-box .custom-time-box .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.new-appointment-modal .date-time-info-box .custom-time-box .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{ border: none; }
.new-appointment-modal .date-time-info-box .custom-time-box .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.new-appointment-modal .date-time-info-box .custom-time-box .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{ border: none; }


/* isAll Check */
.new-appointment-modal .date-time-info-box .form_group .form-check-input{ background-color: #DFDCDC; border-color: #DFDCDC; }
.new-appointment-modal .date-time-info-box .form-switch .form-check-input{ --bs-form-switch-bg: url(../../public/assets/img/WhiteCircle.svg); background-image: var(--bs-form-switch-bg); }
.new-appointment-modal .date-time-info-box .form_group .form-check-input:focus{ background-color: #DFDCDC; border-color: #DFDCDC; }
.new-appointment-modal .date-time-info-box .form_group .form-check-input:checked{ background-color: var(--text-primary); border-color: var(--text-primary); }


/* travel time */
.new-appointment-modal .custom-travel-time-dropdown-toggle{ border: none; display: flex !important; justify-content: space-between !important; align-items: center !important; padding: 1px 8px !important; background-color: #FFDFD5  !important; border-color: #FFDFD5; color: var(--text-primary) !important; } 
.new-appointment-modal .date-time-custom-dropdown .dropdown-menu{ --bs-dropdown-link-active-color: var(--text-color); }
.new-appointment-modal .date-time-custom-dropdown .custom-travel-time-dropdown-toggle .common_icon_image{
  --height:22px;
  width: fit-content;
  margin-left: 8px;
}
.new-appointment-modal .date-time-custom-dropdown .custom-travel-time-dropdown-toggle .picture-opacity-1{ height: 12px; width: 12px; }
.new-appointment-modal .date-time-custom-dropdown .dropdown-menu.show{ padding: 10px 10px 5px 12px; border-radius: 10px; }
.new-appointment-modal .date-time-custom-dropdown .dropdown-menu.show .dropdown-item .check_menu .common_icon_image .picture-opacity-1 { height: 12px; width: 12px; }
.new-appointment-modal .date-time-custom-dropdown .dropdown-menu.show .dropdown-item .check_menu .common_icon_image{ height: 12px; width: 12px; }
.new-appointment-modal .date-time-custom-dropdown  .dropdown-menu.show .dropdown-item { border-bottom: 1px solid #E7E7E7; padding: 4px 8px 4px 0px; }
.new-appointment-modal .date-time-custom-dropdown  .dropdown-menu.show .dropdown-item:last-child { border-bottom: none; }
.new-appointment-modal .date-time-custom-dropdown  .dropdown-menu.show .dropdown-item .check_menu { display: flex; /* align-items: center; */ justify-content: flex-start; gap: 8px; }
.new-appointment-modal .date-time-custom-dropdown .dropdown-menu.show .dropdown-item .check_menu span{ font-size: 13px; font-weight: 500; }
.new-appointment-modal .date-time-custom-dropdown .dropdown-menu.show .dropdown-item:hover{ background-color: var(--text-secondary); }


/* invitees */
.new-appointment-modal .invitees-info-box{ background-color: var(--text-white); border-radius: 10px; margin-top: 10px; padding: 5px 10px 5px 10px; }
.new-appointment-modal .invitees-info-box .invitees-flex-box{ display: flex; align-items: center; justify-content: space-between; }
.new-appointment-modal .invitees-info-box .invitees-flex-box .invitees-name{ display: flex; align-items: center; }
.new-appointment-modal .invitees-info-box .invitees-flex-box .invitees-name .invitees-icon{ display: flex; align-items: center; padding: 15px 10px; }
.new-appointment-modal .invitees-info-box .invitees-flex-box .invitees-name .invitees-icon .common_icon_image{ width: 22px; }
.new-appointment-modal .invitees-info-box .invitees-flex-box .invitees-name .invitees-title{ padding: 15px 15px; }
.new-appointment-modal .invitees-info-box .invitees-flex-box .invitees-count-box{ padding: 0 20px; display: flex; align-items: center; cursor: pointer; }
.new-appointment-modal .invitees-info-box .invitees-flex-box .invitees-count-box .common_icon{ height: 15px; width: 15px; }

/* invitees list */
.new-appointment-modal .invitees-info-box .invitees-list-box{ display: flex; align-items: center; padding: 0 10px; }
.new-appointment-modal .invitees-info-box .invitees-list-box .invitees-name .invitees-icon{ background: linear-gradient(144.83deg, #FF937A 20.4%, #FF6E4B 84.78%); width: 22px; height: 22px; display: flex; justify-content: center; align-items: center; font-size: 10px; font-weight: 600; color: var(--text-white); border-radius: 50%; }
.new-appointment-modal .invitees-info-box .invitees-list-box .invitees-list-i-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 12px 8px 12px 25px;
  border-top: 1px solid #EAEAEA;
}
.new-appointment-modal .invitees-info-box .invitees-list-box .invitees-list-i-box .invitees-info-btn{ cursor: pointer; }

/* invitee modal */
.invitee-modal-dialog  {
 padding: 0px !important;
}
.invitee-modal-dialog .modal-dialog{ margin: 0; margin-left: auto; margin-right: auto; margin-top: 50px; }
.invitee-modal-dialog .modal-dialog.modal-lg{ --bs-modal-width:700px }
.invitee-modal-dialog .modal-content{ background-color: #ececec; }
.invitee-modal .invitee-header{ display: flex; justify-content: space-between; align-items: center; padding: 10px 0; }
.invitee-modal-dialog .invitee-modal .invitee-header-title{ font-weight: 500; font-size: 18px; font-family: "Poppins", sans-serif; }
.invitee-modal-dialog .invitee-modal .invitee-header-title-done{ font-weight: 500; font-size: 15px; font-family: "Poppins", sans-serif; color: var(--text-primary); cursor: pointer; }
.invitee-modal-dialog .invitee-modal .invitee-header-back-icon.common_icon{ height: 25px; width: 25px; }
.invitee-modal-dialog .invitee-modal .invitee-selected-value-box{ display: flex; flex-wrap: wrap; gap: 8px; }   
.invitee-modal-dialog .invitee-modal .invitee-selected-value-box .invitee-selected-value-title { background-color: var(--text-primary); padding: 9px 10px; border-radius: 4px; color: var(--text-white); font-weight: 500; font-size: 14px; display: flex; justify-content: center; /* align-items: center; */ }
.invitee-modal-dialog .invitee-modal .invitee-selected-value-box .invitee-selected-value-title .common_icon_image { vertical-align: middle; height: 12px; width: 12px; margin-left: 5px; cursor: pointer; }
.invitee-modal .form_group.invitee-input-box  .form-control{ background-color: var(--text-white); }  
.invitee-modal .invitee-data{ display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid #D8D8D8; padding: 15px 10px; }
.invitee-modal .invitee-data .invitee-add-btn{ color: #F9623E !important; cursor: pointer; }

/* invitee info modal */
.invitee-info-modal-dialog.modal{ padding: 0 !important; }
.invitee-info-modal-dialog .modal-dialog{ margin: 0; margin-left: auto; margin-right: auto; margin-top: 50px; }
.invitee-info-modal-dialog .modal-dialog.modal-lg{ --bs-modal-width:700px }
.invitee-info-modal-dialog .modal-content{ background-color: #ececec; }
.invitee-info-modal-dialog .invitee-info-modal.modal-body{ padding: 16px 0 ; }
.invitee-info-modal-dialog .invitee-info-modal .invitee-header{ display: flex; justify-content: space-between; align-items: center; padding: 10px 15px; }
.invitee-info-modal-dialog .invitee-info-modal .invitee-header-title{ font-weight: 500; font-size: 18px; font-family: "Poppins", sans-serif; }
.invitee-info-modal-dialog .invitee-info-modal .invitee-header-title-done{ font-weight: 500; font-size: 15px; font-family: "Poppins", sans-serif; color: var(--text-primary); cursor: pointer; }
.invitee-info-modal-dialog .invitee-info-modal .invitee-header-back-icon.common_icon{ height: 25px; width: 25px; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar{ margin-top: 20px; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar{ width: 700px; background-color: #ececec; font-family:"Poppins", sans-serif; border:none; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar .react-calendar__navigation{ background-color: #E2E2E2 ; font-weight: 500; font-size: 17px; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__next2-button{ display: none; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__next-button{ font-size: 40px !important; font-weight: 200 !important; color: var(--text-primary); padding: 0px 30px; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays abbr[title]{ text-decoration: none; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays abbr[title]{ font-weight: 500; font-size: 15px; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile{ font-weight: 400; font-size: 16px; padding: 15px 15px; color: #333333; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:disabled{ background-color: #ececec; }
/* .invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:enabled:hover, 
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:enabled:focus{
  background-color: var(--text-secondary);
} */
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth:disabled{ color: #cdcdcd; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth:disabled{ color:#cdcdcd; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now{ background: none; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now abbr{color: var(--text-primary); padding: 10px 15px; border-radius: 50%; }
.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day--weekend{ color: #333333; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot{ display: flex; justify-content: space-between; align-items: center; padding: 10px 25px; background-color: #E2E2E2; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot .slot-names{ display: flex; justify-content: space-between; align-items: center; gap: 8px; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot h6{ font-weight: 500; font-size: 18px; color: #333333; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot .slot-names .slot-names-content{ display: flex; justify-content: center; align-items: center; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot .slot-names .slot-names-content .available-slot{ width: 8px; height: 8px; border-radius: 50%; background-color: var(--text-primary); }
.invitee-info-modal-dialog .invitee-info-modal .time-slot .slot-names .slot-names-content .booked-slot{ width: 8px; height: 8px; border-radius: 50%; background-color: #929292; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot .slot-names .slot-names-content .other-slot{ width: 8px; height: 8px; border-radius: 50%; background-color: #1565FF; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot .slot-names .slot-names-content h6{ font-weight: 500; font-size: 11px; margin-left: 5px; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot-box{ margin-top: 15px; display: flex; flex-wrap: wrap; padding: 0 20px; gap: 10px; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot-box .time-slot-list-available { border: 1px solid var(--text-primary); padding: 10px 20px; background-color: #FFF5F3; border-radius: 5px; width: 32%; color: #333333; }
.invitee-info-modal-dialog .invitee-info-modal .time-slot-box .time-slot-list-booked { border: 1px solid #CDCDCD; padding: 10px 20px; background-color: #F8F8F8; color: #333333; border-radius: 5px; width: 32%; }




/* repeat */
.new-appointment-modal .repeat-info-box{ background-color: var(--text-white); border-radius: 10px; margin-top: 10px; padding: 5px 10px 5px 10px; }
.new-appointment-modal .repeat-info-box .repeat-flex-box{ display: flex; align-items: center; justify-content: space-between; }
.new-appointment-modal .repeat-info-box .repeat-name{ display: flex; align-items: center; }
.new-appointment-modal .repeat-info-box .repeat-name .repeat-icon{ display: flex; align-items: center; padding: 15px 10px; }
.new-appointment-modal .repeat-info-box .repeat-name .repeat-icon .common_icon_image{ width: 22px; }
.new-appointment-modal .repeat-info-box .repeat-name .repeat-title{ padding: 15px 15px; }
.new-appointment-modal .repeat-info-box .repeat-search-modal-container{ padding: 0 20px; display: flex; align-items: center; }
.new-appointment-modal .repeat-info-box .repeat-search-modal-container .common_icon{ height: 15px; width: 15px; }
/* repeat-dropdown */
.new-appointment-modal .repeat-dropdown-modal-container{ padding: 0 10px; }
.new-appointment-modal .repeat-dropdown-modal-container .custom-repeat-dropdown-toggle{ border: none; display: flex !important; justify-content: space-between !important; align-items: center !important; padding: 1px 8px !important; color: var(--text-color); } 
.new-appointment-modal .repeat-dropdown-modal-container .dropdown-menu{ --bs-dropdown-link-active-color: var(--text-color); }
.new-appointment-modal .repeat-dropdown-modal-container .custom-repeat-dropdown-toggle .repeat-arrow-icon{ height: 15px; width: 15px; margin-left: 5px; }
.new-appointment-modal .repeat-dropdown-modal-container .custom-repeat-dropdown-toggle .picture-opacity-1{ height: 12px; width: 12px; }
.new-appointment-modal .repeat-dropdown-modal-container .dropdown-menu.show{ padding: 10px 10px 5px 12px; border-radius: 10px; }
.new-appointment-modal .repeat-dropdown-modal-container .dropdown-menu.show .dropdown-item .check_menu .common_icon_image .picture-opacity-1 { height: 12px; width: 12px; }
.new-appointment-modal .repeat-dropdown-modal-container .dropdown-menu.show .dropdown-item .check_menu .common_icon_image{ height: 12px; width: 12px; }
.new-appointment-modal .repeat-dropdown-modal-container  .dropdown-menu.show .dropdown-item { border-bottom: 1px solid #E7E7E7; padding: 4px 8px 4px 0px; }
.new-appointment-modal .repeat-dropdown-modal-container  .dropdown-menu.show .dropdown-item:last-child { border-bottom: none; }
.new-appointment-modal .repeat-dropdown-modal-container  .dropdown-menu.show .dropdown-item .check_menu { display: flex; /* align-items: center; */ justify-content: flex-start; gap: 8px; }
.new-appointment-modal .repeat-dropdown-modal-container .dropdown-menu.show .dropdown-item .check_menu span{ font-size: 13px; font-weight: 500; }
.new-appointment-modal .repeat-dropdown-modal-container .dropdown-menu.show .dropdown-item:hover{ background-color: var(--text-secondary); }
/* end repeat */
.new-appointment-modal .repeat-hr-box{ display: flex; justify-content: center; align-items: center; }
.new-appointment-modal .repeat-hr-box hr{ border: none; border-bottom: 1px solid #EAEAEA; width: 90%; margin: 0; opacity: 1; }
.repeat.date-time-info-box{
  padding: 0 !important;
  margin: 0;
}
.repeat-date-time-div-box{
  display: flex;
  gap: 10px;
  margin-left: auto;
}

/* calender date dots */
.dot {
  height: 4px;
  width: 4px;
  background-color: var(--text-primary);
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 4px;

}
.white-dot {
  height: 4px;
  width: 4px;
  background-color: var(--text-white) !important;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  margin-top: 2px;
}
.booked-dot{
  height: 4px;
  width: 4px;
  background-color: #929292;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 4px;
}
.start-date-tile abbr{
  background-color: var(--text-primary) !important;
  color: var(--text-white) !important;
  padding: 8px 8px;
  border-radius: 50%;
}
.start-date-tile.react-calendar__tile--active{
  background-color: transparent !important;
}
.start-date-tile.react-calendar__tile--active:hover{
  background-color: transparent !important;
}

.invitee-info-modal-dialog .invitee-info-modal .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth:disabled .dot{
  background-color: transparent;
}

/* alert ==========================*/
.new-appointment-modal .alert-info-box{ background-color: var(--text-white); border-radius: 10px; margin-top: 10px; padding: 5px 10px 5px 10px; }
.new-appointment-modal .alert-info-box .alert-flex-box{ display: flex; align-items: center; justify-content: space-between; }
.new-appointment-modal .alert-info-box .alert-name{ display: flex; align-items: center; }
.new-appointment-modal .alert-info-box .alert-name .alert-icon{ display: flex; align-items: center; padding: 15px 10px; }
.new-appointment-modal .alert-info-box .alert-name .alert-icon .common_icon_image{ width: 22px; }
.new-appointment-modal .alert-info-box .alert-name .alert-title{ padding: 15px 15px; }
.new-appointment-modal .alert-info-box .alert-search-modal-container{ padding: 0 20px; display: flex; align-items: center; }
.new-appointment-modal .alert-info-box .alert-search-modal-container .common_icon{ height: 15px; width: 15px; }

/* alert-dropdown */
.new-appointment-modal .alert-dropdown-modal-container{ padding: 0 10px; }
.new-appointment-modal .alert-dropdown-modal-container .custom-alert-dropdown-toggle{ border: none; display: flex !important; justify-content: space-between !important; align-items: center !important; padding: 1px 8px !important; color: var(--text-color); } 
.new-appointment-modal .alert-dropdown-modal-container .dropdown-menu{ --bs-dropdown-link-active-color: var(--text-color); }
.new-appointment-modal .alert-dropdown-modal-container .custom-alert-dropdown-toggle .alert-arrow-icon{ height: 15px; width: 15px; margin-left: 5px; }
.new-appointment-modal .alert-dropdown-modal-container .custom-alert-dropdown-toggle .picture-opacity-1{ height: 12px; width: 12px; }
.new-appointment-modal .alert-dropdown-modal-container .dropdown-menu.show{ padding: 10px 10px 5px 12px; border-radius: 10px; }
.new-appointment-modal .alert-dropdown-modal-container .dropdown-menu.show .dropdown-item .check_menu .common_icon_image .picture-opacity-1 { height: 12px; width: 12px; }
.new-appointment-modal .alert-dropdown-modal-container .dropdown-menu.show .dropdown-item .check_menu .common_icon_image{ height: 12px; width: 12px; }
.new-appointment-modal .alert-dropdown-modal-container  .dropdown-menu.show .dropdown-item { border-bottom: 1px solid #E7E7E7; padding: 4px 8px 4px 0px; }
.new-appointment-modal .alert-dropdown-modal-container  .dropdown-menu.show .dropdown-item:last-child { border-bottom: none; }
.new-appointment-modal .alert-dropdown-modal-container  .dropdown-menu.show .dropdown-item .check_menu { display: flex; /* align-items: center; */ justify-content: flex-start; gap: 8px; }
.new-appointment-modal .alert-dropdown-modal-container .dropdown-menu.show .dropdown-item .check_menu span{ font-size: 13px; font-weight: 500; }
.new-appointment-modal .alert-dropdown-modal-container .dropdown-menu.show .dropdown-item:hover{ background-color: var(--text-secondary); }
/* end alert */
.new-appointment-modal .alert-hr-box{ display: flex; justify-content: center; align-items: center; }
.new-appointment-modal .alert-hr-box hr{ border: none; border-bottom: 1px solid #EAEAEA; width: 90%; margin: 0; opacity: 1; }




/* view appointment modal================================================================ */

.view-appointment-modal-dialog.modal{ padding: 0 !important; }
.view-appointment-modal-dialog .modal-dialog .modal-content{ border-radius: 0; }
.view-appointment-modal-dialog .modal-dialog { margin: 0; margin-left: auto; }
.view-appointment-modal-dialog .modal-dialog.modal-lg{ --bs-modal-width:700px }

/* header */
.view-appointment-modal-dialog .modal-header {
  background-color: #f3f3f3;
  padding: 16px 20px;
}
.view-appointment-modal-dialog .modal-header .header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  flex-grow: 1;
}
.view-appointment-modal-dialog .modal-header .header .header-icons-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.view-appointment-modal-dialog .modal-header .header .header-icons-box .common_icon{
  height: 22px;
  width: 22px;
}
.view-appointment-modal-dialog .modal-header .header .header-icons-box .common_icon_image{
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.view-appointment-modal-dialog .modal-header .header h6{
  color: var(--text-primary);
  font-weight: 700;
  font-size: 20px;
}

.view-appointment-modal-dialog .view-appointment-modal{padding: 0 16px 16px 16px;}


/* title font common for all */
.view-appointment-modal  .title-font{
  font-weight: 500;
  font-size: 12px;
  font-family: "poppins";
}
.view-appointment-modal  .title-font-2{
  font-weight: 500;
  font-size: 13px;
  font-family: "poppins";
  display: flex;
  align-items: center;
  color: var(--text-color2);
}

/* title */
.view-appointment-modal .title-box{margin-top: 10px;}
 
.view-appointment-modal .title-content-box{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  padding: 15px 15px;
  margin-top: 10px;
  gap: 10px;
}

/* stuatus */
.view-appointment-modal .status-box{ margin-top: 10px; } 

.view-appointment-modal .status-info-box{
  border: 1px solid #C2C2C2;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 10px;
  padding: 15px 15px;
}
.view-appointment-modal .status-content-box{
  display: flex;
  gap: 10px;
}
.view-appointment-modal .status-box .dropdown{display: flex;}
.view-appointment-modal .status-box .status-dropdown-toggle{
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 !important;
}
.view-appointment-modal .status-hr{
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-appointment-modal .status-hr hr{
  border: none;
  border-bottom: 1px solid #D3D2D2;
  width: 98%;
  opacity: 1;
}
.view-appointment-modal .status-box .status-dropdown-toggle.btn:focus, 
.view-appointment-modal .status-box .status-dropdown-toggle.btn:hover{
 --btn-hover-b-color: transparent !important;
}
.view-appointment-modal .status-box .status-dropdown-toggle.btn:first-child:active{background-color: var(--text-white);}
 .view-appointment-modal .status-box .status-info-box .status-note-content-box{
  display: flex;
  gap: 10px;
 }
.view-appointment-modal .status-box .dropdown-menu.show{
  position: absolute;
  left: 48% !important;
  border-radius: 10px;
  min-width: 345px;
}
.view-appointment-modal .status-box .dropdown-menu.show .dropdown-item {padding: 10px 15px 10px 25px !important;}
.view-appointment-modal .status-box .dropdown-menu.show .dropdown-item .check_menu{
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.view-appointment-modal .status-box .dropdown-menu.show .dropdown-item .check_menu .common_icon_image {
  width: 18px;
  height: 18px;
 }
.view-appointment-modal .status-info-box .status-dropdown .btn.show, 
.view-appointment-modal .status-info-box .status-dropdown .btn:focus{
  background-color: var(--text-white) !important;
  border-color: var(--text-white) !important;
}

 /* status-edit */
 .view-appointment-modal .status-edit-box{ margin-top: 10px;}

 .view-appointment-modal .status-edit-box .status-edit{
  border: 1px solid #C2C2C2 ;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
 }
 .view-appointment-modal .status-edit-box .status-save-cancel{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
 }

.view-appointment-modal .status-edit-box .status-save-cancel .cancel-btn{
  background-color: #A9A9A9;
  color: var(--text-white);
  padding: 0 35px !important;
}
.view-appointment-modal .status-edit-box .status-save-cancel .save-btn{padding: 0 35px !important;}

/* status-cancelled */
.view-appointment-modal .status-edit-box .status-edit .form_group.status-textarea .form-control:focus{
  --fr-border-color: #C2C2C2;
  box-shadow:none;
}


/* Today-page */
.today-page-container{
  display: flex;
  /* justify-content: ; */
  margin-bottom: 15px;
}

/* schedule */
.view-appointment-modal .schedule-box{ margin-top: 10px;}

.view-appointment-modal .schedule-flex-box{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  margin-top: 10px;
  gap: 20px;
  padding: 15px 15px;
}
.view-appointment-modal .schedule-content-box{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}



/* clock */
.view-appointment-modal .travel-box{ margin-top: 10px;}

.view-appointment-modal .travel-content-box{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  padding: 15px 15px;
  margin-top: 10px;
  gap: 10px;
}

/* company info */
.view-appointment-modal .company-info-box{ margin-top: 15px;}

.view-appointment-modal .company-info-main-container{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  margin-top: 10px;
  padding: 15px 15px;
}
.view-appointment-modal .company-info-content-flex{
  display: flex;
  gap: 20PX;
}
.view-appointment-modal .company-info-content-box{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.view-appointment-modal .company-info-hr{
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-appointment-modal .company-info-hr hr{
  border: none;
  border-bottom: 1px solid #D3D2D2;
  width: 98%;
  opacity: 1;
}
.view-appointment-modal .company-info-address-flex{
  display: flex;
  gap: 10px;
}
.view-appointment-modal .company-info-direction-flex{
  display: flex;
  gap: 10px;
  margin-left: 25px;
  margin-top: 10px;
}
.view-appointment-modal .company-direction-title{
  color: #1685F4;
  text-decoration: underline;
  cursor: pointer;
}

/* contact-person-info */
.view-appointment-modal .contact-person-info-box{margin-top: 15px;}

.view-appointment-modal .contact-person-info-content-flex{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  padding: 15px 15px;
  margin-top: 10px;
  gap: 10px;
}
.view-appointment-modal .contact-person-info-content-box{
  display: flex;
  align-items: center;
  gap: 10px;
}


/* invitees */

.view-appointment-modal .invitees-info-box{margin-top: 10px;}

.view-appointment-modal .invitees-info-content-flex{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  padding: 15px 15px;
  margin-top: 10px;
  gap: 30px;
}
.view-appointment-modal .invitees-info-content-box{
  display: flex;
  gap: 10px;
}

/* alert */
.view-appointment-modal .alert-info-box{margin-top: 10px;}

.view-appointment-modal .alert-info-content-flex{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  padding: 15px 15px;
  margin-top: 10px;
  gap: 30px;
}
.view-appointment-modal .alert-info-content-box{
  display: flex;
  gap: 10px;
}

/* attachments */
.view-appointment-modal .attachments-info-box{ margin-top: 10px;}

.view-appointment-modal .attachments-info-content-flex{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  padding: 15px 15px;
  margin-top: 10px;
  gap: 30px;
}
.view-appointment-modal .attachments-info-content-box{
  display: flex;
  gap: 10px;
}

/* url & note */
.view-appointment-modal .url-note-info-box{margin-top: 15px;}

.view-appointment-modal .url-note-info-main-container{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #C2C2C2;
  border-radius: 8px;
  margin-top: 10px;
  padding: 15px 15px;
}
.view-appointment-modal .url-note-info-hr{
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-appointment-modal .url-note-info-hr hr{
  border: none;
  border-bottom: 1px solid #D3D2D2;
  width: 100%;
  opacity: 1;
}
.view-appointment-modal .url-note-title-url{color: var(--text-primary);}


/* Attachments- email ================================= */
.new-appointment-modal .attachments-editor-box {
  background-color: var(--text-white);
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
}
.new-appointment-modal .attachments-editor-box .prime-react-container .p-editor-toolbar {display: none;}

.new-appointment-modal .attachments-editor-box .prime-header-icon-box{
  display: flex !important;
  gap: 10px !important;
}

.new-appointment-modal .attachments-editor-box .email-file-preview{
  display: flex;
  width: 185px;
  background-color: #ececec;
  padding: 5px 5px;
  border-radius: 5px;
}
.new-appointment-modal .attachments-editor-box .email-file-preview .email-file-name{ display: flex;}

.new-appointment-modal .attachments-editor-box .email-file-preview .email-file-name .email-attachment-name-ellipsis{
  display: flex;
  white-space: nowrap; 
  width: 110px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.new-appointment-modal .attachments-editor-box .prime-react-container .card{ border: none !important;}

.new-appointment-modal .attachments-editor-box .prime-react-container .card .p-editor-content {border: none !important;}

.new-appointment-modal .attachments-editor-box .prime-react-container .p-editor-content {height: 70px !important;}

.new-appointment-modal .attachments-editor-box .prime-header-icon-box{
  background-color: #ececec !important;
  border-radius: 8px;
  padding: 0 10px;
}
.new-appointment-modal .attachments-editor-box .prime-header-icon-box .prime-header-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  background-color: #ececec;
}
.new-appointment-modal .attachments-editor-box .prime-header-icon-box .prime-header-icon.bold-to-unordered .common_icon{ height: 12px;}

.new-appointment-modal .attachments-editor-box .prime-header-icon-box .prime-header-icon .common_icon{height: 16px;}

/* add-edit-customer */
.form_group .input-group .right_text .common_icon_image{
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.form_group .input-group .right_text{position: relative;}

.form_group .input-group .right_text.disabled{background-color: #F5F5F5;}

.form_group .input-group .right_text::before {
  content: "";
  position: absolute;
  top: 25%; /* Start the border 25% down from the top */
  left: 0;
  width: 0; /* No width, as it's a border */
  height: 50%; /* 50% of the div's height */
  border-left: 1px solid #E7E7E7; /* Adjust the border thickness and color as needed */
}

/* document */
.document-table .document-type{
  text-transform: uppercase;
}
.document-modal-doc-file-download-btn-box{
  text-align: center;
  margin-top: 50px;
  margin-bottom: 80px;
}
.document-modal-doc-file-download-btn{
  background: var(--text-primary);
  color: var(--text-white);
  padding: 15px 50px;
  border-radius: 5px;
}