
/* Side bar */
.sidebar-panel {
    --side-org-panel: 160px;
    height: 100vh;
    left: 0px;
    top: 0;
    padding-bottom: 50px;
    position: fixed;
    width: var(--width-sidebar);
    display: flex;
}
.org-main-sidebar {
    height: 100vh;
    padding-bottom: 50px;
    width: var(--side-org-panel);
    z-index: 999;
    overflow: hidden;
    overflow-y: hidden;
    transition: 0s;
    background: white;
    /* box-shadow: 0 12px 30px rgba(80, 143, 244, .1); */
    color: var(--text-white);
    ---text-color: var(--text-white);
    --border-color: var(--transparent-primary);
    /* padding-top: 40px; */
}
.main-sidebar {
    height: 100vh;
    position: relative;
    top: 0;
    padding-bottom: 50px;
    width: calc(var(--width-sidebar) - var(--side-org-panel));
    z-index: 999;
    overflow: hidden;
    overflow-y: auto;
    transition: 0s;
    background: var(--white-color);
    color: var(--text-white);
    ---text-color: var(--text-white);
    --border-color: #e8def0;
    --text-white: var(--text-sidebar);
}
.main-sidebar .logo {
    padding: 30px 25px 4px 15px;
}
.main-sidebar .logo a {position: relative;display: flex;align-items: center;width: 100%;}
a.links{color: var(--t-primary);}
.main-sidebar .logo a .picture-opacity-1 {max-width: 34px;min-width: 39px;min-height: 36px;}
.logo .logo_title>*,.ellipse {display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.ellipse-2{overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.ellipse-3{overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;}
.ellipse-1{overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;}
.ellipsis2 {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 310px !important;
  }
.main-sidebar .logo .logo_title h6 {margin: 0px;line-height: 1.2;}
.metis_menu {
    max-height: calc(100vh - 143px);
    overflow: hidden;
    height: 100%;
}
.metis_menu .list-group {--bs-body-bg: transparent;--bs-border-color: transparent;---text-color: var(--text-white);--bs-border-radius: 0px;--bs-body-color: var(---text-color);--bs-list-group-color: var(--bs-body-color);--bs-list-group-bg: var(--bs-body-bg);--bs-list-group-border-color: var(--bs-border-color);--bs-list-group-border-width: var(--bs-border-width);--bs-list-group-border-radius: var(--bs-border-radius);--bs-list-group-item-padding-x: 1.25rem;--bs-list-group-item-padding-y: 0.75rem;--bs-list-group-action-color: var(--bs-body-color);--bs-list-group-action-hover-color: var(--bs-body-color);--bs-list-group-action-hover-bg: var(--bs-body-bg);--bs-list-group-action-active-color: var(--bs-body-color);--bs-list-group-action-active-bg: var(--bs-secondary-bg);--bs-list-group-disabled-color: var(--bs-secondary-color);--bs-list-group-disabled-bg: var(--bs-body-bg);--bs-list-group-active-color: var(--text-white);--bs-list-group-active-bg: var(--check-primary);--bs-list-group-active-border-color: var(--check-primary);}
.metis_menu .link-dropdown ul {
    padding-left: 35px;
    margin-bottom: 0px;
}
.metis_menu .link-dropdown ul li{display: flex;position: relative;align-items: center;}
.metis_menu .link-dropdown ul li a::after {
    content: "";
    height: 6px;
    width: 6px;
    background-color: var(--text-color);
    position: absolute;
    left: -15px;
    top:13px;
    border-radius: 100%;
    
}
.metis_menu .link-dropdown ul{
    /* color: var(--tab-color); */
    padding: 0px 33px 10px 32px;
}
.metis_menu .link-dropdown ul li a {
    /* color: var(--tab-color); */
   padding: 6px;
    width: 100%;
}
/* .metis_menu .link-dropdown:hover {--tab-color: white} */
/* .metis_menu .list-group a:hover {
    --bs-list-group-bg: var(--transparent-primary);
    --bs-body-bg: var(--transparent-primary) !important;
    background-color: var(--bs-list-group-bg);
} */
.metis_menu .list-group .list-group-item-action,
.metis_menu .list-group .active .list-group-item,
.metis_menu .list-group>a:hover .list-group-item,
.metis_menu .list-group .list-group-item-action:hover {
    --bs-list-group-bg: #FFF4F1;
    background-color: var(--bs-list-group-bg);
    color: #F9623E;
    border-color: #F9623E;
}
/* .metis_menu .list-group .list-group-item{padding-left: 40px;} */
/* .metis_menu .list-group-item .common_icon_image:first-child{position: absolute;left: 15px;} */
.metis_menu .list-group-item .common_icon_image:last-child{position: absolute;right: 15px;}
/* .metis_menu .list-group .list-group-item-action::before, .metis_menu .list-group .active .list-group-item::before {content: "";width: 3px;height: calc(100% - 6px);background-color: var(--t-primary);border-radius: 0px 40px 40px 0px;position: absolute;left: 0px;} */
img.size-l{height: 60px;width: 60px;object-fit: cover;object-position: center;}
img.size-sm{height: 40px;width: 40px;object-fit: cover;object-position: center;}
img.size-md{height: 50px;width: 50px;object-fit: cover;object-position: center;}


.list-group{
    padding: 0 10px;
}
.list-group .list-group-item{
    border: 1px solid #D0D0D0;
    padding: 10px 10px;
    border-radius: 8px;
    margin-bottom: 15px;
    background-color: #F4F4F4;
    gap: 10px;
    position: relative;
}

.list-images.list-group-item{
    border: 0px solid black;
    padding: 0px ;
    border-radius: 8px;
    /* margin-bottom: 10px; */
    background-color: white !important;
}

.list-group .active .list-group-item .common_icon {
    background-color: #F9623E;
}
.list-group .list-group-item:hover .common_icon{
    background-color: #F9623E;
}

.arrow-down-icon{
    position: absolute !important;
    right: 11px;
}

.sidebar-site-title{
    font-family:  "Figtree", sans-serif;
    color: #616161;
    font-size: 22px;
    font-weight: 300;
}
.sidebar-site-title .text-orange{
    color: #F15D02;
}
.sidebar-site-sub-title{
    font-family:  "Figtree", sans-serif;
    color: #F15D02;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 5px;
}
.main-sidebar .logo_title{
    /* margin-bottom: 10px; */
    padding: 10px 0px;
    text-align: center;
}

.org-main-sidebar .metis_menu{
    max-height: calc(100vh - 78px);
}

.org-main-sidebar{
    border-right: 1px solid #D9D9D9;
}

.main-sidebar{
    border-right: 1px solid #D9D9D9;
}

.main-sidebar .side-bar-title{
    font-family:  "Figtree", sans-serif;
    color: #616161;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}
.list-images .card{
   border: none;
}
.list-images .card-img-overlay .img-text-size{
    font-size: 11px;
    font-weight: 700;
} 
.list-images .card-img-overlay{
    position: absolute;
    border-radius: 10px;
    padding: 0;
    /* padding: var(--bs-card-img-overlay-padding); */
}
.img-text-size{
    position: absolute;
    bottom: 5px;
    left: 7px;
}
.org-main-sidebar .list-image-group .active .list-images .card-img-overlay{
    border: 2px solid #F9623E;
    background-color: rgb(249, 98, 62, 0.4);
}
.org-main-sidebar .logo_title{
    padding: 10px 0px;
    text-align: center;
}

.main-sidebar .list-group .link-dropdown{
    margin-bottom: 10px;
}
.main-sidebar .list-group .link-dropdown ul {
    border: 1px solid red;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: none;
}
.main-sidebar .list-group .link-dropdown .list-group-item{
    margin-bottom: 0px;
}


.main-sidebar .list-group .link-dropdown.active .list-group-item{
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.main-sidebar .list-group .link-dropdown.active ul{
   background-color: var(--text-secondary);
}

.main-sidebar .list-group .link-dropdown.active ul li .active{
    color: var(--text-primary);
}
.main-sidebar .list-group .link-dropdown.active ul li .active::after{
    background-color: var(--text-primary);
}


/* header============== */
.header-content-box{ width: 100%; display: flex; justify-content: space-between; align-items: center; }
.header-content-box .header-left-box{ display: flex; justify-content: center; align-items: center; }
.header-content-box .header-left-box .header-date{ font-size: 20px; font-weight: 500; color: #000; margin: 0 15px; }
.header-content-box .header-left-box .header-data-items{ display: flex; justify-content: center; align-items: center; }
.header-content-box .header-left-box .header-calendar-icon, .header-content-box .header-right-box .header-gear-icon { width: 30px; height: 30px; }
.header-content-box .header-left-box .header-appointment-button{ background-color: var(--text-primary) !important; color: white !important; }
.header-content-box .header-right-box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-content-box .header-right-box .header-user-short-name {
    background-color: var(--text-secondary) !important;
    padding: 8px 10px;
    color: var(--text-primary);
    margin: 0 10px;
    border: 1px solid var(--text-primary);
    border-radius: 8px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.header-content-box .header-right-box .dropdown-items{
    color: var(--text-color);
}

.header-content-box .common-dropdown.dropdown .dropdown-items{
    padding: 10px 0 !important;
    background: white;
    color: var(--text-color) !important;
    border: none;
}