
.form-control:disabled{
    background-color: #F5F5F5 !important;
}
.css-t3ipsp-control:hover{
    border-color: var(--text-primary) !important;
}
.custom-select__control--is-disabled {
    background-color: #F5F5F5 !important;
}

.card-body-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-body-header .card-body-header-title{
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}
.card-body-header .card-body-filters{
    display: flex;

    justify-content: flex-end;
    align-items: center;
    flex-basis: auto;
    flex-grow: 3;
    gap: 15px;
}
.card-body-header-hr{
    border: none;
    border-bottom: 1px solid #E7E7E7;
    margin: 12px 0;
    opacity: 1;
}
.table-select {
    width: 40px;
    max-width: 40px;
    text-align: center
}

.form-control:focus{
    box-shadow: none;
}
.create-offer .card .status-dropdown-toggle:disabled{
    background-color: #f5f5f5;
    color: #6c7278 !important;
    opacity: 1;
}

.form_group .input-group span.disable  {
    background-color: #f5f5f5 !important;
    color: #6c7278 !important;  
}