/* login page css start here */
.loginWrapper {
  float: left;
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logoheader {
  color: #34b739;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin: 0 0 14px;
  font-size: 39px;
  text-align: center;
}

.logoheader .logoheader-img {
  width: 250px;
  /* height: 300px; */
  margin-bottom: 30px;
}

.loginMainblog {
  float: left;
  width: 400px;
  padding: 25px;
  background-color: #d5d5d5;
}

.loginMainblog h4 {
  float: left;
  width: 100%;
  font-size: 20px;
  margin: 0 0 15px;
}

.mainloginwrps {
  float: left;
  width: 100%;
  height: 100%;
}

.formloginset .form-label {
  float: left;
  width: 100%;
  font-size: 14px;
}

.formloginset input {
  border-radius: 0px;
  height: 38px;
  font-size: 13px !important;
  box-shadow: none !important;
}

.widthfullbtn {
  width: 100%;
}

.formloginset select {
  width: 100%;
  border-radius: 0px;
  height: 38px;
  font-size: 13px;
  padding: 10px 10px;
  border-color: #ced4da;
  outline: none;
  box-shadow: none !important;
  background-image: url("../assets/img/caret-down.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 96% center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

small.note {
  font-size: 13px;
  margin: 5px 0 0;
  color: #7a7a7a;
}

.formloginset .btn {
  background-color: var(--text-primary) !important;
  border-color: var(--text-primary) !important;
  border-radius: 0px;
  padding: 8px 10px;
  /* margin: 5px 0 0; */
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 9;
}

body .btn.button-1 {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  border-radius: 0px;
  padding: 8px 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 9;
  color: white !important;
}

body .btn.button-1.btn-sm {
  font-size: 14px !important;
  border-radius: 0px !important;
  padding: 6.6px 10px !important;
}

body .btn.button-1:focus {
  background-color: #7f878f !important;
}

.signInbtn::after {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  content: "";
  background-color: red;
  border-color: var(--text-primary);
  transform: translateY(100%);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.signInbtn:hover::after {
  transform: translateY(0%);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.passwordfiled {
  float: left;
  width: 100%;
  position: relative;
}

#searchbtn {
  margin: 0px;
}

.passwordfiled input {
  padding-right: 35px;
}

.passwordfiled .showpassbtn {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 9px;
  top: 8px;
  cursor: pointer;
}

.passwordfiled .showpassbtn svg {
  float: left;
  width: 100%;
}

.danger-colorset {
  font-size: 12px;
  color: #F84646;
  float: left;
  width: 100%;
  margin: 4px 0 0;
}

/* login page css end here */


/* common css */
.dashTitle {
  font-weight: 500;
  font-size: 13px !important;
  color: #616161;
}

.cmnData {
  font-weight: 600;
  color: #555555;
  font-size: 16px;
}

.dashSub {
  font-weight: 500;
  font-size: 10px;
  color: #787878;
}

.dashSub2 {
  font-weight: 500;
  font-size: 11px;
  color: #4CAF50;
}

.dashSub3 {
  font-weight: 500;
  font-size: 11px;
  color: #FF4545;
}

.customHeaderColor {
  color: #F9623E;
  font-weight: 600;
  font-size: 19px;
}

/* discard-changes================= */

.discard-modal .modal-dialog {
  width: 320px;
}

.discard-modal .modal-content {
  border-radius: 20px;
}

.discard-modal .modal-body {
  padding: 20px;
}

.discard-modal .main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.discard-modal .discard-icon {
  width: 50px;
}

.discard-modal .discard-icon h6 {
  font-weight: 700;
  font-size: 25px;
  color: #555555;
}

.discard-modal .main-content .title {
  font-weight: 700;
  font-size: 20px;
  color: #555555;
  margin: 25px 0;
  text-align: center;
}

.discard-modal .discard-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.discard-modal .discard-btn-container .no-btn,
.discard-modal .discard-btn-container .yes-btn {
  width: 100%;
}

/* =========message-modal========== */
.message-modal .modal-dialog {
  width: 400px;
}

.message-modal .modal-content {
  border-radius: 20px;
}

.message-modal .modal-body {
  padding: 20px;
}

.message-modal .main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.message-modal .main-content .title {
  font-weight: 700;
  font-size: 20px;
  color: #555555;
  margin: 5px 0;
  text-align: center;
}

.message-modal .main-content .subtitle {
  font-weight: 500;
  font-size: 16px;
  color: #555555;
  margin: 10px;
  padding: 10px 0;
}

.message-modal .discard-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.message-modal .discard-btn-container .no-btn,
.message-modal .discard-btn-container .yes-btn {
  width: 100%;
}

/* user-message-changes================= */

.user-message-modal .modal-dialog {
  width: 350px;
}

.user-message-modal .modal-content {
  border-radius: 20px;
}

.user-message-modal .modal-body {
  padding: 20px;
}

.user-message-modal .main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.user-message-modal .discard-icon {
  width: 50px;
}

.user-message-modal .discard-icon h6 {
  font-weight: 700;
  font-size: 25px;
  color: #555555;
}

.user-message-modal .main-content .title {
  font-weight: 700;
  font-size: 20px;
  color: #555555;
  margin: 25px 0;
  text-align: center;
}

.user-message-modal .discard-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.user-message-modal .discard-btn-container .no-btn,
.user-message-modal .discard-btn-container .yes-btn {
  width: 100%;
}



/* bullet style */
.bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  font-size: 11px;
}

.bullet.orange {
  background-color: #F9623E;
}

.bullet.green {
  background-color: #6AD968;
}

.bullet.blue {
  background-color: #5F51FF;
}

.bullet.pink {
  background-color: #FF61E6;
}

.bullet.yellow {
  background-color: #FFB800;
}

/* ellipsis */
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.items .closeIcon {
  background-color: #FF3434;
  padding: 4px 5px;
  border-radius: 6px;
  cursor: pointer;
}

.items .closeIcon .close {
  background-color: #fff;
  color: #555555 !important;
}

.items .title {
  color: #F9623E;
  font-size: 20px;
  font-weight: 700;
}

.textgrp {
  border: 1px solid #DCDCDC !important;
  background-color: #F5F5F5 !important;
  border-radius: 9px !important;
}

.text-end {
  --bs-border-radius: 9px !important;
}

/* add-remark */
.add-remark-modal {
  padding: 25px;
}

.add-remark-modal .header {
  display: flex;
  justify-content: space-between;
}

.add-remark-modal .header .title {
  font-weight: 700;
  font-size: 20px;
  color: var(--text-primary);
}

.add-remark-modal .add-remark-text-box {
  margin-top: 15px;
}

/* view remark */
.view-remark .modal-dialog {
  --bs-modal-width: 560px;
}

.view-remark .modal-body {
  padding: 0 !important;
}

.view-remark .custom-modal-body .header {
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-remark .custom-modal-body .header {
  font-weight: 600;
  color: #555555;
  align-items: center !important;
  font-size: 16px;
}

.view-remark .header .title {
  font-weight: 700;
  font-size: 20px;
  color: var(--text-primary);
}

.view-remark .header .header-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.view-remark .header .header-icons i {
  cursor: pointer;
}

.view-remark .header .header-icons .common_icon_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-remark .custom-modal-body .remark {
  color: #555555;
  font-size: 16px;
  font-weight: 600;
  background-color: #F5F5F5;
  border: 1px solid #E7E7E7;
  margin-top: 0;
  padding: 10px 0;
}

.view-remark .custom-modal-body .remark span {
  padding: 20px 25px;
}

.view-remark .remark-box {
  padding: 20px 25px;
  margin-bottom: 20px;
}

.view-remark .remark-box .title {
  font-weight: 600;
  font-size: 19px;
  color: var(--text-primary);
}

.view-remark .remark-box h6 {
  font-weight: 600;
  font-size: 18px;
  color: #555555;
}



/* view email design */
.email-view-dialog .modal-dialog.modal-lg {
  --bs-modal-width: 900px;
}

.email-header.modal-header {
  display: block !important;
  padding: 16px 25px;
}

.email-header .email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.email-header .emailtitle {
  font-size: 18px;
  font-weight: 700;
  color: #262D3D;
}

.email-header .email .mail {
  font-size: 14px;
  color: #9CA1A6;
  font-weight: 400;
}

.email-header .email .time {
  font-size: 16px;
  color: #9CA1A6;
  font-weight: 400;
}

.email-header .email .profile {
  border-radius: 50%;
  background-color: #FFD2BE;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
}

.email-header .email .profile h6 {
  color: #F9623E;
  font-size: 23px;
  font-weight: 600;
}

.emailview.modal-body {
  padding: 16px 5px;
}

.emailview .emailtitle {
  font-size: 18px;
  font-weight: 700;
  color: #262D3D;
}

.emailview .subject p {
  font-size: 16px;
  font-weight: 400;
  color: #9CA1A6;
}

.email-view-dialog .attachments-card {
  margin-bottom: 15px;
}

.email-view-dialog .attachments-card .attachments-card-body {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.email-view-dialog .attachments-card .attachments-card-body .attachment-content-box {
  border: 1px solid #CCCCCC;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border-radius: 10px;
  width: 400px;
}

.email-view-dialog .attachments-card .attachments-card-body .attachment-download-btn {
  cursor: pointer;
}

.email-view-dialog .view-email-loader-box {
  display: flex;
  align-items: center;
}

.email-view-dialog .view-email-loader-image-size {
  width: 25px !important;
  height: 25px !important;
  margin-left: 8px;
}

/* .email-view-dialog  .email-view-content img{
  width: 150px;
} */

/* call-view-modal =========================================*/
.call-view-modal .modal-body {
  padding: 0 !important;
}

.call-view-modal .modal-content {
  border-radius: 15px;
}

.call-view-modal .modal-dialog {
  --bs-modal-width: 600px;
}

.call-view-modal .custom-modal-body .header {
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
}

.call-view-modal .custom-modal-body .header .title {
  font-weight: 700;
  font-size: 20px;
  color: var(--text-primary);
}

.call-view-modal .custom-modal-body .header p {
  font-weight: 600;
  color: #555555;
  align-items: center !important;
  font-size: 16px;
}

.call-view-modal .custom-modal-body .header .close-icon-box {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;
}

.call-view-modal .custom-modal-body .header .close-icon-box .common_icon_image {
  height: 22px;
  width: 22px;
}

.call-view-modal .custom-modal-body .remark {
  color: #555555;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
  background-color: #F5F5F5;
  border: 1px solid #E7E7E7;
  margin-top: 0;
  padding: 10px 0;
}

.call-view-modal .custom-modal-body .remark span {
  padding: 20px 25px;
}

.call-view-modal .modal-footer {
  padding: 0 !important;
  margin: 0 !important;
  justify-content: start !important;
  background-color: #F5F5F5;
  border-top: 1px solid #E7E7E7;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.call-view-modal .user-info {
  font-family: "Inter";
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 20px 0px;
}

.call-view-modal .remark-box {
  font-family: "Inter";
  padding: 20px 0px;
}

.call-view-modal .user-info .company,
.call-view-modal .user-info .username {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.call-view-modal .user-info .company .common_icon_image,
.call-view-modal .user-info .username .common_icon_image {
  height: 25px;
  width: 25px;
}

.call-view-modal .data .close {
  width: 30px !important;
  height: 30px !important;
}

.call-view-modal .footerData {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  width: 100%;
}

.call-view-modal .footerData .footerData-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 5px 10px;
  width: 100%;
  border-right: 1px solid #E2E2E2;
}

.call-view-modal .footerData .footerData-content:last-child {
  border-right: 0;
}

.call-view-modal .footerData .footerData-content .common_icon_image {
  height: 30px;
  width: 30px;
}

.call-view-modal .footerData .footerData-content .mobile-number {
  text-decoration: underline;
  font-weight: 500;
  font-size: 18px;
}

.call-view-modal .user-info-remark-box {
  padding: 0 25px;
}

/* call schedule modal ============================================================== */

.callSchedule .modal-body {
  padding: 10px 15px;
}

.callSchedule .modal-grid {
  display: grid;
  grid-template-columns: 0fr 1fr;
  /* gap: 20px; */
}


.callSchedule .calendar-section .custom-calendar .react-calendar {
  font-family: "Inter", sans-serif;
  border: none;
}

.callSchedule .calendar-section .custom-calendar .react-calendar .react-calendar__navigation {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 15px;
  border-bottom: 1px solid #E8E8E8;
  height: 55px !important;
}

.callSchedule .calendar-section .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label__labelText {
  font-weight: 700;
  font-size: 20px;
}

.callSchedule .calendar-section .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.callSchedule .calendar-section .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.callSchedule .calendar-section .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.callSchedule .calendar-section .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.callSchedule .calendar-section .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.callSchedule .calendar-section .custom-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 24px !important;
  font-weight: 400 !important;
  padding: 0px 10px;
  color: var(--text-color);
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer {
  margin-bottom: 10px;
  padding: 10px 0;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays abbr[title] {
  text-decoration: none;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays abbr[title] {
  font-weight: 500;
  font-size: 15px;
  color: #666666;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile {
  font-weight: 400;
  font-size: 16px;
  padding: 15px 7px;
  color: #666666;
  background-color: #FFFFFF;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:enabled:hover,
.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:enabled:focus {
  background-color: var(--text-white) !important;
  color: var(--text-primary) !important;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth:disabled {
  color: #cdcdcd;
  background: none;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth:disabled {
  color: #cdcdcd;
  background: none;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now {
  background: none;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now {
  background: var(--text-secondary);
  color: var(--text-primary);
  padding: 10px 10px;
  border-radius: 50%;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day--weekend {
  color: #666666;
}

.callSchedule .modal-grid .calendar-section .custom-calendar .react-calendar__month-view__days .react-calendar__tile.previously-selected-date abbr {
  background: var(--text-secondary);
  color: var(--text-primary) !important;
  padding: 10px 10px;
  border-radius: 50%;
}

.callSchedule .modal-grid .calendar-section .custom-calendar .react-calendar__month-view__days .react-calendar__tile.highlighted-date abbr {
  background: var(--text-secondary);
  color: var(--text-primary) !important;
  padding: 10px 10px;
  border-radius: 50%;
}

.callSchedule .modal-grid .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now {
  background-color: transparent;
  color: #666666;
}

.callSchedule .calendar-section .custom-calendar .custom-calendar-hr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.callSchedule .calendar-section .custom-calendar .custom-calendar-hr hr {
  border: 0;
  border-bottom: 1px solid #E8E8E8;
  width: 95%;
  margin: 0;
  opacity: 1;
}

.callSchedule .form-section .call-schedule-close-btn {
  padding: 14.6px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.callSchedule .form-section .call-schedule-close-btn .common_icon_image {
  height: 25px;
  width: 25px;
}

.callSchedule .form-section .call-schedule-close-btn .close-icon {
  cursor: pointer;
}

.callSchedule .form-section .form-section-box {
  border-left: 1px solid #E8E8E8;
  border-top: 1px solid #E8E8E8;
  padding: 15px 20px;
}

.callSchedule .form-section .info {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.callSchedule .form-section .info .close {
  width: 25px;
  height: 25px;
}

.callSchedule .form-section .info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.callSchedule .form-section .info .close {
  width: 30px;
  height: 30px;
}

.callSchedule .form-section h6 {
  font-size: 17px;
  font-weight: 500;
  color: #333333;
}

.callSchedule .form-section .companyinfo {
  display: flex;
  padding: 12px 12px;
  border-radius: 5px;
  background-color: #EEEEEE;
  border: none;
  gap: 20px;
}

.callSchedule .form-section .companyinfo .phone-number {
  color: #777777;
}

.callSchedule .form-section .add-remark .add-remark-select {
  background-color: #EEEEEE;
}

.callSchedule .add-remark .input-group .custom-select__option.css-10wo9uf-option {
  color: #333333;
  /* font-weight: 500; */
}

.callSchedule .calendar-section .callSchedule-time-box {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* padding: 10px; */
  gap: 10px;
  padding: 10px 10px;
}

.callSchedule .calendar-section .callSchedule-time-box .hour {
  width: 100px;
}

.callSchedule .calendar-section .callSchedule-time-box .minute {
  width: 100px;
}

.callSchedule .calendar-section .callSchedule-time-box .form_group.normal-control.hour .form-control {
  height: 45px !important;
  background-color: #EEEEEE;
  color: #333333;
  font-weight: 500;
  font-size: 25px;
  text-align: center !important;
}

.callSchedule .calendar-section .callSchedule-time-box .form_group.normal-control.minute .form-control {
  height: 45px !important;
  background-color: #EEEEEE;
  color: #333333;
  font-weight: 500;
  font-size: 25px;
  text-align: center !important;
}

.callSchedule .calendar-section .custom-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:disabled {
  color: #ababab !important;
  pointer-events: none;
}


.callSchedule .calendar-section .callSchedule-time-box .form_group.normal-control.minute .form-control:focus,
.callSchedule .calendar-section .callSchedule-time-box .form_group.normal-control.hour .form-control:focus {
  --fr-border-color: transparent !important;
}

.callSchedule .calendar-section .callSchedule-time-box .colon {
  font-weight: 700;
  font-size: 30px;
}

.callSchedule .calendar-section .callSchedule-time-box .am-pm {
  /* padding: 0 15px; */
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
  background-color: #EEEEEE;
}

.callSchedule .calendar-section .callSchedule-time-box .am {
  padding: 0 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid transparent;
}

.callSchedule .calendar-section .callSchedule-time-box .pm {
  padding: 0 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid transparent;
}

.callSchedule .calendar-section .callSchedule-time-box .am-pm .am.active,
.callSchedule .calendar-section .callSchedule-time-box .am-pm .pm.active {
  border-color: var(--text-primary) !important;
  background-color: var(--text-secondary);
  color: var(--text-primary);
}


/* call-schedules-successful-modal ================ */
.call-scheduled-successfully .modal-content {
  border-radius: 10px;
}

.call-scheduled-successfully .main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.call-scheduled-successfully .modal-dialog {
  width: 400px;
}

.call-scheduled-successfully .success-icon {
  width: 100px;
  height: 100px;
  margin-top: 30px;
}

.call-scheduled-successfully .success-msg-content {
  text-align: center;
  margin: 30px 0 40px 0;
}

.call-scheduled-successfully .success-msg-content h6 {
  font-weight: 700;
  font-size: 20px;
}

.call-scheduled-successfully .date-time-box {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid #E1E1E1;
  background-color: #F6F6F6;
  border-radius: 10px;
  margin-bottom: 30px;
}

.call-scheduled-successfully .date-time-box .date-content,
.call-scheduled-successfully .date-time-box .time-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 20px 20px
}

.call-scheduled-successfully .date-time-box .date-content .title,
.call-scheduled-successfully .date-time-box .time-content .title {
  color: #A8A8A8;
}

.call-scheduled-successfully .date-time-box .date-content .subtitle,
.call-scheduled-successfully .date-time-box .time-content .subtitle {
  font-weight: 600;
  color: #6C7278;
}

/* ring-call-modal */
.callmodal .modal-content {
  border-radius: 20px;
  width: 450px;
}

.callmodal .card {
  border-radius: 20px;
}

.callmodal .modal-body {
  padding: 0;
  --bs-modal-border-radius: 40px !important;
}

.callmodal .background-container {
  background-image: url('/public/assets/image/RingCallBg.png');
  background-size: cover;
  background-position: center;
  /* height: 562px;  */
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 3rem;
}

.callmodal .caller h6 {
  font-size: 30px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 15px;
}

.callmodal .caller p {
  font-size: 23px;
  font-weight: 500;
  color: #9CA1A6;
  margin-bottom: 3rem;
}

.callmodal .call-btn {
  background-color: #FE4747;
  font-size: 17px;
  color: white;
  border-radius: 8px;
  width: 90%;
  margin-bottom: 20px;
}

.callmodal .card-overly-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  margin-top: 22%;
}

.callmodal .card-img-overlay .user-name {
  font-weight: 700;
  font-size: 28px;
  color: #333333;
  margin-bottom: 10px;
}

.callmodal .card-img-overlay .ring-call {
  font-weight: 500;
  font-size: 23px;
  color: #9CA1A6;
  margin-bottom: 50px;
}

/* share documnet*/
.sharedoc .close-appointment-btn {
  /* width: 25px;  */
  cursor: pointer;
}

.sharedoc .modal-header {
  padding: 0 !important;
  width: 100% !important;
  display: block;
  border-bottom: none !important;
}

.sharedoc .docheader .searchable {
  padding: 15px 30px;
}

.sharedoc .docheader .searchable .docsearch {
  border: none !important;
  background-color: #F5F5F5 !important;
  /* padding: 10px 0 !important; */
  --fr-padding: 9px !important;
  --fr-border-color: none !important;
  border-radius: 5px;
}

.sharedoc .docheader .searchable input .left-label .input-group-text {
  border: none;
  background-color: #F5F5F5;
  padding: 10px 0 !important;
}

.sharedoc .docheader .searchable .custom-select .custom-select__control {
  border: 1px solid #F9623E;
  background-color: #FFE1DA;
  color: #4F4F4F;
}

.sharedoc .modal-body {
  padding: 0 !important;
}

.sharedoc .bodyheader {
  padding: 8px 30px;
  background-color: #F2F2F2;
  border: 1px solid #E7E7E7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sharedoc .bodyheader h6 {
  font-size: 18px;
}

.sharedoc .docheader .header {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: 15px 30px;
}

.sharedoc .docheader .header .title {
  font-size: 20px;
  color: #F9623E;
  font-weight: 700;
}

.sharedoc .docheader .header .subheader {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}

.sharedoc .modal-dialog.modal-lg {
  --bs-modal-width: 1000px !important;
}

.custom-table-three {
  padding: 20px 30px;
}

.custom-table-three .scrollbar-padding {
  padding: 0 30px 0 10px;
}

.custom-table-three .card {
  overflow: hidden;
  border-radius: 11px;
  border: 1px solid #EBEBEB;
}


.custom-table-three .table-with-radius {
  vertical-align: middle;
}

.custom-table-three th {
  background-color: #EBEBEB;
  color: #6C7278;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 30px;
  align-items: center;
}

.custom-table-three th:nth-last-child(1),
.custom-table-three td:nth-last-child(1) {
  padding: 10px 60px;
  text-align: end;
}

.custom-table-three td {
  color: #6C7278;
  font-size: 14px;
  font-weight: 500;
  /* padding: 10px 0px 30px 30px; */
  padding: 10px 30px;
}

.custom-table-three .custom-checkbox {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #979797;
  border-radius: 3px;
  appearance: none;
  cursor: pointer;
  margin: -5px 5px;
}

.custom-checkbox:checked {
  background-color: #F9623E;
  border-color: #F9623E;
}

.custom-checkbox:checked::after {
  content: '✔';
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-table-three .card-header {
  background-color: var(--text-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-table-three .table-th-bg {
  border-bottom: 1px solid var(--border-primary);
}

/* viewDoc */
.viewDoc .modal-body {
  padding: 0;
}

.viewDoc .close-appointment-btn {
  height: 33px;
  width: 33px;
  cursor: pointer;
}

.viewDoc .save-appointment-btn {
  padding: 8px 38px !important;
  margin-right: 10px;
}

.viewDoc .header {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: 12px 0;
}

.viewDoc .header h6 {
  padding: 0 30px;
}

.viewDoc .subheader {
  padding: 0 20px;
}

.viewDoc .header .title {
  font-size: 20px;
  color: #1A202B;
  font-weight: 700;
}

.viewDoc .header .subheader {
  display: flex;
  align-items: center;
}

.viewDoc .header .subheader .btn {
  --padding: 6px 20px 6px 20px !important;
}

/* compose-mail==================================== */
.compose-mail-dialog .compose-mail-header {
  background-color: #f3f3f3;
  color: #000;
}

.compose-mail-body {
  padding: 0;
}

.compose-mail-body .from-name {
  padding: 20px 25px;
  border-bottom: 1px solid #DCDCDC;
}

.compose-mail-body .to-name {
  padding: 4px 25px;
  border-bottom: 1px solid #DCDCDC;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.compose-mail-body .to-name .title-search {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.compose-mail-body .to-name .title {
  font-size: 16px;
  width: 15px;
}

.compose-mail-body .title .user-name {
  font-weight: 900;
  margin-left: 10px;
  color: #262D3D;
}

.compose-mail-body .to-name .title-search .to-users {
  width: 100%;
  margin-left: 30px;
  border: none !important;
}

.compose-mail-body .to-name .title-search .to-users .css-13cymwt-control {
  border: none !important;
  cursor: pointer;
}

.compose-mail-body .to-name .title-search .to-users .css-t3ipsp-control:hover {
  border: none !important;
  box-shadow: none;
  cursor: pointer;
}

.compose-mail-body .to-name .title-search .to-users .css-t3ipsp-control {
  border: none !important;
  box-shadow: none;
  cursor: pointer;
}

.compose-mail-body .to-name .title-search .to-users .css-t3ipsp-control .css-1p3m7a8-multiValue {
  border-radius: 6px;
  padding: 5px 0;
  background-color: #F3F3F3;
  border: 1px solid #DCDCDC;
}

.compose-mail-body .to-name .title-search .to-users .css-13cymwt-control .css-1p3m7a8-multiValue {
  border-radius: 6px !important;
  padding: 5px 0 !important;
  background-color: #F3F3F3 !important;
  border: 1px solid #DCDCDC !important;
}

.compose-mail-body .to-name .email-cc-bcc-box .cc-btn,
.compose-mail-body .to-name .email-cc-bcc-box .bcc-btn {
  color: #000
}

.compose-mail-body .mail-input-box {
  padding: 10px 25px;
  border: none;
  width: 100%;
  outline: none;
}

.compose-mail-body .prime-react-container {
  padding: 0 20px 4px 20px;
}

.primeEditor {
  height: 250px;
  overflow: auto;
}

.compose-mail-dialog .modal-footer .add-document-btn {
  color: #000 !important;
  /* width: 90%; */

}

.compose-mail-body .attachments-text {
  padding: 0px 25px;
  margin-bottom: 10px;
}

.compose-mail-body .email-file-preview-box {
  display: flex;
  flex-wrap: wrap;
}

.compose-mail-body .email-file-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px !important;
  background-color: #F2F5F8 !important;
  border-radius: 4px;
  width: 240px;
  height: 35px;

}

.compose-mail-body .email-file-preview .email-attachment-name-ellipsis {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.compose-mail-body .email-file-name {
  display: flex;
  align-items: center;
}

.compose-mail-body .email-file-name span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.compose-mail-body .email-delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.compose-mail-body .email-discard-button {
  background-color: #F2F5F8 !important;
}

.email-star-selected-not-selected-icon.button.btn-icon:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.compose-mail-dialog .modal-content .prime-header-icon-box {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  padding: 0 12px;
}

.compose-mail-dialog .modal-content .prime-header-icon {
  background-color: #f3f3f3;
  padding: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.compose-mail-dialog .modal-content .prime-header-icon.bold-to-unordered .common_icon {
  /* width: 15px; */
  height: 15px;
}

.compose-mail-body .prime-react-container .p-editor-toolbar {
  display: none !important;
}

.compose-mail-body .prime-react-container .card {
  border: none !important;
}

.compose-mail-body .prime-react-container .card .ql-container {
  border: none !important;
}

.compose-mail-body .prime-react-container .card .ql-container .ql-editor {
  padding: 0 5px;
}

.compose-mail-body .prime-react-container .card .ql-container .ql-editor.ql-blank::before {
  left: 3px !important;
}

.compose-mail-body .compose-email-subject-error {
  margin: 0 !important;
  padding: 0px 0px 5px 25px !important;
}

.compose-mail-body .prime-react-container .card .ql-container .ql-editor img {
  width: 30%;
}


/* Today-Page */
.today-page-container {
  border-radius: 9px;
  border: 1px solid #D9D9D9;
  margin-bottom: 0;
}

.today-page-container .react-calendar {
  width: 50%;
  font-family: Poppins;
  border: none;
  padding: 20px 20px;
  border-radius: 9px;
}

.today-page-container .react-calendar__navigation {
  margin: 0 15px;
  align-items: center;
  /* margin-bottom: 10px; */
}

.today-page-container .react-calendar__navigation__label {
  font-size: 20px;
  color: #333333;
  font-weight: 700;
  padding: 10px 0;
}

.today-page-container .react-calendar__viewContainer .react-calendar__month-view__weekdays abbr[title] {
  text-decoration: none;
}

.today-page-container .react-calendar__viewContainer .react-calendar__month-view__weekdays abbr[title] {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  text-transform: capitalize;
}

.today-page-container .react-calendar__month-view__days .react-calendar__tile .react-calendar__tile--neighboringMonth {
  display: none;
}

.today-page-container .react-calendar__month-view__days .react-calendar__tile {
  font-weight: 500;
  font-size: 16px;
  padding: 15px 10px;
  color: #717171 !important;
}

.today-page-container .react-calendar__navigation button:enabled:hover {
  background-color: #F6F5F4;
}

.today-page-container .react-calendar__navigation__prev-button {
  display: inline-block;
  background-color: #F3F3F3;
  color: #717171;
  border-color: 1px solid #717171 !important;
  border-radius: 3px;
  margin: 5px;
  width: 15px !important;
  height: 25px !important;
  font-weight: 500;
  font-size: 17px;
}

.today-page-container .react-calendar__navigation__next-button {
  display: inline-block;
  background-color: #F3F3F3;
  color: #717171;
  border-color: 1px solid #717171 !important;
  border-radius: 3px;
  margin: 5px;
  width: 15px !important;
  height: 25px !important;
  font-weight: 500;
  font-size: 17px;

}

.today-page-container .react-calendar__navigation__prev2-button {
  display: inline-block;
  background-color: #F3F3F3;
  color: #717171;
  border-color: 1px solid #D7D7D7 !important;
  border-radius: 3px;
  margin: 5px;
  width: 15px !important;
  height: 25px !important;
  font-weight: 500;
  font-size: 17px;

}

.today-page-container .react-calendar__navigation__next2-button {
  display: inline-block;
  background-color: #F3F3F3;
  color: #717171;
  border-color: 1px solid #717171 !important;
  border-radius: 3px;
  margin: 5px;
  width: 15px !important;
  height: 25px !important;
  font-weight: 500;
  font-size: 17px;

}

.today-page-container .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now {
  background: none;
}

.today-page-container .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now abbr {
  background: var(--text-primary) !important;
  color: var(--text-white);
  padding: 10px 13px;
  border-radius: 50%;
}

.today-page-container .react-calendar__viewContainer {
  margin-top: 10px 0;
}

.today-page-container .custom-border-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.today-page-container .custom-border {
  border-right: 1px solid #D3D3D3;
  height: 65%;
  margin: auto 0;
}


.today-page-container .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day--weekend {
  color: #333333;
}

.today-page-container .react-calendar__tile--active {
  background: white !important;
}

.today-page-container .react-calendar__tile--active:enabled:focus,
.react-calendar__tile--hover,
.react-calendar__tile:enabled:focus {
  background: white !important;
  color: black;
  border: none;
}

.today-page-container .react-calendar__tile--active:hover,
.react-calendar__tile--hover {
  background: #e6e6e6 !important;
}


/* meeting event */
.new-appointment-modal .meetingevent {
  margin-top: 10px;
  position: relative;
}

.new-appointment-modal .meetingevent .form_group .input-group {
  width: 100%;
  background-color: white;
  border-radius: 8px;
}

.new-appointment-modal .meetingevent .dropdown-toggle {
  background-color: white;
  border-radius: 7px;
  width: 100%;
  color: #A1A1A1;
  text-align: start;
  border: none;
}

.new-appointment-modal .meetingevent .dropdown-menu {
  min-width: 615px;
  border: 1px solid #C2C2C2;
  margin: 12px 20px;
  padding: 10px 20px;
}

.new-appointment-modal .meetingevent .input-group-text {
  background-color: transparent !important;
}

.new-appointment-modal,
/* .new-appointment-modal .meetingevent .css-1nmdiq5-menu {height: 400px; } */

.new-appointment-modal,
.new-appointment-modal .meetingevent .custom-options-data .recent-searches-heading .css-1n6sfyn-MenuList {
  position: 'absolute';
  /* top: '100%'; */
  left: 0;
  width: '100%';
  background-color: 'white';
  z-index: 1000;
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.1)';
}

.new-appointment-modal .meetingevent .custom-options-data .css-1nmdiq5-menu,
.new-appointment-modal .meetingevent .custom-options-data .css-1n6sfyn-MenuList {
  /* padding: 16px 18px; */
  content: 'Recent Searches';
  border-radius: 9px;
  position: relative;
  font-size: 17px;
  font-weight: 500;
}

.new-appointment-modal .meetingevent .custom-options-data .recent-searches-heading {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 15px;
  color: #616161;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.new-appointment-modal .meetingevent .custom-options-data .dropdown-item {
  border-bottom: 1px solid #E7E7E7;
}

.new-appointment-modal .meetingevent .custom-options-data .custom-option {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.new-appointment-modal .meetingevent .circle-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #D9D9D9;
  color: #6A6A6A;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.new-appointment-modal .meetingevent .option-text {
  display: flex;
  gap: 10px;
}

.new-appointment-modal .meetingevent .option-text .name {
  font-weight: 500;
  font-size: 15px;
  color: #6A6A6A;
}

.new-appointment-modal .meetingevent .option-text .email {
  font-weight: 400;
  font-size: 15px;
  color: #B3B3B3;
}

/* schedule call when click on calender  */
.schedulecall-calender {
  margin: 10px 5px;
  background-color: #F0F0F0;
  border-radius: 9px;
  padding: 0 10px;
}

.schedulecall-calender .info {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  gap: 10px;
}

.schedulecall-calender .info3 {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  gap: 10px;
  border-top: 1px solid #DDDDDD;
  font-size: 16px;
  color: #555555;
}

.schedulecall-calender .info3 h6 {
  font-size: 14px;
  color: #555555;
  font-weight: 500;
}

/* dashboard */

.dashboard,
.cardData {
  padding: 0;
  display: flex;
  align-items: center;
}

.dashboard,
.cardData .chart-container2 {
  width: 100px;
  margin-top: 5px;
  padding-right: 10px;
}

.dashboard,
.cardData .cardsubdata {
  padding: 10px 15px;
}

.dashboard,
.cardData .number {
  font-weight: 600;
  font-size: 23px !important;
  color: #000000;
}

.dashboard,
.cardData .dashTitle {
  font-weight: 500;
  font-size: 13px !important;
  color: #616161;
}

.dashboard,
.cardData .dashSub {
  font-weight: 500;
  font-size: 10px;
  color: #787878;
}

.dashboard,
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard,
.header .custom-nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid #D9D9D9;
  border-radius: 5px !important;
  margin-top: 8px;
}

.dashboard,
.header .custom-nav-tabs .nav {
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid #D9D9D9;
  border-radius: 5px !important;
}

.dashboard,
.header .custom-nav-tabs .nav-item {
  margin: 0;
  border-radius: 10px !important;
}


.dashboard,
.header .custom-nav-tabs .nav-link {
  border: none;
  color: #000000;
  background-color: #FFFFFF !important;
  border: 1px solid #D9D9D9;
  font-size: 14px;
  padding: 0.5rem 8px !important;
}


.custom-nav-tabs .nav-link.active {
  background-color: #FFF4F1 !important;
  color: #000000;
  border-color: #F9623E;
  z-index: 1;
  font-weight: 500;
}

.dashboard,
.header-data {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #D9D9D9;
  padding: 0.5rem 8px !important;
  /* gap: 10px; */
  border-radius: 5px;

}

.dashboard .header-data,
.start-date,
.end-date {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard .header-data,
.start-date h6 {
  padding-right: 5px;
  /* margin-left: 2px; */
  border-right: 1px solid #D9D9D9;
}

.dashboard .header-data,
.end-date {
  padding-left: 5px;
}

/* statistics */
.statistics .card {
  padding: 15px;
  border-radius: 10px;
}

.statistics .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statistics .header .title {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.statistics .points {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 500;
}

/* dashboard appintment btns */
.dashbtns .d_btns {
  display: flex;
  padding: 4px 5px;
  width: 230px;
  border: 1px solid #D9D9D9;
  border-radius: 7px;
  gap: 10px;
}

.dashbtns .d_btns .appointmentBtn,
.calls {
  border: none !important;
  background-color: white;
  border-radius: 5px;
  font-size: 12px;
  margin: 2px 0;
}

.dashbtns .d_btns .appointmentBtn,
.calls:hover {
  border: none;
}

.dashbtns .d_btns .appointmentBtn,
.calls:active {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* dashboard start date nd end date */
.dashboard .header-data,
.start-date .react-datepicker {
  font-family: Poppins !important;
  border: 1px solid #C2C2C2;
  border-radius: 9px !important;
  border-top: 1px solid #F15D02 !important;
}

.dashboard .header-data .start-date .date-picker-wrapper {
  display: flex;
  align-items: center;
}

.start-date .custom-input {
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.start-date .custom-input span {
  padding: 0 8px;
}

.start-date .custom-input span .header-calendar-icon {
  margin-right: 5px;
}

.start-date .custom-input:focus {
  outline: none;
}

.start-date .react-datepicker__input-container input {
  border: none;
  outline: none;
  padding: 0;
  background: none;
  color: inherit;
}

.dashboard .header-data .start-date,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.dashboard .header-data .start-date,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border: none !important;
}

/* Start and end date specific styling */
.start-date .react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: var(--text-primary) !important;
  border-radius: 50% !important;
  color: white !important;
}

.start-date .react-datepicker__day--selected:hover,
.react-datepicker__day--range-start:hover,
.react-datepicker__day--range-end:hover,
.react-datepicker__day--in-range:hover {
  background-color: var(--text-primary) !important;
  border-radius: 50% !important;
  color: white !important;
}

.start-date .react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range:hover {
  border-radius: 50%;
  /* background-color: #FFFAF7 !important; */
  color: #FFFFFF !important;
}

.start-date .react-datepicker__day--selected:hover {
  background-color: var(--text-primary) !important;
  border-radius: 50%;
  color: white !important;
}

.start-date .react-datepicker__day--today {
  outline: none !important;
}

.start-date .react-datepicker__day:hover {
  background-color: #ffefd5;
  color: gray;
  border-radius: 50%;
}

.start-date .react-datepicker__day {
  color: gray;
  background-color: transparent;
  border-radius: 50%;
}

.start-date .react-datepicker__day:hover {
  background-color: #ddd;
  color: gray;
}

.dashboard .header-data .start-date,
.react-datepicker .react-datepicker__month-container {
  width: 300px;
}

.dashboard .header-data .start-date,
.react-datepicker .react-datepicker__day {
  margin: 7px;
}

.dashboard .header-data .start-date,
.react-datepicker .react-datepicker__day-name {
  margin: 7px;
}

.start-date .react-datepicker__navigation,
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  margin-top: 8px;
  color: #333333;
}

.start-date .react-datepicker__header {
  background-color: #FFFAF7;
  border-bottom: 0;
  padding: 0;
}

.start-date .react-datepicker__current-month {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  border-bottom: 1px solid #F15D02;
  border-left: 1px solid #F15D02;
  border-right: 1px solid #F15D02;
  padding: 10px 0;
}

.start-date .react-datepicker__day-names {
  background-color: white;
  font-size: 14px;
  font-weight: 400;
}

.start-date .react-datepicker__month-container {
  border-right: 1px solid #C2C2C2;
}

/* search company */
.searchcomapny {
  --bs-modal-width: 649px;
}

.searchcomapny .modal-body {
  padding: 15px 25px;
}

.searchcomapny .modal-content {
  border-radius: 14px;
}

.searchcomapny .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchcomapny .header-container .header-title {
  font-weight: 600;
  font-size: 23px;
  margin: 0;
  color: #F9623E;
}

.searchcomapny .header-container .header-button-save-close {
  display: flex;
}

.searchcomapny .save-appointment-btn {
  padding: 5px 40px !important;
  margin-right: 10px;
}

.searchcomapny .close-appointment-btn {
  height: 32px !important;
  width: 32px !important;
  cursor: pointer;
}

.searchcomapny .searchbar {
  margin-top: 30px;
  background-color: #F7F7F7;
  /* border: 1px solid #E7E7E7; */
  border-radius: 7px !important;
}

.searchcomapny .user-details {
  margin: 0 0 20px 0;
  background-color: #F0F0F0;
  border: 1px solid #DDDDDD;
  border-radius: 9px;
  padding: 5px 10px;
}

.searchcomapny .user-details .details1 {
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 600 !important;
  color: #555555 !important;
  border-bottom: 1px solid #DDDDDD;
}

.searchcomapny .user-details .details1 .head {
  display: flex;
}

.searchcomapny .user-details .details1 h6 {
  margin-top: 10px;
  padding: 3px 0;
}

.searchcomapny .user-details .details1 .icons {
  margin-right: 10px;
}

.searchcomapny .user-details .details2 {
  display: flex;
  padding: 12px 10px;
  font-size: 16px;
  font-weight: 600;
  color: #555555;
}

.searchcomapny .user-details .details2 .icons {
  margin-right: 10px;
}

.breadcrumb-main .showdate {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 19rem;
  gap: 10px;
}

.breadcrumb-main .showdate h6 {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}

.breadcrumb-main .showdate .date-btn {
  background-color: #6C7278;
  color: white;
  border-radius: 5px;
}

/* select status */

.customers-add-edit-card .card-body-filters .status-dropdown-toggle {
  display: flex;
  justify-content: space-between;
  border: 1px solid #E7E7E7 !important;
  color: #6C7278;
  width: 100%;
}

.customers-add-edit-card .card-body-filters .status-dropdown-toggle .common_icon_image {
  color: #6C7278;
}

.customers-add-edit-card .card-body-filters .status-dropdown-toggle.btn:focus,
.customers-add-edit-card .card-body-filters .status-dropdown-toggle:hover {
  border: 1px solid #E7E7E7 !important;
  color: #6C7278;
}

.customers-add-edit-card .card-body-filters .status-content-box {
  display: flex;
  gap: 10px;
}

.customers-add-edit-card .card-body-filters .status-dropdown,
.customers-add-edit-card .card-body-filters .status-dropdown .dropdown-menu {
  position: absolute;
  /* left: 48% !important; */
  border-radius: 10px;
  min-width: 345px;
  padding: 0 10px;
}

.customers-add-edit-card .card-body-filters .dropdown-menu .dropdown-item {
  padding: 15px 5px 10px 5px !important;
  right: 58%;
  font-size: 14px;
  border-bottom: 1px solid #F0F0F0;
}

.customers-add-edit-card .card-body-filters,
.dropdown-menu.show .dropdown-item .check_menu {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.customers-add-edit-card .card-body-filters .dropdown-menu.show .dropdown-item .check_menu .common_icon_image {
  width: 18px;
  height: 18px;
}

.card-body-filters .status-content-box {
  display: flex;
  gap: 10px;
}

.card-body-filters .status-dropdown,
.card-body-filters .status-dropdown .dropdown-menu {
  position: absolute;
  /* left: 48% !important; */
  border-radius: 10px;
  min-width: 345px;
  padding: 0 10px;
}

.card-body-filters .dropdown-menu .dropdown-item {
  padding: 15px 5px 10px 5px !important;
  right: 58%;
  font-size: 14px;
  border-bottom: 1px solid #F0F0F0;
}

.card-body-filters .dropdown-menu.show .dropdown-item .check_menu {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.card-body-filters .dropdown-menu.show .dropdown-item .check_menu .common_icon_image {
  width: 18px;
  height: 18px;
}

.card-body-filters .status-dropdown .btn.show,
.card-body-filters .status-dropdown .btn:focus {
  background-color: var(--text-white) !important;
}

.customers-add-edit-card .card-body-filters .status-dropdown .dropdown-menu .check_menu .bulleticons .common_icon_image {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}