body .btn {   
    --padding: 10px 20px 10px 20px;
    --fr-secondary-p-border-color: var(--grey-light-color);;
    --btn-hover-b-color: var(--text-primary);
    --bs-btn-color: var(---text-color);
    --bs-btn-font-size: var(--fs-14);
    --bs-btn-font-weight: var(--fw-500);
    --bs-btn-line-height: 1.5;
    padding: var(--padding) !important;
    --t-active-primary: var(--text-primary);
    --t-active-secondary: var(--text-gray);
    --background: transparent;
    background: var(--background);
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: var(--bs-border-radius);
    --bs-btn-hover-border-color: var(--btn-hover-b-color) ;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    --bs-btn-hover-bg: transparent;
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-font-sans-serif);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, var(--fr-transition);
    color: var(--bs-btn-color);
}

body .btn.btn-xsm:not(.btn-icon){--padding: 0.375rem 0.75rem; --bs-btn-font-size:var(--fs-12);height: auto;}
body .btn.btn-sm:not(.btn-icon){--padding: 0.45rem 0.85rem; --bs-btn-font-size:var(--fs-12);height: 34px;}
body .btn.btn-md:not(.btn-icon){--padding: 0.45rem 1.25rem; --bs-btn-font-size:var(--fs-14);height: 38px;}
body .btn.btn-lmd:not(.btn-icon){--padding: 0.45rem 1.25rem; --bs-btn-font-size:var(--fs-14);height: 40px;}
body .btn.btn-lg:not(.btn-icon){--padding: 0.55rem 1.25rem; --bs-btn-font-size:var(--fs-14);height: 48px;}

body .btn:hover,
body .btn:focus,
body .btn:focus-visible {
    border-color: var(--bs-btn-hover-border-color) !important;
    background-color: var(--bs-btn-hover-bg);
    color: var(--bs-btn-hover-color);
}


body .btn-primary{
    --bs-btn-color: var(--text-white);
    --bs-btn-bg: var(--text-primary);
    --bs-btn-border-color: var(--text-primary);
    --bs-btn-hover-color: var(--text-white);
    --bs-btn-hover-bg: var(--text-primary);
    --bs-btn-hover-border-color: var(--text-primary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--text-white);
    --bs-btn-active-bg: var(--t-active-primary);
    --bs-btn-active-border-color: var(--t-active-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--text-white);
    --bs-btn-disabled-bg: var(--text-primary);
    --bs-btn-disabled-border-color: var(--text-primary);
}
body .btn-secondary{
    --bs-btn-color: var(--text-color);
    --bs-btn-bg: var(--text-gray);
    --bs-btn-border-color: #E7E7E7;
    --bs-btn-hover-color: var(--text-color);
    --bs-btn-hover-bg: var(--text-gray);
    --bs-btn-hover-border-color: #E7E7E7;
    --bs-btn-active-color: var(--text-color);
    --bs-btn-active-bg: var(--t-active-secondary);
    --bs-btn-active-border-color: var(--t-active-secondary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--text-color);
    --bs-btn-disabled-bg: var(--text-gray);
    --bs-btn-disabled-border-color: var(--text-gray);
}

body .btn-grey{
    --bs-btn-color: var(--text-white);
    --bs-btn-bg: #696969;
    --bs-btn-border-color: #696969;
    --bs-btn-hover-color: var(--text-white);
    --bs-btn-hover-bg: #696969;
    --bs-btn-hover-border-color: #696969;
    --bs-btn-active-color: var(--text-white);
    --bs-btn-active-bg: #696969;
    --bs-btn-active-border-color: var(--t-active-sondary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--text-white);
    --bs-btn-disabled-bg: #696969;
    --bs-btn-disabled-border-color: #696969;
}


body .btn-white{
    --bs-btn-color: var(--text-color);
    --bs-btn-bg: var(--text-white);
    --bs-btn-border-color: var(--border-primary);
    --bs-btn-hover-color: var(--text-color);
    --bs-btn-hover-bg: var(--text-white);
    --bs-btn-hover-border-color: var(--border-primary);
    --bs-btn-active-color: var(--text-color);
    --bs-btn-active-bg: var(--t-active-secondary);
    --bs-btn-active-border-color: var(--t-active-secondary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--text-color);
    --bs-btn-disabled-bg: var(--text-white);
    --bs-btn-disabled-border-color: var(--text-white);
}


body .btn-dark{
    --bs-btn-color: var(--text-white);
    --bs-btn-bg:#454544;
    --bs-btn-border-color: var(--border-primary);
    --bs-btn-hover-color: var(--text-white);
    --bs-btn-hover-bg:#454544;
    --bs-btn-hover-border-color: var(--border-primary);
    --bs-btn-active-color: var(--text-white);
    --bs-btn-active-bg: #454544;
    --bs-btn-active-border-color: var(--t-active-secondary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--text-white);
    --bs-btn-disabled-bg:#454544;
    --bs-btn-disabled-border-color: var(--text-white);
}



body .btn.dropdown-toggle::after {
    display: none !important;
}


/* button icon */

.button.btn-icon:focus{outline: 0px!important;}
.button.btn-icon:not(.min-width) {height: var(--height);width: var(--width);}
.button.btn-icon.min-width {min-height: var(--height);min-width: var(--width);--padding: 6px;}
.button.btn-icon {--padding: 2px;--height: 40px;--width: 40px;padding: var(--padding);display: inline-flex;align-items: center;justify-content: center;--default-bg: #e7ecf2;outline: 0px!important;}
.button.btn-icon.btn-primary-2{--default-bg: #C3CCE8!important; --bs-btn-hover-bg:var(--bs-btn-bg); --bs-btn-bg: var(--default-bg)!important}
.button.btn-icon.btn-success{--default-bg: #01CC83!important; --bs-btn-hover-bg:var(--bs-btn-bg); --bs-btn-bg: var(--default-bg)!important;}
.button.btn-icon.btn-danger-1{--default-bg: #FDDDDD!important; --bs-btn-hover-bg:var(--bs-btn-bg); --bs-btn-bg: var(--default-bg)!important}
.button.btn-icon.btn-primary{--default-bg:var(--text-primary)}
.button.btn-icon.btn-sm {--height: 35px;--width: 35px}
.button.btn-icon.btn-xsm {--height: 25px;--width: 25px;--padding: 3px;}
.button.btn-icon.btn-xsm .common_icon {--height: 100%;--width: 100%}
.button.btn-icon.min-width.btn-xxl {--height: 50px;--width: 50px}
.button.btn-icon.btn-success i{--text-primary-color: var(--text-white)!important}
.button.btn-icon.btn-danger-1 i{--text-primary-color: var(--bs-danger)!important}
.b-0 {--fr-border-color: transparent !important;--bs-btn-border-color: var(--fr-border-color) !important;--bs-dropdown-border-color: var(--fr-border-color) !important;}
.b-1 {--fr-border-color: #E4E4E7 !important;--fr-border-width: 1px !important;--bs-btn-border-color: var(--fr-border-color) !important;}
.button.btn-icon.md {--height: 35px;--width: 35px}
.button.btn-icon.sm {--height: 30px;--width: 30px}
.button.btn-icon.rounded {border-radius: 1000px;}
.button.btn-icon:hover {background: var(--default-bg);}


.bg-primary{
    background-color: var(--text-primary) !important;
}


.confirm-modal .title{
    line-height: 30px;
}
.confirm-modal .button-box{
    margin-bottom: 20px;
}
.confirm-modal .confirm-modal-cancel-btn{
    width: 40% !important;
}
.confirm-modal .confirm-modal-success-btn{
    width: 40% !important;
}
.confirm-modal .modal-dialog{
    width: 400px;
}